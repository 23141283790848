

















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import serviceBarcode from "@/services/barcode";
import serviceLayout from "@/services/layout";
import serviceCategoryMenu from "@/services/categoryMenu";

@Component({
  watch: {
    "state.best": function(newValue: string) {
      if (newValue !== null) {
        setTimeout(() => {
          serviceCategoryMenu.show();
          serviceLayout.emit("changed-menu-categories-search", newValue);
          serviceBarcode.reset();
        }, 1e3);
      }
    }
  }
})
export default class Scanner extends Vue {
  protected state = serviceBarcode.getState();
  mounted() {
    (this.$refs.slot as HTMLElement).appendChild(serviceBarcode.getDom());
    serviceBarcode.init();
    this.$service.categoryMenu.on("changed-visible", this.handlerCategoryMenu);
  }

  beforeDestroy() {
    serviceBarcode.stop();
    this.$service.categoryMenu.off("changed-visible", this.handlerCategoryMenu);
  }

  handlerClose() {
    this.$router.push("/");
    serviceCategoryMenu.show();
  }

  handlerCategoryMenu(menuIsVisible: boolean) {
    if (menuIsVisible) {
      serviceBarcode.stop();
    } else {
      serviceBarcode.init();
    }
  }
}
