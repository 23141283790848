var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{staticClass:"delivery-and-payments",on:{"submit":function($event){$event.preventDefault();return passes()}}},[_c('div',{staticClass:"d-flex flex-column flex-fill bg-light order-content"},[_c('DeliveryAndPayments'),_c('section',{staticClass:"bg-white order-section section-payer"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-6 d-flex justify-content-start align-items-center"},[_c('header',{staticClass:"m-0"},[_c('h3',{staticClass:"text m-0"},[_vm._v(_vm._s(_vm.t("Dane płatnika")))])])]),_c('div',{staticClass:"col-6 d-flex justify-content-end align-items-center"},[_c('AddressesDropdown',{attrs:{"addresses":_vm.addresses,"on-select":function (item) { return _vm.fillAddress(item, 'user_'); }}})],1)]),_c('ValidationProvider',{staticClass:"row payer-type",attrs:{"name":"user_type","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.payerOptions),function(item,index){return [_c('div',{key:index,staticClass:"col"},[_c('div',{staticClass:"custom-control custom-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.user_type),expression:"formData.user_type"}],staticClass:"custom-control-input",attrs:{"type":"radio","id":("payer-radio-" + (item.value)),"name":"payer"},domProps:{"value":item.value,"checked":_vm._q(_vm.formData.user_type,item.value)},on:{"change":function($event){return _vm.$set(_vm.formData, "user_type", item.value)}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":("payer-radio-" + (item.value))}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.label))])])])])]}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"row"},[(_vm.formData.user_type === 1)?_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"user_company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.user_company),expression:"formData.user_company"}],staticClass:"form-control",attrs:{"type":"text","name":"user_company","placeholder":_vm.formPlaceholders.company},domProps:{"value":(_vm.formData.user_company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "user_company", $event.target.value)}}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1):_vm._e(),(_vm.formData.user_type === 1)?_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"user_nip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.user_nip),expression:"formData.user_nip"}],staticClass:"form-control",attrs:{"type":"text","name":"user_nip","placeholder":_vm.formPlaceholders.nip},domProps:{"value":(_vm.formData.user_nip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "user_nip", $event.target.value)}}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1):_vm._e()]),_c('FormName',{attrs:{"suffix":"user_","formData":_vm.formData,"formPlaceholders":_vm.formPlaceholders}}),_c('FormContact',{attrs:{"suffix":"user_","formData":_vm.formData,"formPlaceholders":_vm.formPlaceholders}})],1),_c('section',{staticClass:"bg-white order-section section-address"},[_c('header',[_c('h3',{staticClass:"text"},[_vm._v(_vm._s(_vm.t("Dane adresowe")))])]),_c('FormAddress',{attrs:{"suffix":"user_","formData":_vm.formData,"formPlaceholders":_vm.formPlaceholders}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"delivery_other"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.delivery_other),expression:"formData.delivery_other"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"delivery_other","name":"delivery_other"},domProps:{"checked":Array.isArray(_vm.formData.delivery_other)?_vm._i(_vm.formData.delivery_other,null)>-1:(_vm.formData.delivery_other)},on:{"change":function($event){var $$a=_vm.formData.delivery_other,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "delivery_other", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "delivery_other", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "delivery_other", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"delivery_other"}},[_vm._v(" "+_vm._s(_vm.formPlaceholders.other)+" ")]),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)])],1),(_vm.formData.delivery_other)?_c('section',{staticClass:"bg-white order-section section-recipient"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-6 d-flex justify-content-start align-items-center"},[_c('header',{staticClass:"m-0"},[_c('h3',{staticClass:"text m-0"},[_vm._v(_vm._s(_vm.t("Dane odbiorcy")))])])]),_c('div',{staticClass:"col-6 d-flex justify-content-end align-items-center"},[_c('AddressesDropdown',{attrs:{"addresses":_vm.addresses,"on-select":function (item) { return _vm.fillAddress(item, 'delivery_'); }}})],1)]),_c('FormName',{attrs:{"suffix":"delivery_","formData":_vm.formData,"formPlaceholders":_vm.formPlaceholders}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"delivery_company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.delivery_company),expression:"formData.delivery_company"}],staticClass:"form-control",attrs:{"type":"text","name":"delivery_company","placeholder":_vm.formPlaceholders.company},domProps:{"value":(_vm.formData.delivery_company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "delivery_company", $event.target.value)}}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)])],1):_vm._e(),(_vm.formData.delivery_other)?_c('section',{staticClass:"bg-white order-section section-other-address"},[_c('header',[_c('h3',{staticClass:"text"},[_vm._v(_vm._s(_vm.t("Dane adresowe odbiorcy")))])]),_c('FormAddress',{attrs:{"suffix":"delivery_","formData":_vm.formData,"formPlaceholders":_vm.formPlaceholders}})],1):_vm._e(),_c('section',{staticClass:"order-section section-checkboxes"},[_vm._l((_vm.checkboxes),function(item,index){return [_c('ValidationProvider',{key:index,attrs:{"name":("order_" + (item.slug))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData[("order_" + (item.slug))]),expression:"formData[`order_${item.slug}`]"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":("checkbox-" + (item.slug)),"name":("order_" + (item.slug))},domProps:{"checked":Array.isArray(_vm.formData[("order_" + (item.slug))])?_vm._i(_vm.formData[("order_" + (item.slug))],null)>-1:(_vm.formData[("order_" + (item.slug))])},on:{"change":function($event){var $$a=_vm.formData[("order_" + (item.slug))],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, ("order_" + (item.slug)), $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, ("order_" + (item.slug)), $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, ("order_" + (item.slug)), $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":("checkbox-" + (item.slug))}},[_c('div',{domProps:{"innerHTML":_vm._s(item.text_raw)}})]),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0] ? _vm.t("Ta zgoda jest wymagana") : "")+" ")])],1)]}}],null,true)})]})],2),_c('section',{staticClass:"p-0 m-0"},[_c('div',{staticClass:"btn-order-bottom-bar as-padding"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/order/delivery-and-payments"}},[_vm._v(" "+_vm._s(_vm.t("Dalej"))+" ")])],1)])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }