



































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CartResponse } from "@/services/connection";
import serviceCart from "@/services/cart";
import ListItem from "@/views/cart/ListItem.vue";

@Component({
  components: {
    ListItem
  }
})
export default class Wishlist extends Vue {
  cart = null as CartResponse | null;

  /******************************/

  beforeMount() {
    serviceCart.on("changed-wishlist", this.getCart);
    this.getCart();
  }
  beforeDestroy() {
    serviceCart.on("changed-wishlist", this.getCart);
  }

  /******************************/

  getCart() {
    const cart = serviceCart.getWishListData();
    this.$set(this, "cart", cart);
  }
}
