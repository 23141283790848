import { jsonApiFetch } from "./endpoint";

type A = { guest_token: string };
type B = { customer_token: string };
type R = {
  coupon: string;
};
type Request = (A | B) & R;
type Response = {};

function action(inputData: Request) {
  return jsonApiFetch<Response>(
    "/cartCoupon",
    JSON.stringify({ ...inputData, ...{ type: "cart" } })
  );
}

export { action as cartCoupon, Response as CartCouponResponse };
