import { jsonApiFetch } from "./endpoint";

type Request =
  | {
      customer_token: string;
    }
  | {
      long_life_token: string;
    };
type Response = {
  logout: boolean;
};

function action(inputData: Request) {
  return jsonApiFetch<Response>("/customerLogout", JSON.stringify(inputData));
}

export { action as customerLogout, Response as LogoutResponse };
