




























































































import { Component, Vue } from "vue-property-decorator";
import serviceCustomer from "@/services/customer";

@Component
export default class SocialButtons extends Vue {
  protected statusGoogle: null | "logging" | "success" | "error" = null;
  protected statusFB: null | "logging" | "success" | "error" = null;

  $auth!: {
    authenticate(
      provider: "google"
    ): Promise<{
      access_token: string;
      authuser: string;
      expires_in: string;
      prompt: string;
      scope: string;
      token_type: string;
    }>;
    authenticate(
      provider: "facebook"
    ): Promise<{
      access_token: string;
      data_access_expiration_time: string;
      expires_in: string;
    }>;
  };

  async authenticateGoogle() {
    this.statusGoogle = "logging";
    this.$auth.authenticate("google").then(
      async result => {
        const loginResult = await this.$service.customer.socialLogin(
          "google_oauth",
          result.access_token
        );

        if (loginResult !== null) {
          this.statusGoogle = "success";
          await new Promise(r => setTimeout(r, 2000));
          this.$router.push("/");
        } else {
          this.statusGoogle = "error";
          setTimeout(() => (this.statusGoogle = null), 5e3);
        }
      },
      () => {
        this.statusGoogle = "error";
        setTimeout(() => (this.statusGoogle = null), 5e3);
      }
    );
  }

  async authenticateFB() {
    this.statusFB = "logging";
    this.$auth.authenticate("facebook").then(
      async result => {
        const loginResult = await this.$service.customer.socialLogin(
          "facebook",
          result.access_token
        );

        if (loginResult !== null) {
          this.statusFB = "success";
          await new Promise(r => setTimeout(r, 2000));
          this.$router.push("/");
        } else {
          this.statusFB = "error";
          setTimeout(() => (this.statusFB = null), 5e3);
        }
      },
      () => {
        this.statusFB = "error";
        setTimeout(() => (this.statusFB = null), 5e3);
      }
    );
  }
}
