import { jsonApiFetch } from "./endpoint";
import { SearchResponse } from "./search";
import { FacetsResponse } from "./facets";

type Response = SearchResponse & FacetsResponse;

function action(searchParams: URLSearchParams) {
  return jsonApiFetch<Response>("/search/facets/1", searchParams);
}

export { action as searchAndFacets, Response as SearchAndFacetsResponse };
