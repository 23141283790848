














import { Component, Prop, Vue } from "vue-property-decorator";
import serviceFacets from "@/services/facets";

@Component({
  errorCaptured: (/*error, vm, info*/) => {
    return false;
  }
})
export default class ProductFilter extends Vue {
  @Prop({ required: true }) options!: { id: number; name: string }[];
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) onChange!: (values: number[]) => void;
  props = {
    multiple: true,
    emitPath: false,
    checkStrictly: false,
    value: "id",
    label: "name",
    children: "childs"
  };

  value: number[] = [];
  onValueChange(value: [number, number]) {
    this.onChange(value);
  }
}
