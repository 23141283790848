

































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/views/layout/header";
import Modal from "@/views/layout/Modal";
import serviceLayout, { CategoryItem, MenuItem } from "@/services/layout";
import serviceProductList from "@/services/productList";
import serviceCategoryMenu from "@/services/categoryMenu";
import serviceSpeechRecognition from "@/services/speechRecognition";
import * as serviceConnection from "@/services/connection";
import { ProductOnList, SearchCategoryItem } from "@/services/connection";
import serviceFacets from "../../../services/facets";

@Component
export default class DrawerSearch extends Vue {
  visible = serviceCategoryMenu.isVisible();
  speachAvailable = false;
  btnMicStatus = "btn-outline-secondary";
  searchState = serviceLayout.searchState;
  searchResults = [] as MenuItem[];
  searchResultsProducts = [] as ProductOnList[];
  searchResultsCategories = [] as SearchCategoryItem[];
  searchClass = {
    focus: false
  };

  beforeMount() {
    serviceSpeechRecognition.waitToReady().then(() => {
      this.speachAvailable = true;
    });
    serviceCategoryMenu.on("changed-visible", this.onMenuCategoriesVisible);
    serviceLayout.on(
      "changed-menu-categories-search",
      this.fireHandlerSearchInput
    );
  }
  beforeDestroy() {
    serviceCategoryMenu.off("changed-visible", this.onMenuCategoriesVisible);
    serviceLayout.off(
      "changed-menu-categories-search",
      this.fireHandlerSearchInput
    );
  }

  onMenuCategoriesVisible(value: boolean) {
    this.$nextTick(() => {
      this.visible = serviceCategoryMenu.isVisible();
    });
  }

  handleCategoryMenuClose() {
    serviceCategoryMenu.hide();
    this.searchState.query = "";
    this.searchResultsProducts = [];
    this.searchResultsCategories = [];
    this.searchClass.focus = false;
  }

  async handlerSpeak() {
    this.btnMicStatus = "btn-danger";
    try {
      const result = await serviceSpeechRecognition.start();
      this.searchState.query = result;
      this.handlerSearchInput();
    } catch (error) {
      null;
    }
    this.btnMicStatus = "btn-outline-secondary";
  }

  applySearch() {
    const searchValue = this.searchState.query.toLowerCase();

    serviceProductList.setCid(null);
    serviceProductList.setBid(null);
    serviceProductList.setQuery(searchValue);
    this.searchResultsProducts = [];
    this.searchResultsCategories = [];
    this.searchClass.focus = false;

    const url = `/products${serviceProductList.getUrlParams()}`;

    if (url !== this.$route.path) {
      this.$router.push(url);
    }
    this.handleCategoryMenuClose();
  }

  handlerSearchInput(event?: KeyboardEvent) {
    const list = serviceProductList.getCategoryList();
    const result = [];

    if (event) {
      if (event.keyCode === 13) {
        this.applySearch();
        return;
      }
    }

    const searchValue = this.searchState.query.toLowerCase();

    (async () => {
      const result = await serviceConnection.search({
        filters: `q=${this.searchState.query}&limit=7&sort=7`
      });

      if (result.valid()) {
        const newList = [...result.products];
        newList.length = Math.min(7, newList.length);
        this.searchResultsProducts = newList;
      }
    })();

    (async () => {
      const result = await serviceConnection.searchCategories(
        new URLSearchParams(`q=${this.searchState.query}&limit=7&sort=7`)
      );

      if (result.valid()) {
        const newList = [...result.category];
        newList.length = Math.min(7, newList.length);
        this.searchResultsCategories = newList;
      }
    })();

    if (searchValue.length > 2) {
      for (const item of list) {
        if (item.name.toLowerCase().search(this.searchState.query) !== -1) {
          result.push(item);
        }
      }
    }

    this.$set(this, "searchResults", result);
    this.$set(
      this.searchClass,
      "focus",
      result.length || searchValue.length > 2
    );
  }

  fireHandlerSearchInput(query: string) {
    this.searchState.query = query;
    this.handlerSearchInput();
  }

  getCategoryParents(itemId: string | number) {
    const id = parseInt(itemId as string);

    const result = this.$service.productList.getCategoryById(id);

    if (result) {
      return result.parents;
    }
    return [];
  }
}
