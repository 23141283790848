




























import { Component, Vue } from "vue-property-decorator";
import serviceLayout from "@/services/layout";
import { Content } from "@/services/connection";
import Back from "@/components/RouteTopBack.vue";

@Component({
  components: {
    Back
  },
  watch: {
    $route: function(this: ArticleItem) {
      this.onContentsChanged();
    }
  }
})
export default class ArticleItem extends Vue {
  private main = null as Content | null;

  mounted() {
    serviceLayout.on("changed-contents", this.onContentsChanged);
    setTimeout(() => this.onContentsChanged(), 10);
  }
  beforeDestroy() {
    serviceLayout.off("changed-contents", this.onContentsChanged);
  }

  onContentsChanged() {
    this.main = serviceLayout.getContentWithSlug(this.$route.params.slug);
  }
}
