














































































import { Component, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import serviceLayout, { CategoryItem } from "@/services/layout";
import serviceCategoryMenu from "@/services/categoryMenu";

@Component({
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class SwiperCategories extends Vue {
  private layoutCategories = serviceLayout.getCategories();

  beforeMount() {
    serviceLayout.on("changed-categories", this.onLayoutCategoriesChange);
  }
  beforeDestroy() {
    serviceLayout.off("changed-categories", this.onLayoutCategoriesChange);
  }

  onLayoutCategoriesChange(list: CategoryItem[]) {
    this.layoutCategories = serviceLayout.getCategories();
  }
  showAllCategories() {
    serviceCategoryMenu.show();
  }
}
