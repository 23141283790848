
































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import serviceCart from "@/services/cart";
import { CartResponse, CartRow } from "@/services/connection";

@Component
export default class ListItem extends Vue {
  @Prop({ required: true }) type!: "cart" | "wishlist";
  @Prop({ required: true }) item!: CartRow;
  @Prop({ default: false }) disable!: boolean;

  lock = false;
  item$syncTimeout: number | null = null;
  item$sync = false;

  async deleteFromCart() {
    this.$set(this, "lock", true);

    let result;

    if (this.type === "cart") {
      result = await serviceCart.delFromCart(this.item.product.id);
    } else if (this.type === "wishlist") {
      result = await serviceCart.delFromWishList(this.item.product.id);
    }

    if (result !== null) {
      this.$set(this, "lock", false);
    }
  }

  async setProductQty(value: number, check = false) {
    this.$set(this.item, "qty", value);

    // kasujemy poprzednie zlecenie update
    if (this.item$syncTimeout) {
      clearTimeout(this.item$syncTimeout);
    }
    // flaga sync
    this.item$sync = true;
    // zlecamy update za 500ms
    this.item$syncTimeout = setTimeout(async () => {
      // nie ma już poprzewdniego update do kasowania
      this.item$syncTimeout = null;
      // update
      const result = await serviceCart.updateInCart({
        product_id: this.item.product.id,
        quantity: this.item.qty
      });

      // jeśli jest response i nie ma kolejnego zlecenia update
      if (result && !this.item$syncTimeout) {
        this.item$sync = false;
      }
    }, 500);
  }

  async addToCart() {
    const result = await serviceCart.addToCart({
      product_id: this.item.product.id,
      quantity: this.item.qty
    });

    if (result !== null) {
      this.$bvToast.toast(window.t("Dodano do koszyka"), {
        title: window.t("Koszyk"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    }
  }
}
