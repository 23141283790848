import { CategoryResponse, Category } from "@/services/connection";

type TC = CategoryResponse["categories"];

export default class Categories {
  protected map_pId = {} as {
    [key: number]: TC & { parents: TC; parent: TC[0] };
  };
  protected map_id = {} as {
    [key: number]: TC[0] & { parents: TC; parent: TC[0] };
  };

  constructor(protected rawList: TC) {
    const listItemProto = (function(that) {
      const proto = {};
      Object.defineProperties(proto, {
        children: {
          get() {
            return that.getChildListById(this.id);
          },
          enumerable: true,
          configurable: true
        },
        parent: {
          get() {
            return that.getById(this.parent_id);
          },
          enumerable: false,
          configurable: true
        },
        parents: {
          get() {
            const path: Category[] = [];
            const parent = this.parent;

            if (parent) {
              path.push(...parent.parents, parent);
            }

            return path;
          },
          enumerable: false,
          configurable: true
        }
      });
      return proto;
    })(this);

    for (const item of rawList) {
      this.map_pId[item.parent_id] = this.map_pId[item.parent_id] || [];
      this.map_pId[item.parent_id].push(item);
      //@ts-ignore
      this.map_id[item.id] = item as TC[0];

      Object.setPrototypeOf(item, listItemProto);
    }
  }

  getById(id: number) {
    if (this.map_id[id]) {
      return this.map_id[id];
    }
    return null;
  }

  getChildListById(id: number) {
    if (this.map_pId[id]) {
      return this.map_pId[id].slice();
    }
    return [];
  }

  getMain() {
    return this.getChildListById(0);
  }

  getList() {
    return this.rawList.slice();
  }
}
