import { jsonApiFetch } from "./endpoint";

export type OrderItem = {
  cur_value: null;
  delivery_address: string;
  delivery_city: string;
  delivery_company: null;
  delivery_country: string;
  delivery_method: string;
  delivery_name: string;
  delivery_nip: null;
  delivery_number: null;
  delivery_number2: null;
  delivery_other: null;
  delivery_post_office: null;
  delivery_postcode: string;
  delivery_postzone: string;
  delivery_price: string;
  delivery_price_gross: null;
  delivery_surname: string;
  delivery_select: string;
  discount_value: null;
  discount_value_gross: null;
  id_card: number;
  id_cur: null;
  id_delivery: number;
  id_discount: null;
  id_lng: null;
  id_ord: null;
  id_payment: number;
  id_user: string | null;
  order_consent_adult: null;
  order_consent_newsletter: null;
  order_consent_phone: null;
  order_date_finished: null;
  order_date_modified: null;
  order_date_purchased: null;
  order_desc: string | null;
  order_desc_admin: null;
  order_discount: null;
  order_final_value: string;
  order_final_value_gross: string;
  order_invoice: null;
  order_privacy_policy: null;
  order_promotions: null;
  order_rules: number | null;
  order_status: number;
  order_status_payment: number;
  order_value: null;
  order_value_gross: null;
  order_weight: string;
  payment_method: null;
  payment_price: null;
  payment_price_gross: null;
  user_address: string | null;
  user_city: string | null;
  user_company: string | null;
  user_country: string | null;
  user_email: string | null;
  user_is_tax: null;
  user_name: string | null;
  user_nip: string | null;
  user_number: string | null;
  user_number2: string | null;
  user_phone: string | null;
  user_post_office: string | null;
  user_postcode: string | null;
  user_postzone: string;
  user_regon: string | null;
  user_surname: string | null;
  user_type: number;
  products: {
    id_opro: number; // number
    id_ord: number; // number
    id_pro: number; // number
    id_pro_user: string;
    opro_comment: string;
    opro_image: string;
    opro_model: string;
    opro_name: string;
    opro_options: string;
    opro_price: number; // number
    opro_price_gross: number; // number
    opro_quantity: number; // number
    opro_size: string;
    opro_tax: number; // number
    opro_value: number; // number
    opro_value_gross: number; // number
    opro_weight: number; // number
  }[];
};

type Response = {
  orders: OrderItem[];
};

type A = { guest_token: string };
type B = { customer_token: string };
type Request = (A | B) & {
  order_id?: string;
};

function action(inputData: Request) {
  return jsonApiFetch<Response>(
    "/order",
    JSON.stringify({
      ...inputData
    })
  );
}

export { action as order, Response as OrderResponse };
