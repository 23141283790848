import { jsonApiFetch } from "./endpoint";

type Request = {
  deviceUuid: string;
  pushToken: string;
};
type Response = {
  guest_token: string;
  checksum: string;
  errors_count: number;
};

function action(inputData: Request) {
  return jsonApiFetch<Response>(
    "/cartPrepare",
    JSON.stringify({
      push_token: inputData.pushToken,
      device_uuid: inputData.deviceUuid
    })
  );
}

export { action as cartPrepare, Response as CartPrepareResponse };
