











































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import serviceCustomer from "@/services/customer";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class ResetPassword extends Vue {
  private formData = {
    customer: ""
  };
  private lock = false;
  private sent = false;
  private message = "";

  /******************************/

  async onSubmit() {
    this.lock = true;

    const result = await serviceCustomer.resetPassword(this.formData.customer);

    //eslint-disable-next-line
    debugger;

    if (result !== null) {
      if (result.valid()) {
        this.sent = true;
        this.message = result.message as string;
      } else {
        type Errors = { [key: string]: string[] };
        const errors = {} as Errors;
        result.getErrors().map(row => {
          errors[row.key] = errors[row.key] || [];
          errors[row.key].push(row.message);
        });
        (this.$refs.form as {
          setErrors(errors: Errors): void;
        } & Vue).setErrors(errors);
        this.lock = false;
      }
    } else {
      this.sent = true;
      this.message = window.t("Błąd serwera");
      this.lock = false;
    }
  }
}
