var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 mb-5"},[(_vm.getContent('home_slider').length)?_c('Swiper',{staticClass:"swiper swiper-main",attrs:{"options":{
      pagination: {
        el: '.swiper-main .swiper-pagination'
      }
    }}},[_vm._l((_vm.getContent('home_slider')),function(item,index){return [_c('SwiperSlide',{key:index,attrs:{"options":{
          grabCursor: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets'
          }
        }}},[(item.filters || item.url)?_c('router-link',{attrs:{"to":item.filters || item.url}},[_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[(item.text_raw)?_c('div',{staticClass:"embed-responsive-item",style:({
                backgroundImage: ("url('" + (_vm.getPicture(
                  item.picture,
                  1000,
                  560
                )) + "')")
              })},[_c('div',{staticClass:"text p-3",domProps:{"innerHTML":_vm._s(item.text_raw)}})]):_vm._e(),(!item.text_raw)?_c('div',{staticClass:"embed-responsive-item p-0"},[_c('img',{staticClass:"d-block m-0",attrs:{"src":_vm.getPicture(item.picture, 1000, 560)}})]):_vm._e()])]):_vm._e()],1)]}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2):_vm._e(),(_vm.getContent('home_slider_btn').length)?_c('Swiper',{staticClass:"swiper swiper-main-links pt-G2 px-G1 mx-Gp2",attrs:{"options":{
      slidesPerView: 'auto'
    }}},[_vm._l((_vm.getContent('home_slider_btn')),function(item,index){return [_c('SwiperSlide',{key:("" + index),staticClass:"d-flex justify-content-stretch"},[_c('router-link',{staticClass:"w-100 d-flex",attrs:{"to":item.url || '/'}},[_c('Tile',{attrs:{"text":item.text_raw,"image":("<img width='100'\n          src='" + (_vm.getBaseUrl()) + (item.picture_raw) + "' />")}})],1)],1)]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }