






















































































import { Component, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import serviceLayout, { CategoryItem } from "@/services/layout";
import { Brand } from "@/services/connection";

@Component({
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class SwiperBrands extends Vue {
  private loading = true;
  private layoutBrands = serviceLayout.getBrands();

  beforeMount() {
    serviceLayout.on("changed-brands", this.onLayoutBrandsChange);

    if (this.layoutBrands.length) {
      this.loading = false;
    }
  }
  beforeDestroy() {
    serviceLayout.off("changed-brands", this.onLayoutBrandsChange);
  }

  onLayoutBrandsChange(list: Brand[]) {
    this.loading = false;
    this.layoutBrands = list;
  }
}
