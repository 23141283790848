







































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/views/layout/header";
import Modal from "@/views/layout/Modal";
import serviceLayout, { CategoryItem, MenuItem } from "@/services/layout";
import serviceProductList from "@/services/productList";
import serviceSpeechRecognition from "@/services/speechRecognition";
import { ContentResponse } from "@/services/connection";

@Component
export default class DrawerMenu extends Vue {
  menuMainVisible = false;
  menuMain = serviceLayout.getMenuMain();
  menuCategories = serviceLayout.getMenuCategories();
  menuCategoriesSelectStack = [] as MenuItem[];
  btnMicStatus = "btn-outline-secondary";
  searchValue = "";
  searchResults = [] as MenuItem[];
  searchClass = {
    focus: false
  };

  beforeMount() {
    serviceLayout.on("changed-menu-main", this.onMenuMain);
    serviceLayout.on("changed-menu-main-visible", this.onMenuMainVisible);
  }
  beforeDestroy() {
    serviceLayout.off("changed-menu-main", this.onMenuMain);
    serviceLayout.off("changed-menu-main-visible", this.onMenuMainVisible);
  }

  onMenuMain() {
    this.menuMain = serviceLayout.getMenuMain();
  }
  onMenuMainVisible(value: boolean) {
    this.menuMainVisible = value;
  }
  handleMenuMainClose() {
    serviceLayout.setMenuVisible(false);
  }
}
