
















































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  Swiper,
  SwiperSlide,
  directive as swiperDirective
} from "vue-awesome-swiper";
import ProductsSwiper from "@/components/SwiperProducts.vue";
import serviceLayout from "@/services/layout";
import { Content } from "@/services/connection";

@Component({
  components: {
    Swiper,
    SwiperSlide,
    ProductsSwiper
  },
  directives: {
    swiper: swiperDirective
  }
})
export default class SwiperProducts extends Vue {
  private activeTab = "";
  private contents = serviceLayout.getContents();
  private promotions = serviceLayout.getPromotions();
  private selected = null as Content | null;
  private selectedSlug = "";
  private swiperNav: any;

  private encodeURIComponent!: (input: string) => string;

  beforeMount() {
    serviceLayout.on("changed-contents", this.onContentsChanged);
    serviceLayout.on("changed-promotions", this.onPromotionsChanged);
    this.onPromotionsChanged();
    this.selectFirst();
  }
  beforeDestroy() {
    serviceLayout.off("changed-contents", this.onContentsChanged);
    serviceLayout.off("changed-promotions", this.onPromotionsChanged);
  }

  onContentsChanged() {
    this.$set(this, "contents", serviceLayout.getContents());
    this.selectFirst();
  }

  onPromotionsChanged() {
    this.$set(this, "promotions", serviceLayout.getPromotions());
    this.selectFirst();
  }

  selectFirst() {
    if (this.selected === null) {
      if (this.contents["promotions"]) {
        if (Array.isArray(this.contents["promotions"])) {
          for (let i = 0; i < this.contents["promotions"].length; i++) {
            const item = this.contents["promotions"][i];
            if (
              this.promotions[item.slug] &&
              this.promotions[item.slug].length
            ) {
              this.selectList(item, 0);
              break;
            }
          }
        } else {
          this.selectList(this.contents["promotions"] as Content, 0);
        }
      }
    }
  }

  selectList(item: Content, index: number) {
    if (this.$refs.swiperNav) {
      (this.$refs.swiperNav as any).$swiper.slideTo(index, 100, false);
    }
    if (this.$refs.swiperProducts) {
      (this.$refs.swiperProducts as any).$swiper.slideTo(0, 100, false);
    }
    this.selected = item;
    this.selectedSlug = item.slug;
  }
}
