







































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CartResponse } from "@/services/connection";
import serviceCart from "@/services/cart";

@Component
export default class Layout extends Vue {}
