














































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Input from "./Input.vue";

@Component({
  components: {
    ValidationProvider,
    Input
  }
})
export default class FormAddress extends Vue {
  @Prop({ default: "" }) suffix!: string;
  @Prop({ required: true }) formData!: { [key: string]: string };
  @Prop({ required: true }) formPlaceholders!: { [key: string]: string };

  beforeMount() {
    this.$props.formData[`${this.$props.suffix}country`] =
      this.$props.formData[`${this.$props.suffix}country`] || "";
  }
}
