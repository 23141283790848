import Events from "@/libs/events";
import * as uuid from "uuid";
import LazyPromise from "@/libs/LazyPromise";
import * as serviceConnection from "@/services/connection";
import serviceDevice from "@/services/device";

/******************************/

interface EventTypes {
  "after-hello": void;
  "after-login": void;
  "after-password-reset": void;
  "after-logout": void;
  "changed-profile": void;
}
interface EventTypesError {
  "after-hello-failed": Error;
  "after-login-failed": Error;
  "after-password-reset-failed": Error;
  "after-logout-failed": Error;
}
type EventKey = keyof EventTypes;
type EventKeyError = keyof EventTypesError;

/******************************/

class ServiceCustomer {
  protected events = new Events<EventTypes & EventTypesError>();
  protected status = {
    logged: false
  };
  protected profile = null as
    | null
    | serviceConnection.ProfileResponse["client"];
  protected addressList = null as
    | null
    | serviceConnection.AddressResponse["addresses"];
  protected store = {
    pushToken: null as string | null,
    guestToken: null as string | null,
    customerToken: null as string | null,
    longLifeToken: null as string | null
  };
  public redirectAfterLogin = "";
  protected placeholders = {
    nip: window.t("NIP"),
    company: window.t("Firma"),
    name: window.t("Imię"),
    surname: window.t("Nazwisko"),
    address: window.t("Ulica"),
    number: window.t("Numer budynku"),
    number2: window.t("Numer lokalu"),
    city: window.t("Miasto"),
    postcode: window.t("Kod pocztowy"),
    post_office: window.t("Poczta - miasto"),
    country: window.t("Kraj"),
    email: window.t("Adres e-mail"),
    password: window.t("Hasło"),
    confirmation: window.t("Powtórz hasło"),
    phone: window.t("Telefon"),
    www: window.t("Strona www")
  };

  public emit!: Events<EventTypes & EventTypesError>["emit"];
  public on!: Events<EventTypes & EventTypesError>["on"];
  public off!: Events<EventTypes & EventTypesError>["off"];

  /******************************/

  constructor() {
    this.on = this.events.on.bind(this.events);
    this.off = this.events.off.bind(this.events);

    if (process.env.NODE_ENV !== "production") {
      this.emit = (type: keyof EventTypes, ...value: any[]) => {
        console.log("%cFacetsCustomer", "color:orange;", type, value);
        return this.events.emit(type, value[0]);
      };
    } else {
      this.emit = this.events.emit.bind(this.events);
    }

    setTimeout(() => {
      if (this.getGuestToken() === null) {
        (async () => {
          const result = await serviceConnection.cartPrepare({
            deviceUuid: serviceDevice.getDeviceUuid(),
            pushToken: this.getPushToken() as string
          });

          if (result.valid()) {
            this.setGuestToken(result.guest_token);

            this.events.emit("after-hello");

            setTimeout(() => this.loginByToken());
          } else {
            this.events.emit("after-hello-failed", new Error("TODO"));
          }
        })();
      } else {
        this.events.emit("after-hello");
        setTimeout(() => this.loginByToken());
      }
    });
  }

  getPlaceholders() {
    return this.placeholders;
  }

  /******************************/

  protected getStorageProperty(key: keyof ServiceCustomer["store"]) {
    return (this.store[key] = this.store[key] || localStorage.getItem(key));
  }

  protected setStorageProperty(
    key: keyof ServiceCustomer["store"],
    value: string | null
  ) {
    this.store[key] = value;
    if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }

  protected createEventHandler(eventName: EventKey) {
    return (cb?: () => void, cberr?: (error: Error) => void) => {
      if (cb) {
        this.events.on(eventName, cb);
        if (cberr) {
          this.events.on(`${eventName}-failed` as EventKeyError, cberr);
        }
        return null;
      } else {
        const promise = new LazyPromise();
        this.events.once(eventName, promise.resolve);
        this.events.once(
          `${eventName}-failed` as EventKeyError,
          promise.reject
        );
        return promise.get();
      }
    };
  }

  /******************************/

  getStatus() {
    return this.status;
  }

  getPushToken() {
    const pushToken = this.getStorageProperty("pushToken");

    if (pushToken !== null) {
      return pushToken;
    }

    const newUuid = uuid.v4();

    this.setPushToken(newUuid);
    return newUuid;
  }
  setPushToken(value: string | null) {
    this.setStorageProperty("pushToken", value);
  }

  getGuestToken() {
    const value = this.getStorageProperty("guestToken");

    if (value === undefined || value === "undefined") {
      return null;
    }

    return value;
  }
  setGuestToken(value: string | null) {
    this.setStorageProperty("guestToken", value);
  }

  getCustomerToken() {
    const value = this.getStorageProperty("customerToken");

    if (value === undefined || value === "undefined") {
      return null;
    }

    return value;
  }
  setCustomerToken(value: string | null) {
    this.setStorageProperty("customerToken", value);
  }

  getLongLifeToken() {
    const value = this.getStorageProperty("longLifeToken");

    if (value === undefined || value === "undefined") {
      return null;
    }

    return value;
  }
  setLongLifeToken(value: string | null) {
    this.setStorageProperty("longLifeToken", value);
  }

  /******************************/

  canLogin() {
    if (this.getGuestToken() === null) {
      return false;
    }
    if (this.getPushToken() === null) {
      return false;
    }
    return true;
  }
  isLogged() {
    return this.status.logged;
  }

  async login(email: string, password: string) {
    if (this.canLogin()) {
      try {
        const result = await serviceConnection.customerLogin({
          email,
          password,
          deviceUuid: serviceDevice.getDeviceUuid(),
          pushToken: this.getPushToken() as string,
          guestToken: this.getGuestToken() as string
        });

        if (result.valid()) {
          this.status.logged = true;

          this.setCustomerToken(result.customer_token);
          this.setLongLifeToken(result.long_life_token);

          this.events.emit("after-login");

          if (result.data.raw) {
            this.profile = result.data.raw as Exclude<
              ServiceCustomer["profile"],
              null
            >;
            this.profile.cli_password = "";
            this.events.emit("changed-profile");
          }
        } else {
          this.events.emit("after-login-failed", new Error("TODO"));
        }
        return result;
      } catch (error) {
        /*TODO*/
        return null;
      }
    } else {
      return null;
    }
  }

  async socialLogin(provider: string, token: string) {
    if (this.canLogin()) {
      try {
        const result = await serviceConnection.customerLogin({
          socialToken: token,
          socialService: provider,
          deviceUuid: serviceDevice.getDeviceUuid(),
          pushToken: this.getPushToken() as string,
          guestToken: this.getGuestToken() as string
        });

        if (result.valid()) {
          this.status.logged = true;

          this.setCustomerToken(result.customer_token);
          this.setLongLifeToken(result.long_life_token);

          this.events.emit("after-login");

          if (result.data.raw) {
            this.profile = result.data.raw as Exclude<
              ServiceCustomer["profile"],
              null
            >;
            this.profile.cli_password = "";
            this.events.emit("changed-profile");
          }
        } else {
          this.events.emit("after-login-failed", new Error("TODO"));
        }
        return result;
      } catch (error) {
        /*TODO*/
        return null;
      }
    } else {
      return null;
    }
  }

  async loginByToken() {
    const longLifeToken = this.getLongLifeToken();

    if (longLifeToken !== null) {
      try {
        const result = await serviceConnection.customerReclaim({
          long_life_token: longLifeToken
        });

        if (result.valid()) {
          this.status.logged = true;

          this.setCustomerToken(result.customer_token);
          this.setLongLifeToken(result.long_life_token);

          this.events.emit("after-login");

          if (result.data.raw) {
            this.profile = result.data.raw as Exclude<
              ServiceCustomer["profile"],
              null
            >;
            this.profile.cli_password = "";
            this.events.emit("changed-profile");
          }
        } else {
          this.events.emit("after-login-failed", new Error("TODO"));
        }
        return result;
      } catch (error) {
        /*TODO*/
        return null;
      }
    } else {
      return null;
    }
  }

  async logout() {
    if (!this.isLogged()) {
      return null;
    }

    try {
      const result = await serviceConnection.customerLogout({
        customer_token: this.getCustomerToken() as string
      });

      this.status.logged = false;
      this.setCustomerToken(null);
      this.setLongLifeToken(null);
      if (!result.valid()) {
        this.events.emit("after-logout-failed", new Error("TODO"));
      }
      this.events.emit("after-logout");

      return result;
    } catch (error) {
      /*TODO*/
      return null;
    }
  }

  async resetPassword(email: string) {
    if (this.canLogin()) {
      try {
        const result = await serviceConnection.customerPassword({
          email,
          deviceUuid: serviceDevice.getDeviceUuid(),
          pushToken: this.getPushToken() as string,
          guestToken: this.getGuestToken() as string
        });

        if (result.valid()) {
          this.events.emit("after-password-reset");
        } else {
          this.events.emit("after-password-reset-failed", new Error("TODO"));
        }
        return result;
      } catch (error) {
        /*TODO*/
        return null;
      }
    } else {
      return null;
    }
  }

  async register(data: {
    cli_nip?: string;
    cli_company?: string;
    cli_name: string;
    cli_surname: string;

    cli_email: string;
    cli_password: string;
    cli_confirm_password: string;

    cli_address: string;
    cli_number: string;
    cli_number2: string;
    cli_postcode: string;
    cli_country: string;
    cli_post_office: string;

    cli_www: string;
    cli_phone: string;

    accountType: "individual" | "company";
    checkboxes: { [key: string]: boolean };
  }) {
    try {
      const result = await serviceConnection.customerRegistration({
        ...data,
        // parsowanie zgód
        ...(checkboxes => {
          const result = {} as { [key: string]: 1 | 0 };
          for (const key in checkboxes) {
            result[key] = checkboxes[key] ? 1 : 0;
          }
          return result;
        })(data.checkboxes),
        cli_type: data.accountType === "individual" ? 0 : 1,

        device_uuid: serviceDevice.getDeviceUuid(),
        push_token: this.getPushToken() as string,
        guest_token: this.getGuestToken() as string
      });

      if (result.valid()) {
        this.status.logged = true;

        this.setCustomerToken(result.customer_token);
        this.setLongLifeToken(result.long_life_token);

        this.events.emit("after-login");

        if (result.data.raw) {
          this.profile = result.data.raw as Exclude<
            ServiceCustomer["profile"],
            null
          >;
          this.profile.cli_password = "";
          this.events.emit("changed-profile");
        }
      } else {
        this.events.emit("after-login-failed", new Error("TODO"));
      }

      return result;
    } catch (error) {
      /*TODO*/
    }
    return null;
  }

  /******************************/

  public async loadProfile() {
    const result = await serviceConnection.profile({
      ...(this.isLogged()
        ? { customer_token: this.getCustomerToken() as string }
        : { guest_token: this.getGuestToken() as string })
    });

    if (result.valid()) {
      this.profile = result.client;
      this.profile.cli_password = "";
      this.events.emit("changed-profile");
    }

    return result;
  }
  public async saveProfile(
    formData: Exclude<ServiceCustomer["profile"], null>
  ) {
    const result = await serviceConnection.profile({
      ...(this.isLogged()
        ? { customer_token: this.getCustomerToken() as string }
        : { guest_token: this.getGuestToken() as string }),
      client: formData
    });

    if (result.valid()) {
      this.profile = result.client;
      this.profile.cli_password = "";
      this.events.emit("changed-profile");
    }

    return result;
  }

  getProfile() {
    return this.profile;
  }

  public async loadAddresses() {
    const result = await serviceConnection.address({
      ...(this.isLogged()
        ? { customer_token: this.getCustomerToken() as string }
        : { guest_token: this.getGuestToken() as string })
    });

    if (result.valid()) {
      this.addressList = result.addresses;
    }

    return result;
  }
  public async saveAddresses(formData: { [kye: string]: string }) {
    const result = await serviceConnection.address({
      ...(this.isLogged()
        ? { customer_token: this.getCustomerToken() as string }
        : { guest_token: this.getGuestToken() as string }),
      address: {
        ...formData,
        cla_flag_payment: parseInt(formData.cla_flag_payment) || 0,
        cla_flag_delivery: parseInt(formData.cla_flag_delivery) || 0
      }
    });

    if (result.valid()) {
      this.addressList = result.addresses;
    }

    return result;
  }
  public async deleteAddresses(id: string) {
    const result = await serviceConnection.address({
      ...(this.isLogged()
        ? { customer_token: this.getCustomerToken() as string }
        : { guest_token: this.getGuestToken() as string }),
      delete: id
    });

    if (result.valid()) {
      this.addressList = result.addresses;
    }

    return result;
  }
  public getAddresses() {
    return this.addressList;
  }
  public getAddress(id: string) {
    if (this.addressList) {
      for (const item of this.addressList) {
        if (parseInt(item.id_cla) === parseInt(id)) {
          return item;
        }
      }
    }
    return null;
  }
  public async getOrLoadAddress() {
    if (!this.addressList) {
      await this.loadAddresses();
    }
    return this.getAddresses() as Exclude<ServiceCustomer["addressList"], null>;
  }

  /******************************/

  public onHello = this.createEventHandler("after-hello");
  public onLogin = this.createEventHandler("after-login");
  public onLogout = this.createEventHandler("after-logout");
}

/******************************/

const customerService = new ServiceCustomer();

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceCustomer"] = customerService;
}

export default customerService;
