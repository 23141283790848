import { Component, Vue } from "vue-property-decorator";
import serviceLayout from "@/services/layout";

@Component
export default class BottomBarAdditional extends Vue {
  additionalBars = [] as JSX.Element[];

  beforeMount() {
    this.updateBarList();
    serviceLayout.on("changed-additional-bottom-bars", this.updateBarList);
  }

  beforeDestroy() {
    serviceLayout.off("changed-additional-bottom-bars", this.updateBarList);
  }

  updateBarList() {
    this.additionalBars = serviceLayout.getBottomBarList();
  }

  render() {
    return (
      <div class="additional-bars">
        {this.additionalBars.map((item, key) => (
          <div class="additional-bar" key={key}>
            {item}
          </div>
        ))}
      </div>
    );
  }
}
