import Events from "@/libs/events";
import * as serviceConnection from "@/services/connection";
import serviceCustomer from "@/services/customer";

/******************************/

interface EventTypes {
  "changed-lock": boolean;
  "changed-cart": ServiceCart["cart"];
  "changed-wishlist": ServiceCart["wishlist"];
  "code-message": string;
}
type EventKey = keyof EventTypes;

/******************************/

class ServiceCart {
  protected events = new Events<EventTypes>();
  protected cart = {
    items_count: 0,
    items: [] as serviceConnection.CartRow[]
  };
  protected wishlist = {
    items_count: 0,
    items: [] as serviceConnection.CartRow[]
  };

  public on!: Events<EventTypes>["on"];
  public off!: Events<EventTypes>["off"];
  public emit!: Events<EventTypes>["emit"];

  /******************************/

  constructor() {
    this.on = this.events.on.bind(this.events);
    this.off = this.events.off.bind(this.events);

    if (process.env.NODE_ENV !== "production") {
      this.emit = (type: keyof EventTypes, ...value: any[]) => {
        console.log("%cCartService", "color:blue;", type, value);
        return this.events.emit(type, value[0]);
      };
    } else {
      this.emit = this.events.emit.bind(this.events);
    }

    serviceCustomer.on("after-hello", () => {
      this.getCart();
      this.getWishList();
    });
    serviceCustomer.on("after-login", () => {
      this.getCart();
      this.getWishList();
    });
    serviceCustomer.on("after-logout", () => {
      this.getCart();
      this.getWishList();
    });
  }

  /******************************/

  isProductInWishList(productId: number) {
    for (const item of this.wishlist.items) {
      if (item.product.id === productId) {
        return true;
      }
    }
    return false;
  }

  /******************************/

  public setCartDataFromCartResonse(
    data: serviceConnection.CartResponse,
    type: "cart" | "wishlist"
  ) {
    this[type] = data;
    this.events.emit(`changed-${type}` as "changed-cart", this[type]);
  }

  getCartData() {
    return this.cart;
  }
  getWishListData() {
    return this.wishlist;
  }

  protected async $getCart(type: "cart" | "wishlist") {
    const result = await serviceConnection.cartList({
      ...(serviceCustomer.isLogged()
        ? { customer_token: serviceCustomer.getCustomerToken() as string }
        : { guest_token: serviceCustomer.getGuestToken() as string }),
      type
    });

    if (result.valid()) {
      this.setCartDataFromCartResonse(result, type);
    }

    return result;
  }
  getCart() {
    return this.$getCart("cart");
  }
  getWishList() {
    return this.$getCart("wishlist");
  }

  protected async $addToCart(
    data: { product_id: number; quantity?: number },
    type: "cart" | "wishlist"
  ) {
    const result = await serviceConnection.cartAdd({
      ...(serviceCustomer.isLogged()
        ? { customer_token: serviceCustomer.getCustomerToken() as string }
        : { guest_token: serviceCustomer.getGuestToken() as string }),
      ...data,
      type
    });

    if (result.valid()) {
      this.setCartDataFromCartResonse(result, type);
    }

    return result;
  }
  addToCart(data: { product_id: number; quantity?: number }) {
    return this.$addToCart(data, "cart");
  }
  addToWishList(data: { product_id: number; quantity?: number }) {
    return this.$addToCart(data, "wishlist");
  }

  protected async $updateInCart(
    data: { product_id: number; quantity: number },
    type: "cart" | "wishlist"
  ) {
    const result = await serviceConnection.cartUpdate({
      ...(serviceCustomer.isLogged()
        ? { customer_token: serviceCustomer.getCustomerToken() as string }
        : { guest_token: serviceCustomer.getGuestToken() as string }),
      ...data,
      type
    });

    if (result.valid()) {
      this.setCartDataFromCartResonse(result, type);
    }

    return result;
  }
  updateInCart(data: { product_id: number; quantity: number }) {
    return this.$updateInCart(data, "cart");
  }
  updateInWishList(data: { product_id: number; quantity: number }) {
    return this.$updateInCart(data, "wishlist");
  }

  clear(type: "cart" | "wishlist") {
    this[type].items = [];
    this[type].items_count = 0;

    Object.assign(this[type], {
      items: [],
      items_count: 0,
      subtotal: 0,
      subtotal_formatted: "-",
      grand: 0,
      grand_formatted: "-",
      coupon_discount: 0,
      coupon_discount_formatted: "-",
      cost_payment: 0,
      cost_payment_formatted: "-",
      cost_delivery: 0,
      cost_delivery_formatted: "-"
    });

    this.emit(`changed-${type}` as EventKey, this[type]);
  }

  protected async $delFromCart(productId: number, type: "cart" | "wishlist") {
    const result = await serviceConnection.cartDel({
      ...(serviceCustomer.isLogged()
        ? { customer_token: serviceCustomer.getCustomerToken() as string }
        : { guest_token: serviceCustomer.getGuestToken() as string }),
      product_id: productId,
      type
    });

    if (result.valid()) {
      this.setCartDataFromCartResonse(result, type);
    }
    return result;
  }
  delFromCart(product_id: number) {
    return this.$delFromCart(product_id, "cart");
  }
  delFromWishList(product_id: number) {
    return this.$delFromCart(product_id, "wishlist");
  }

  /******************************/

  protected lock = false;

  isLock() {
    return this.lock;
  }
  setLock(value: boolean) {
    if (this.lock !== value) {
      this.lock = value;
      this.emit("changed-lock", this.lock);
    }
  }

  /******************************/

  protected discountData = {
    code: null as string | null,
    value: null as number | null
  };

  async applyDiscountCode(code: string): Promise<boolean> {
    this.setLock(true);

    try {
      const result = await serviceConnection.cartCoupon({
        coupon: code,
        ...(serviceCustomer.isLogged()
          ? { customer_token: serviceCustomer.getCustomerToken() as string }
          : { guest_token: serviceCustomer.getGuestToken() as string })
      });

      if (result.valid()) {
        this.emit("code-message", window.t("Kod przyjęty"));
        this.setLock(false);
        return true;
      } else {
        for (const error of result.errors_list) {
          this.emit("code-message", error.message);
        }
        this.setLock(false);
        return false;
      }
    } catch (error) {
      this.emit("code-message", "Error");
      this.setLock(false);
    }
    return false;
  }
}

/******************************/

const cartService = new ServiceCart();

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceCart"] = cartService;
}

export default cartService;
