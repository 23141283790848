






























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { CartResponse, Content } from "@/services/connection";
import serviceCart from "@/services/cart";
import serviceLayout from "@/services/layout";
import serviceCustomer from "@/services/customer";
import * as uuid from "uuid";

const paczkomaty = (function() {
  let iframe: HTMLIFrameElement;

  function createIframe() {
    //console.log("createIframe");
    iframe = document.createElement("iframe");

    iframe.setAttribute("width", "100%");
    iframe.setAttribute("height", "100%");
    Object.assign(iframe.style, {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: "0"
    });

    document.body.appendChild(iframe);

    if (iframe.contentDocument) {
      Object.assign(iframe.contentWindow, {
        callback: (point: any) => {
          null;
        },
        onClose() {
          Object.assign(iframe.style, { zIndex: "0", visibility: "hidden" });
        }
      });
      iframe.contentDocument.write(
        `<html><body style="background-color: rgba(0,0,0,0.2);">`,
        `<script src="https://geowidget.easypack24.net/js/sdk-for-javascript.js"></${""}script>`,
        `<link rel="stylesheet" href="https://geowidget.easypack24.net/css/easypack.css"/>`,
        `<script>
          window.easyPackAsyncInit = function() {
            easyPack.init({
              defaultLocale: "pl",
              mapType: "osm",
              searchType: "osm",
              points: {
                types: ["parcel_locker"]
              },
              map: {
                initialTypes: ["parcel_locker"]
              }
            });
          };
          function openModal() {
            easyPack.modalMap(
              function(point, modal) {
                modal.closeModal();
                var name =
                  point.address_details.city +
                  " - " +
                  point.address.line1 +
                  " (" +
                  point.name +
                  ")";

                callback(point);
              },
              { width: 500, height: 600 }
            );
            document.querySelector(
              '.widget-modal__close'
            ).addEventListener(
              'click',
              window.onClose, false
            );
          }
          function init() {
            if (window.easyPackConfig) {
              openModal();
            } else {
              requestAnimationFrame(init);
            }
          }
          init();
        </${""}script>`,
        "</body></html>"
      );
    }
  }

  function open(callback: (point: any) => void) {
    const cb = (point: any) => {
      Object.assign(iframe.style, { zIndex: "0", visibility: "hidden" });
      callback(point);
    };

    if (!iframe) {
      createIframe();
    } else {
      (iframe.contentWindow as any).openModal();
    }
    Object.assign(iframe.style, { zIndex: "111111", visibility: "visible" });
    Object.assign(iframe.contentWindow, { callback: cb });
  }

  return { open };
})();

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class DeliveryAndPayments extends Vue {
  protected uuid = uuid.v4();
  protected lock = false;
  protected paczkomat = this.$service.order.getForm().delivery_value;

  async paymentChange() {
    this.lock = true;

    Promise.all([
      this.$service.order.createOrder(),
      new Promise(r => setTimeout(r, 400))
    ]).then(() => {
      this.lock = false;
    });
  }

  paczkomaty() {
    paczkomaty.open((point: any) => {
      const name = `${point.address_details.city} - ${point.address.line1} (${point.name})`;

      this.$service.order.getForm().delivery_value = name;
      this.paczkomat = name;
    });
  }
}
