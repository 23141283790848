
































































































































import { Component, Vue } from "vue-property-decorator";

import {
  SearchAndFacetsResponse,
  CategoryResponse,
  Category
} from "@/services/connection";
import serviceProductList from "@/services/productList";
import serviceFacets from "@/services/facets";
import serviceCategoryMenu from "@/services/categoryMenu";
import Categories from "@/models/categories";
import Product from "./Product.vue";
import Pagination from "./Pagination.vue";
import ProductFilter from "./ProductFilter.vue";
import ProductPriceFilter from "./ProductPriceFilter.vue";
import ProductFilterLabels from "./FilterLabels.vue";
import Back from "@/components/RouteTopBack.vue";
import Header from "./Header.vue";

@Component({
  components: {
    Pagination,
    ProductPriceFilter,
    Product,
    ProductFilter,
    ProductFilterLabels,
    Back,
    Header
  }
})
export default class ProductList extends Vue {
  private productList = serviceProductList.getProductList();
  private categories = serviceProductList.getCategories();
  private category = serviceProductList.getCategory();
  private brand = serviceProductList.getBrand();
  private totalItems = serviceProductList.getTotal();
  private loader = false;

  /******************************/

  private updateCategories(categories: Category[] | null) {
    this.categories = categories;
  }
  private updateCategory() {
    this.category = serviceProductList.getCategory();
    this.brand = serviceProductList.getBrand();
  }
  private updateProductList(productList: ProductList["productList"]) {
    this.productList = productList;
  }
  private updateTotalProductCount(value: number) {
    this.totalItems = value;
  }
  private updateUrl(urlParams: string) {
    const newUrl = `/products${urlParams}`;

    if (this.$router.currentRoute.path !== newUrl) {
      this.$router.push({ path: `/products${urlParams}` });
    }
  }
  private loaderHide() {
    this.loader = false;
  }
  private loaderShow() {
    this.loader = true;
  }

  /******************************/

  beforeMount() {
    this.$watch("$route", () => this.routeReadParams());

    if (process.env.NODE_ENV !== "production") {
      Object.assign(window, { $pageProductList: this });
    }

    serviceProductList.on("loaded-categories", this.updateCategories);
    serviceProductList.on("changed-category", this.updateCategory);
    serviceProductList.on("changed-brand", this.updateCategory);
    serviceProductList.on("loading-products", this.loaderShow);
    serviceProductList.on("loaded-products", this.loaderHide);
    serviceProductList.on("changed-productList", this.updateProductList);

    serviceProductList.on("changed-total", this.updateTotalProductCount);
    serviceProductList.on("changed-urlParams", this.updateUrl);

    this.$service.customer.on("after-login", this.updateCategory);
  }
  beforeDestroy() {
    serviceProductList.off("loaded-categories", this.updateCategories);
    serviceProductList.off("changed-category", this.updateCategory);
    serviceProductList.off("changed-brand", this.updateCategory);
    serviceProductList.off("loading-products", this.loaderShow);
    serviceProductList.off("loaded-products", this.loaderHide);
    serviceProductList.off("changed-productList", this.updateProductList);

    serviceProductList.off("changed-total", this.updateTotalProductCount);
    serviceProductList.off("changed-urlParams", this.updateUrl);

    this.$service.customer.off("after-login", this.updateCategory);
  }

  mounted() {
    this.routeReadParams();
    if (this.productList.length === 0) {
      serviceProductList.requestUpdateList();
    }
  }

  /******************************/

  routeReadParams() {
    serviceProductList.readUrlParams(
      this.$router.currentRoute.params.pageParams || ""
    );
  }

  /******************************/

  handlerBackCategory() {
    if (this.category) {
      serviceCategoryMenu.openWith(this.category.parent_id);
    } else {
      serviceCategoryMenu.openWith(0);
    }
  }

  handlerBackBrand() {
    this.$router.push("/brands");
  }

  showFilters() {
    serviceFacets.emit("changed-visible", true);
  }
  hideFilters() {
    serviceFacets.emit("changed-visible", false);
  }

  sortBy(value: number) {
    serviceProductList.setSort(value);
  }
}
