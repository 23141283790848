







































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CartResponse } from "@/services/connection";
import serviceCart from "@/services/cart";
import serviceLayout from "@/services/layout";
import serviceOrder from "@/services/order";
import * as uuid from "uuid";
import ListItem from "@/views/cart/ListItem.vue";
import OrderPayer from "./OrderPayer.vue";
import OrderDelivery from "./OrderDelivery.vue";

@Component({
  components: {
    ListItem,
    OrderPayer,
    OrderDelivery
  }
})
export default class Confirm extends Vue {
  protected uuid = uuid.v4();
  protected cart = null as CartResponse | null;
  protected order = this.$service.order.getForm();
  protected delivery = this.$service.order.getDelivery("-1");
  protected payment = this.$service.order.getPayment("-1");

  beforeMount() {
    serviceLayout.setBottomBar(this.uuid, this.renderBottomBar());
    this.getCart();
  }
  mounted() {
    serviceLayout.emit("changed-bottom-bar-visible", false);
    serviceCart.on("changed-cart", this.getCart);
  }
  beforeDestroy() {
    serviceLayout.removeBottomBar(this.uuid);
    serviceCart.off("changed-cart", this.getCart);
  }
  destroyed() {
    serviceLayout.emit("changed-bottom-bar-visible", true);
  }

  renderBottomBar(): JSX.Element {
    return this.cart && this.cart.items && this.cart.items.length ? (
      <div class="btn-order-bottom-bar">
        <div class="container d-flex justify-content-between">
          <button class="btn btn-primary" onClick={this.handlerNext}>
            {window.t("Zamawiam z obowiązkiem zapłaty")}
          </button>
        </div>
      </div>
    ) : (
      <span></span>
    );
  }

  getCart() {
    const cart = serviceCart.getCartData();
    this.$set(this, "cart", cart);

    this.order = this.$service.order.getForm();
    this.delivery = this.$service.order.getDelivery(
      this.order.delivery_method || "-1"
    );
    this.payment = this.$service.order.getPayment(
      this.order.payment_method || "-1"
    );
    serviceLayout.setBottomBar(this.uuid, this.renderBottomBar());
  }

  async handlerNext() {
    const result = await serviceOrder.finishOrder();
    if (result.valid()) {
      this.$router.push("/order/success");
    } else {
      this.$router.push("/order");
    }
  }
}
