import Vue from "vue";
import VueRouter, { RouterOptions, RouteConfig, Route } from "vue-router";
import Home from "@/views/home/Home.vue";
import Product from "@/views/Product.vue";
import ProductList from "@/views/productList";
import Filters from "@/views/filters";
import serviceCustomer from "@/services/customer";
import serviceLayout from "@/services/layout";
import serviceCategoryMenu from "@/services/categoryMenu";

import AccountLayout from "@/views/account/Layout.vue";
import AccountData from "@/views/account/data/Layout.vue";
import AccountDataProfile from "@/views/account/data/Profile.vue";
import AccountDataAddresses from "@/views/account/data/Addresses.vue";
import AccountDataOrders from "@/views/account/data/Orders.vue";
import Login from "@/views/account/login/Layout.vue";
import ResetPassword from "@/views/account/ResetPassword.vue";
import Registration from "@/views/account/registration/Main.vue";
import RegistrationIndividual from "@/views/account/registration/Individual.vue";
import RegistrationCompany from "@/views/account/registration/Company.vue";

import CartLayout from "@/views/cart/Layout.vue";
import CartCurrent from "@/views/cart/Current.vue";
import CartWishlist from "@/views/cart/Wishlist.vue";

import OrderLayout from "@/views/order/Layout.vue";
import OrderForm from "@/views/order/Form.vue";
import OrderConfirm from "@/views/order/Confirm.vue";
import OrderSuccess from "@/views/order/Success.vue";

import ArticleList from "@/views/article/List.vue";
import ArticleItem from "@/views/article/Item.vue";

import PageBrands from "@/views/PageBrands.vue";
import Contact from "@/views/Contact.vue";

import Scanner from "@/views/Scanner.vue";
import NotFound from "@/views/NotFound.vue";
import serviceOrder from "@/services/order";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/products",
    name: "Product list",
    component: ProductList
  },
  {
    path: "/products/:pageParams",
    name: "Product list",
    component: ProductList
  },
  {
    path: "/filters",
    name: "Filters",
    component: Filters
  },

  {
    path: "/account",
    name: "Account",
    component: AccountLayout,
    redirect(to: Route) {
      if (serviceCustomer.isLogged()) {
        return "/account/data";
      } else {
        return "/account/login";
      }
    },
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        // eslint-disable-next-line
        //@ts-ignore
        beforeEnter(to, from, next: (nextLink?: string) => void) {
          if (serviceCustomer.isLogged()) {
            next("/account/data");
          } else {
            next();
          }
        }
      },
      {
        path: "logout",
        name: "Logout",
        // eslint-disable-next-line
        //@ts-ignore
        beforeEnter(to, from, next: (nextLink?: string) => void) {
          serviceCustomer.logout();
          next("/");
        }
      },
      {
        path: "data",
        name: "Account data",
        component: AccountData,
        // eslint-disable-next-line
        //@ts-ignore
        beforeEnter(to, from, next: (nextLink?: string) => void) {
          if (serviceCustomer.isLogged()) {
            next();
          } else {
            next("/");
          }
        },
        redirect(to: Route) {
          return "/account/data/profile";
        },
        children: [
          {
            path: "profile",
            name: "Account - profile",
            component: AccountDataProfile
          },
          {
            path: "addresses",
            name: "Account - address list",
            component: AccountDataAddresses,
            children: [
              {
                path: ":id",
                name: "Account - address list - edit"
              }
            ]
          },
          {
            path: "orders",
            name: "Account - order list",
            component: AccountDataOrders,
            children: [
              {
                path: ":id",
                name: "Account - order list - show"
              }
            ]
          }
        ]
      },
      {
        path: "registration",
        name: "Registration",
        component: Registration,
        children: [
          {
            path: "individual",
            name: "Individual registion",
            component: RegistrationIndividual
          },
          {
            path: "company",
            name: "Company registion",
            component: RegistrationIndividual
          }
        ]
      },
      {
        path: "reset-password",
        name: "Reset password",
        component: ResetPassword
      }
    ]
  },
  {
    path: "/logout",
    name: "Logout",
    // eslint-disable-next-line
    //@ts-ignore
    async beforeEnter(to, from, next: (nextLink?: string) => void) {
      if ((await serviceCustomer.logout()) !== null) {
        serviceLayout.addModal({
          header: "You are log out",
          cancel: false
        });
      }
      next("/");
    }
  },

  {
    path: "/cart",
    name: "Cart",
    component: CartLayout,
    redirect(to: Route) {
      return "/cart/current";
    },
    children: [
      {
        path: "current",
        name: "Cart",
        component: CartCurrent
      },
      {
        path: "wishlist",
        name: "Wishlist",
        component: CartWishlist
      }
    ]
  },

  {
    path: "/order",
    name: "order",
    component: OrderLayout,
    redirect(to: Route) {
      return "/order/delivery-and-payments";
    },
    children: [
      {
        path: "delivery-and-payments",
        name: "Delivery and Payments",
        component: OrderForm
      },
      {
        path: "confirm",
        name: "Confirm",
        component: OrderConfirm
      },
      {
        path: "success",
        name: "Success",
        component: OrderSuccess
      }
    ]
  },

  {
    path: "/product/:productId",
    name: "Product",
    component: Product
  },

  {
    path: "/articles/:slug",
    name: "Article list",
    component: ArticleList
  },
  {
    path: "/article/:slug",
    name: "Article",
    component: ArticleItem
  },

  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/brands",
    name: "Brands",
    component: PageBrands
  },

  { path: "/barcode-scanner", name: "Barcode scanner", component: Scanner },
  { path: "*", name: "404", component: NotFound }
];

const isEcreo = location.host.search("test.ecreo.eu") !== -1 ? true : false;
const router = new VueRouter({
  mode: isEcreo ? undefined : "history",
  routes,
  scrollBehavior() {
    //@ts-ignore
    window.document.getElementById("app").scrollIntoView();
  }
});
// eslint-disable-next-line
//@ts-ignore
router.beforeEach((to, from, next: (nextLink?: string) => void) => {
  if (serviceCustomer.redirectAfterLogin && to.path.search("/account") !== 0) {
    serviceCustomer.redirectAfterLogin = "";
  }
  if (process.env.NODE_ENV !== "production") {
    console.log("%cRoute", "font-size:16px;color:yellow;", to);
  }
  serviceLayout.setBottomBarVisible(true);
  serviceLayout.setMenuVisible(false);
  serviceCategoryMenu.hide();
  next();
});

export default router;
