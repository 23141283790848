import { jsonApiFetch } from "./endpoint";

type Response = {
  client: {
    cli_address: string;
    cli_adult: string;
    cli_city: string;
    cli_company: string;
    cli_country: string;
    cli_date: string;
    cli_desc: string;
    cli_discount: string;
    cli_email: string;
    cli_enabled: string;
    cli_image: string;
    cli_login: string;
    cli_login_count: null;
    cli_login_date: null;
    cli_login_ip: null;
    cli_name: string;
    cli_newsletter: string;
    cli_nip: string;
    cli_number: string;
    cli_number2: string;
    cli_password: string;
    cli_phone: string;
    cli_post_office: string;
    cli_postcode: string;
    cli_privacy_policy: string;
    cli_rules: string;
    cli_salesmanago_contact_id: null;
    cli_sms: string;
    cli_social_id: null;
    cli_social_name: null;
    cli_social_service: null;
    cli_social_url: null;
    cli_surname: string;
    cli_type: string;
    cli_www: string;
    id_cgr: string;
    id_cli: string;
    id_src: null;
    mobile_details: string;
    mobile_device: string;
    mobile_push: string;
    mobile_token: string;
    mobile_token_long: string;
    mobile_uuid: string;
  };
};

type A = { guest_token: string };
type B = { customer_token: string };
type Request = (A | B) & { client?: Response["client"] };

function action(inputData: Request) {
  return jsonApiFetch<Response>(
    "/profile",
    JSON.stringify({
      ...inputData
    })
  );
}

export { action as profile, Response as ProfileResponse };
