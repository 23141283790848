import Vue from "vue";
import "@/language";
import { getPicture } from "@/helper";
import { getBase } from "./services/connection/endpoint";
//@ts-ignore
import noImage from "!raw-loader!@/assets/no-image.svg";

const noImageUri = `data:image/svg+xml;base64,${btoa(noImage)}`;

import serviceLayout from "@/services/layout";
import serviceCustomer from "@/services/customer";
import serviceCart from "@/services/cart";
import serviceProductList from "@/services/productList";
import serviceFacets from "./services/facets";
import serviceCategoryMenu from "@/services/categoryMenu";
import serviceOrder from "@/services/order";
import * as serviceConnection from "@/services/connection";

history.pushState({ ...history.state, init: true }, "init", location.hash);

Vue.use({
  install(Vue) {
    Vue.prototype.getPicture = function(
      url: string,
      width: number,
      height: number
    ) {
      return getPicture(url, width, height);
    };
    Vue.prototype.getBaseUrl = function() {
      return getBase();
    };
    Vue.prototype.noImage = function() {
      return noImageUri;
    };
    Vue.prototype.$service = {
      cart: serviceCart,
      productList: serviceProductList,
      facets: serviceFacets,
      categoryMenu: serviceCategoryMenu,
      layout: serviceLayout,
      order: serviceOrder,
      customer: serviceCustomer,
      connection: serviceConnection
    };
  }
});

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./styles/custom.scss";
import "./plugins/bootstrap-vue";
import "./plugins/element";

import "@/components/NavDDMenu";
import "@/services/speechRecognition";

import VueAxios from "vue-axios";
import axios from "axios";

/******************************/

/**
 * dyrektywa attrybut "v-bottom-bar"
 * przenosi dany element do dolnego paska
 */
Vue.directive("bottomBar", {
  inserted: function(el, a, node, c) {
    //szukanie miejsca docelowego
    const targetList = [
      ...document.querySelectorAll(".additional-bars").values()
    ];
    //lista referencji do elementów, trzeba usówać przy niszczenie danego komponentu
    const toRemove = [] as HTMLElement[];

    if (targetList.length) {
      //tu trafi aktywny działający element
      const target = targetList.pop();

      if (target !== undefined) {
        target.appendChild(el);
        toRemove.push(el);
        //kopie elementu
        for (const target of targetList) {
          const copy = el.cloneNode(true) as HTMLElement;
          target.appendChild(copy);
          toRemove.push(copy);
        }
        //nasłuch na zniszczenie komponentu
        node.context?.$once("hook:beforeDestroy", () => {
          for (const item of toRemove) {
            item.remove();
          }
        });
      }
    }
  }
});

const LL = (function() {
  let updateHasBeenRequested = false;
  const imageList = [] as HTMLImageElement[];

  function setNoIMage(this: HTMLImageElement) {
    this.src = noImageUri;
  }
  function add(element: HTMLImageElement) {
    imageList.push(element);
  }
  function update() {
    updateHasBeenRequested = false;

    for (const image of imageList) {
      const ddImage = image.getBoundingClientRect();

      if (
        ((ddImage.x < window.screen.width + 500 && ddImage.x > -500) ||
          (ddImage.x - ddImage.width < window.screen.width + 500 &&
            ddImage.x - ddImage.width > -500)) &&
        ((ddImage.y < window.screen.height + 500 && ddImage.y > -500) ||
          (ddImage.y - ddImage.height < window.screen.height + 500 &&
            ddImage.y - ddImage.height > -500))
      ) {
        image.src = image.dataset.src as string;
        image.addEventListener("error", setNoIMage);
        imageList.splice(imageList.indexOf(image), 1);
      }
    }
  }
  function requestUpdate() {
    if (updateHasBeenRequested === false) {
      updateHasBeenRequested = true;
      setTimeout(update);
    }
  }

  setInterval(requestUpdate, 500);

  return {
    register(element: HTMLImageElement) {
      add(element);
      requestUpdate();
    }
  };
})();

Vue.directive("lazy", {
  inserted: function(el, a, node, c) {
    if (el instanceof HTMLImageElement) {
      el.src = noImageUri;
      el.dataset.src = a.value;
      LL.register(el);
    }
  }
});

/******************************/

import VueAuthenticate from "vue-authenticate";

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: `${location.origin}`,

  providers: {
    storageType: "cookieStorage",
    bindRequestInterceptor: (...args: any[]) => {
      //console.log(args);
    },
    facebook: {
      clientId: "812930692563618", // "2745548648989893",
      redirectUri: `${location.origin}${location.pathname}`,
      responseType: "token"
    },
    google: {
      clientId:
        "397237036914-08qo0cgtha85o4fk317ui669e6sl629f.apps.googleusercontent.com",
      redirectUri: `${location.origin}${location.pathname}`,
      responseType: "token"
    }
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

/******************************/

const vhDetector = document.createElement("div");

Object.assign(vhDetector.style, {
  position: "absolute",
  top: 0,
  left: 0,
  height: "100vh",
  zIndex: -1
});

document.body.appendChild(vhDetector);

let updateHasBeenRequested = false;
function updateCssVarible() {
  //console.log("cssVarible");
  const rect = vhDetector.getBoundingClientRect();
  //const vh = Math.max(rect.height + rect.top, Math.min(screen.height, innerHeight)) * 0.01;
  const vh = (rect.height + rect.top) * 0.01;

  document.documentElement.style.setProperty("--vh", vh + "px");
}
function requestUpdateCssVarible() {
  if (updateHasBeenRequested === false) {
    updateHasBeenRequested = true;
    requestAnimationFrame(updateCssVarible);
  }
}

window.addEventListener("resize", () => {
  requestUpdateCssVarible();
  const items = document.querySelectorAll(".vh-100");
  for (let k = 0; k < items.length; k++) {
    const item = items[k] as HTMLElement;
    item.style.height = item.style.height + 0;
  }
});
window.addEventListener("touchmove", requestUpdateCssVarible);
window.addEventListener("load", requestUpdateCssVarible);
requestUpdateCssVarible();

/******************************/

import { extend } from "vee-validate";
import { email } from "vee-validate/dist/rules";

extend("email", {
  ...email,
  message: window.t("Adres email nie jest poprawny")
});
extend("required", {
  validate(value: any, args) {
    return { required: true, valid: !!value };
  },
  message: window.t("To pole jest wymagane"),
  computesRequired: true
});
extend("selectPayment", {
  validate(value: any, args) {
    return !!value;
  },
  message: window.t("Wybierz metodę płatności"),
  computesRequired: true
});
extend("selectDelivery", {
  validate(value: any, args) {
    return !!value;
  },
  message: window.t("Wybierz metodę dostawy"),
  computesRequired: true
});
