







import { Component, Prop, Vue } from "vue-property-decorator";
import { CartResponse } from "@/services/connection";
import RouteTopNav from "@/components/RouteTopNav.vue";
import serviceCart from "@/services/cart";

@Component({
  components: {
    RouteTopNav
  }
})
export default class Layout extends Vue {
  routeMap = {
    "/cart/current": window.t("Koszyk"),
    "/cart/wishlist": window.t("Przechowalnia")
  };
  cart = null as CartResponse | null;

  /******************************/

  beforeMount() {
    serviceCart.on("changed-wishlist", this.getCart);
    this.getCart();
  }
  beforeDestroy() {
    serviceCart.on("changed-wishlist", this.getCart);
  }

  /******************************/

  getCart() {
    const cart = serviceCart.getWishListData();
    const count = cart.items.length;

    if (count) {
      this.routeMap["/cart/wishlist"] = `${window.t(
        "Przechowalnia"
      )} (${count})`;
    } else {
      this.routeMap["/cart/wishlist"] = `${window.t("Przechowalnia")}`;
    }
  }
}
