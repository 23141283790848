export function getPicture(url: string, width: number, height: number): string {
  return url
    .replace(
      "%w%",
      (typeof width === "number" ? Math.floor(width) : 300).toString()
    )
    .replace(
      "%h%",
      (typeof height === "number" ? Math.floor(height) : 450).toString()
    );
}
