var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-layout"},[(_vm.$route.path === "/order/success")?_c('div',{staticClass:"row order-nav"},[_c('h1',{staticClass:"col d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.t("Realizacja"))+" ")])]):_vm._e(),(_vm.$route.path !== "/order/success")?_c('div',{staticClass:"row order-nav"},[_c('div',{staticClass:"col-back"},[_c('router-link',{staticClass:"btn btn-outline-secondary btn-back",attrs:{"to":_vm.$route.path === "/order/confirm"
            ? "/order/delivery-and-payments"
            : "/cart/current"}},[_c('span',{staticClass:"el-icon-back"})])],1),_c('div',{staticClass:"col-item item-first"},[_c('div',{staticClass:"icon"},[_c('router-link',{staticClass:"el-icon-check text-decoration-none",attrs:{"to":"/cart"}})],1),_c('router-link',{staticClass:"text text-decoration-none",attrs:{"to":"/cart"}},[_vm._v(_vm._s(_vm.t("Koszyk")))])],1),_c('router-link',{attrs:{"to":"/order/delivery-and-payments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var isActive = ref.isActive;
return [_c('div',{staticClass:"col-item",class:isActive ? 'active' : ''},[_c('div',{staticClass:"icon"},[(isActive)?_c('div',{staticClass:"el-icon-check"}):_vm._e(),(!isActive)?_c('a',{staticClass:"el-icon-check text-decoration-none",attrs:{"href":href}}):_vm._e()]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.t("Dostawa i płatność")))])])]}}],null,false,1186822696)}),_c('router-link',{attrs:{"to":"/order/confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var isActive = ref.isActive;
return [_c('div',{staticClass:"col-item item-last",class:isActive ? 'active' : ''},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"el-icon-check"})]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.t("Potwierdzenie")))])])]}}],null,false,1044275184)}),_c('div',{staticClass:"col-empty"})],1):_vm._e(),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }