import { jsonApiFetch } from "./endpoint";

type Brand = {
  id: number;
  position: number;
  name: string;
  slug: string;
  route: string;
  image: unknown;
};

type Response = {
  brands: Brand[];
};

function action() {
  return jsonApiFetch<Response>("/brand", undefined, "get");
}

export { action as brand, Response as BrandResponse, Brand };
