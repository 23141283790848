







































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { CartResponse, Content } from "@/services/connection";
import serviceLayout from "@/services/layout";
import FormName from "@/components/form/Name.vue";
import FormContact from "@/components/form/Contact.vue";
import FormAddress from "@/components/form/Address.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    FormName,
    FormContact,
    FormAddress
  }
})
export default class Profile extends Vue {
  protected customerStatus = this.$service.customer.getStatus();
  protected profile = this.$service.customer.getProfile();
  protected formPlaceholders = this.$service.customer.getPlaceholders();
  protected checkboxes: Content[] = [];
  protected checkboxMap: { [key: string]: Content } = {};
  protected saving = false;

  mounted() {
    this.$service.customer.on("after-login", this.changeProfile);
    this.$service.customer.on("changed-profile", this.changeProfile);
    serviceLayout.on("changed-contents", this.contentChange);
    this.contentChange();
    this.changeProfile();
  }
  beforeDestroy() {
    this.$service.customer.off("after-login", this.changeProfile);
    this.$service.customer.off("changed-profile", this.changeProfile);
    serviceLayout.off("changed-contents", this.contentChange);
  }

  contentChange() {
    const contents = serviceLayout.getContents();

    if (contents["form-checkboxes"]) {
      this.checkboxes = contents["form-checkboxes"] as Content[];
      for (const item of this.checkboxes) {
        this.checkboxMap[item.slug] = item;
      }
    }
  }
  changeProfile() {
    this.customerStatus = this.$service.customer.getStatus();
    this.profile = this.$service.customer.getProfile();
  }

  async save() {
    this.saving = true;
    let result;

    try {
      result = await this.$service.customer.saveProfile(
        this.profile as Exclude<Profile["profile"], null>
      );
    } catch (error) {
      result = null;
    }

    if (result !== null && !result.valid()) {
      type Errors = { [key: string]: string[] };
      const errors = {} as Errors;
      result.getErrors().map((row: any) => {
        errors[row.key] = errors[row.key] || [];
        errors[row.key].push(row.message);
      });
      (this.$refs.form as {
        setErrors(errors: Errors): void;
      } & Vue).setErrors(errors);
      this.$bvToast.toast(window.t("Nie udało się zapisać zmian"), {
        title: window.t("Konto"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "danger",
        headerClass: "d-none"
      });
      this.$nextTick(() => {
        const feedbackElement = document.querySelector(
          ".invalid-feedback.d-block"
        );

        if (feedbackElement) {
          const bBox = feedbackElement.getBoundingClientRect();
          //console.log(bBox);
          window.scrollTo(0, window.scrollY + bBox.top - 200);
        }
      });
    } else {
      this.$bvToast.toast(window.t("Zapisano"), {
        title: window.t("Konto"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    }
    this.saving = false;
  }
}
