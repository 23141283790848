import { jsonApiFetch } from "./endpoint";
import { CartResponse } from ".";

type A = { guest_token: string };
type B = { customer_token: string };
type Request = (A | B) & {
  type: "cart" | "wishlist";
};

function action(inputData: Request) {
  return jsonApiFetch<CartResponse>(
    "/cartList",
    JSON.stringify({ ...inputData })
  );
}

export { action as cartList };
