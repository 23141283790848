import { jsonApiFetch } from "./endpoint";

export type SearchCategoryItem = {
  id: string;
  parent_id: number;
  position: number;
  name: string;
  slug: string;
  route: string;
  path: string;
  countProduct: number;
  level: number;
  image: string | null;
  language_id: number;
};

type Response = {
  category: SearchCategoryItem[];
};

function action(searchParams: URLSearchParams) {
  return jsonApiFetch<Response>("/searchCategories", searchParams);
}

export { action as searchCategories, Response as SearchCategoriesResponse };
