import * as uuid from "uuid";
import Events from "@/libs/events";
import { CreateElement } from "vue/types/umd";
import * as serviceConnection from "@/services/connection";
import serviceProductList from "@/services/productList";
import serviceCategoryMenu from "@/services/categoryMenu";
import { Product, Content, ProductOnList } from "@/services/connection";
import { getBase } from "./connection/endpoint";
import serviceCustomer from "@/services/customer";

export type ModalSlotSope = {
  visible: boolean;
  ok: () => void;
  cancel: () => void;
  close: () => void;
  hide: () => void;
};
export type ModalRenderSlot = (
  h: CreateElement,
  slot: ModalSlotSope
) => JSX.Element;
type BsStyle =
  | "primary"
  | "secondary"
  | "success"
  | "warnig"
  | "danger"
  | "info"
  | "dark"
  | "light";

const defaultModal = {
  headerStyle: "primary" as BsStyle,
  bodyStyle: "light" as BsStyle,
  footerStyle: "light" as BsStyle
};

function createModal(
  data: {
    header: string;
    body?: string;
    headerSlot?: ModalRenderSlot;
    bodySlot?: ModalRenderSlot;
    footerSlot?: ModalRenderSlot;
    cancel?: false | "string";
  } & Partial<typeof defaultModal>
) {
  return {
    uuid: uuid.v4(),
    ...defaultModal,
    ...data
  };
}

/******************************/

type Contents = {
  [key: string]: serviceConnection.Content | serviceConnection.Content[];
};
interface EventTypes {
  "changed-menu-main": LayoutService["menuMain"];
  "changed-menu-main-visible": boolean;
  "changed-menu-categories": MenuItem;
  "changed-menu-categories-search": string;
  "changed-categories": CategoryItem[];
  "changed-contents": Contents;
  "changed-brands": LayoutService["brands"];
  "changed-promotions": LayoutService["promotions"];
  "changed-bottom-bar-visible": boolean;
  "changed-additional-bottom-bars": LayoutService["aditionalButtomBars"];
}
type EventKey = keyof EventTypes;

export type CategoryItem = {
  image?: string;
  name: string;
  url: string;
  picture?: string;
  totalItems: number;
  items?: CategoryItem[];
  id: number;
};

export type MenuItem = {
  id: number;
  parentId: number;
  level?: number;
  name: string | null;
  nameSuffix?: string;
  icon?: string;
  picture?: string;
  type: "url" | "category";
  value: string | null;
  categoryVisible?: boolean;
  items?: MenuItem[];
  raw?: serviceConnection.Category;
};
export type MenuGroup = {
  groupName?: string;
  items: MenuItem[];
};

/******************************/

function mapTo(parent: MenuItem) {
  return function maper(item: serviceConnection.Category) {
    const menuItem: MenuItem = {
      id: item.id,
      parentId: item.parent_id || 0,
      name: item.name,
      type: "category",
      value: item.id.toString(),
      items: [],
      picture: item.image ? `${getBase()}${item.image}` : "", //`https://picsum.photos/seed/${item.id}/54/54`,
      raw: item
    };

    (parent.items as MenuItem[]).push(menuItem);

    if (item.children) {
      item.children.map(mapTo(menuItem));
    }
  };
}

/******************************/

const REGE_SVG_FILE = new RegExp(".*\\.svg$");

class LayoutService {
  protected events = new Events<EventTypes>();
  protected modalList = [] as ReturnType<typeof createModal>[];
  protected menuMain = [
    {
      items: []
    }
  ] as MenuGroup[];
  protected menuCategories = null as MenuItem | null;

  protected categories = [] as CategoryItem[];
  protected menuVisible = false;
  protected bottomBarVisible = true;
  protected aditionalButtomBars = [] as JSX.Element[];
  protected aditionalButtomBarsMap = {} as { [key: string]: JSX.Element };
  protected brands = [] as serviceConnection.Brand[];
  protected contents = {} as Contents;
  protected promotions = {} as { [key: string]: ProductOnList[] };
  public searchState = {
    query: ""
  };

  public on!: Events<EventTypes>["on"];
  public off!: Events<EventTypes>["off"];
  public emit!: Events<EventTypes>["emit"];

  constructor() {
    this.on = this.events.on.bind(this.events);
    this.off = this.events.off.bind(this.events);

    if (process.env.NODE_ENV !== "production") {
      this.emit = (type: EventKey, ...value: any[]) => {
        console.log("%cLayoutService", "color:green;", type, value);
        return this.events.emit(type, value[0]);
      };
    } else {
      this.emit = this.events.emit.bind(this.events);
    }

    setTimeout(() => {
      serviceProductList.on("loaded-categories", categories => {
        const root: MenuItem = {
          id: 0,
          parentId: 0,
          name: null,
          value: "root",
          type: "category",
          items: []
        };

        if (categories !== null) {
          categories.map(mapTo(root));
          this.categories = categories.map(item => {
            const menuItem: CategoryItem = {
              id: item.id,
              name: item.name.replace(/\si\s/g, " i "),
              totalItems: 100,
              url: "#",
              picture: item.image ? `${getBase()}${item.image}` : "" //`https://picsum.photos/seed/${item.id}/54/54`
            };

            return menuItem;
          });

          this.menuCategories = root;
          this.emit("changed-categories", this.categories);
          this.emit("changed-menu-categories", this.menuCategories);
        }
      });
      (async () => {
        const result = await serviceConnection.brand();

        if (result.valid()) {
          this.brands = result.brands;
          this.emit("changed-brands", this.brands);
        }
      })();
      (async () => {
        const result = await serviceConnection.content();

        if (result.valid()) {
          this.contents = result;
          this.emit("changed-contents", this.contents);

          setTimeout(() => {
            this.updatePromotion(this.contents["promotions"] as Content[]);
          }, 1e3);

          if (
            Array.isArray(this.contents["main_menu"]) &&
            this.contents["main_menu"].length
          ) {
            for (const item of this.contents["main_menu"]) {
              if (item.title === "-") {
                this.menuMain.push({
                  items: []
                });
                continue;
              }

              let icon =
                '<div><span class="el-icon-document"><span class="d-none">&nbsp;</span></span></div>';

              if (item.class) {
                icon = `<div><span class="${item.class}"></span></div>`;
              }
              if (item.icon) {
                icon = atob(item.icon);
              }

              this.menuMain[this.menuMain.length - 1].items.push({
                id: 0,
                parentId: 0,
                name: item.title,
                value: item.url,
                type: "url",
                icon
              });
            }

            this.emit("changed-menu-main", this.menuMain);
          }
        }
      })();
    });

    window.addEventListener("popstate", event => {
      const value = (history.state && history.state.menuVisible) || false;

      if (value !== this.menuVisible) {
        this.menuVisible = value;
        this.emit("changed-menu-main-visible", value);
      }
    });
  }

  /******************************/

  updatePromotion(list: Content[]) {
    const promiseList = [];

    for (const item of list) {
      promiseList.push(
        (async () => {
          const result = await serviceConnection.search({
            filters: `${item.filters}&${
              serviceCustomer.isLogged()
                ? `customer_token=${serviceCustomer.getCustomerToken() as string}`
                : `guest_token=${serviceCustomer.getGuestToken() as string}`
            }`
          });

          if (result.valid()) {
            this.promotions[item.slug] = result.products;
          }
        })()
      );
    }

    return Promise.all(promiseList).then(() => {
      this.emit("changed-promotions", this.promotions);
    });
  }

  /******************************/

  addModal(data: Parameters<typeof createModal>[0]) {
    this.modalList.push(createModal(data));
  }
  getModalList() {
    return this.modalList;
  }

  /******************************/

  setMenuVisible(value: boolean) {
    this.menuVisible = this.isMenuVisible();

    if (this.menuVisible !== value) {
      const state = history.state || {};
      let flag = false;

      if (state.categoryMenuVisible) {
        state.categoryMenuVisible = false;
        flag = true;
      }

      history.pushState(
        {
          ...state,
          menuVisible: value
        },
        "",
        location.hash
      );
      this.emit("changed-menu-main-visible", value);
      if (flag) {
        serviceCategoryMenu.emit("changed-visible", false);
      }
    }
  }
  isMenuVisible() {
    return (history.state && history.state.menuVisible) || false;
  }
  isBottomMenuVisible() {
    return this.bottomBarVisible;
  }

  setBottomBarVisible(value: boolean) {
    this.bottomBarVisible = value;
    this.events.emit("changed-bottom-bar-visible", this.bottomBarVisible);
  }
  setBottomBar(key: string, bar: JSX.Element) {
    if (key in this.aditionalButtomBarsMap) {
      this.aditionalButtomBars.splice(
        this.aditionalButtomBars.indexOf(this.aditionalButtomBarsMap[key]),
        1,
        bar
      );
      this.aditionalButtomBarsMap[key] = bar;
    } else {
      this.aditionalButtomBars.push(bar);
      this.aditionalButtomBarsMap[key] = bar;
    }

    this.events.emit(
      "changed-additional-bottom-bars",
      this.aditionalButtomBars
    );
  }
  removeBottomBar(key: string) {
    if (key in this.aditionalButtomBarsMap) {
      this.aditionalButtomBars.splice(
        this.aditionalButtomBars.indexOf(this.aditionalButtomBarsMap[key]),
        1
      );
      delete this.aditionalButtomBarsMap[key];
    }

    this.events.emit(
      "changed-additional-bottom-bars",
      this.aditionalButtomBars
    );
  }
  getBottomBarList() {
    return this.aditionalButtomBars;
  }

  /******************************/

  getCategories() {
    return this.categories;
  }
  getBrands() {
    return this.brands;
  }
  getBrand(bid: number) {
    for (const brand of this.brands) {
      if (brand.id === bid) {
        return brand;
      }
    }
    return null;
  }
  getMenuCategories() {
    return this.menuCategories;
  }
  getMenuMain() {
    return this.menuMain;
  }
  getContents() {
    return this.contents;
  }
  getContentWithSlug(slug: string) {
    for (const key in this.contents) {
      const item = this.contents[key];

      if (Array.isArray(item)) {
        for (const subitem of item) {
          if (subitem.slug === slug) {
            return subitem;
          }
        }
      }
    }

    return null;
  }
  getPromotions() {
    return this.promotions;
  }

  remove(modalUuid: string) {
    for (const modal of this.modalList) {
      if (modal.uuid === modalUuid) {
        this.modalList.splice(this.modalList.indexOf(modal), 1);
      }
    }
  }
}

const service = new LayoutService();

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceLayout"] = service;
}

export default service;
