















import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";

@Component({
  components: {
    ValidationProvider
  }
})
export default class Input extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) formData!: { [key: string]: string };
}
