


































































import { Component, Vue } from "vue-property-decorator";
import serviceLayout from "@/services/layout";
import { Content } from "@/services/connection";
import Back from "@/components/RouteTopBack.vue";

@Component({
  components: {
    Back
  }
})
export default class ArticleList extends Vue {
  private main = null as Content | null;
  private contents = serviceLayout.getContents();

  getContent(name?: string) {
    if (!name) {
      name = this.$route.params.slug;
    }
    return this.contents[name] || [];
  }

  beforeMount() {
    serviceLayout.on("changed-contents", this.onContentsChanged);
    this.onContentsChanged();
  }
  beforeDestroy() {
    serviceLayout.off("changed-contents", this.onContentsChanged);
  }

  onContentsChanged() {
    this.main = serviceLayout.getContentWithSlug(this.$route.params.slug);
    this.contents = serviceLayout.getContents();
  }
}
