import { jsonApiFetch } from "./endpoint";

type A = { guest_token: string };
type B = { customer_token: string };
type Request = (A | B) & {
  type: "cart" | "wishlist";
};
type Response = {};

function action(inputData: Request) {
  return jsonApiFetch<Response>(
    "/cartListId",
    JSON.stringify({ ...inputData })
  );
}

export { action as cartListId, Response as CartListIdResponse };
