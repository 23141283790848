const EMPTY_FUNCTION = () => null;

/******************************/

export default class LazyPromise<T> {
  promise = null as Promise<T> | null;
  $resolve = EMPTY_FUNCTION as (data: T | undefined) => void;
  // eslint-disable-next-line
  $reject = EMPTY_FUNCTION as (error: any) => void;

  /******************************/

  reset() {
    this.promise = new Promise((resolve, reject) => {
      this.$resolve = (argument: T | undefined) => {
        return resolve(argument);
      };
      // eslint-disable-next-line
      this.$reject = (argument: any | undefined) => {
        return reject(argument);
      };
    });
  }

  /******************************/

  get() {
    if (this.promise === null) {
      this.reset();
    }

    return this.promise as Promise<T>;
  }

  resolve = (arg?: T) => {
    if (this.promise === null) {
      this.reset();
    }

    return this.$resolve(arg);
  };

  // eslint-disable-next-line
  reject = (error?: any) => {
    if (this.promise === null) {
      this.reset();
    }

    return this.$reject(error);
  };
}
