const KEY = "d21045b42361f67c7ab81833dd728481a5b84609";

export function getBase() {
  if (location.host.search("sparta.com.pl") !== -1) {
    return `//www.sparta.com.pl`;
  }
  return "//test.ecreo.eu/ecreo/sparta2";
}

export function getEndpointUrl() {
  return `${getBase()}/pl/web/api`;
}
export function getKey() {
  return KEY;
}

// wraper dla response
export type ResponseErrorRow = { key: string; message: string; code: number };
class Response {
  errors_count = 0;
  errors_list = [] as ResponseErrorRow[];

  valid() {
    if (this.errors_count !== 0) {
      return false;
    }

    return true;
  }

  getErrors() {
    if (this.valid()) {
      return [];
    }

    return this.errors_list;
  }
}
const responseProto = new Response();
export function wrapper<T>(obj: {}) {
  Object.setPrototypeOf(obj, responseProto);
  return obj as typeof responseProto & T;
}

// wraper dla fetch

import serviceCustomer from "@/services/customer";

const headers = new Headers();

export async function jsonApiFetch<T>(
  endpoint: string,
  data: URLSearchParams | FormData | string | undefined = undefined,
  method = "post"
) {
  const url = `${getEndpointUrl()}${endpoint}?key=${getKey()}`;

  if (process.env.NODE_ENV !== "production") {
    console.log(`>> ${endpoint} >> ${JSON.stringify(data)}`);
  }

  const request = await fetch(url, {
    method,
    body: data
    // headers: {
    //   'X-Rest-Key': 'd21045b42361f67c7ab81833dd728481a5b84609',
    //   'Content-Type': 'text/plain;charset=UTF-8',
    // },
    // mode: 'no-cors'
  });
  const resultData = await request.json();
  const result = wrapper<T>(resultData);

  const errorList = result.getErrors();
  for (const error of errorList) {
    if (error.key === "customer") {
      serviceCustomer.logout();
    }
  }

  return result;
}
