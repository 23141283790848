import { jsonApiFetch } from "./endpoint";

type ProductOnList = {
  id: number;
  title: string;
  subtitle: string;
  model: string;
  sku: string;
  description: string | null;
  price_brutto: number;
  price_brutto_currency: string;
  price_netto: number;
  price_netto_currency: string;
  price_old: number;
  price_old_currency: string;
  url: string;
  picture: string;
};

type Response = {
  total: number;
  products: ProductOnList[];
  sort_orders: {
    labe: string;
    sort_order: number;
    code: number;
  }[];
};

function action(
  options = {
    limit: 20,
    filters: ""
  } as {
    limit?: number;
    filters?: string;
  }
) {
  const searchParams = new URLSearchParams(options.filters);

  searchParams.append("limit", `${options.limit || 20}`);

  return jsonApiFetch<Response>("/search", searchParams);
}

export { action as search, Response as SearchResponse, ProductOnList };
