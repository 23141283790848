import serviceLayout from "@/services/layout";
import serviceProductList from "@/services/productList";
import * as serviceConnection from "@/services/connection";
import Events from "@/libs/events";
import { getBase } from "./connection/endpoint";

//#region types

interface EventTypes {
  "changed-visible": boolean;
  "changed-stack": CategoryMenuService["stack"];
  "changed-categories": CategoryMenuService["root"];
}
type EventKey = keyof EventTypes;

export type MenuItem = {
  id: number;
  parentId: number;
  level: number;
  parent: MenuItem | null;
  name: string | null;
  nameSuffix?: string;
  icon?: string;
  picture?: string;
  type: "url" | "category";
  value: string | null;
  categoryVisible?: boolean;
  items?: MenuItem[];
  productCount: number;
  raw?: serviceConnection.Category;
};

//#endregion
//#region constants

function mapCategories(
  parent: MenuItem,
  store: Map<number, MenuItem>,
  level = 0
) {
  return function maper(item: serviceConnection.Category) {
    const menuItem: MenuItem = {
      id: item.id,
      parentId: item.parent_id || 0,
      level,
      parent,
      name: item.name,
      type: "category",
      value: item.id.toString(),
      items: [],
      picture: item.image ? `${getBase()}${item.image}` : "", //`https://picsum.photos/seed/${item.id}/54/54`,
      raw: item,
      productCount: parseInt(item.countProduct)
    };

    (parent.items as MenuItem[]).push(menuItem);
    store.set(item.id, menuItem);

    if (item.children) {
      item.children.map(mapCategories(menuItem, store, level + 1));
    }
  };
}

//#endregion

class CategoryMenuService {
  protected events = new Events<EventTypes>();
  protected map_id_category = new Map<number, MenuItem>();
  protected topId = 0;
  protected root: MenuItem = {
    id: 0,
    parentId: 0,
    level: 0,
    parent: null,
    name: "",
    value: "root",
    type: "category",
    items: [],
    productCount: 0
  };
  protected stack = [] as {
    visible: boolean;
    menuItem: MenuItem;
  }[];
  protected visible = false;

  //#region constructor

  public on!: Events<EventTypes>["on"];
  public off!: Events<EventTypes>["off"];
  public emit!: Events<EventTypes>["emit"];

  constructor() {
    this.on = this.events.on.bind(this.events);
    this.off = this.events.off.bind(this.events);

    if (process.env.NODE_ENV !== "production") {
      this.emit = (type: EventKey, ...value: any[]) => {
        console.log("%cCategoryMenuService", "color:red;", type, value);
        return this.events.emit(type, value[0]);
      };
    } else {
      this.emit = this.events.emit.bind(this.events);
    }

    serviceProductList.on("loaded-categories", categories => {
      this.root = {
        id: 0,
        parentId: 0,
        level: 0,
        parent: null,
        name: window.t("Wszystkie kategorie"),
        value: "root",
        type: "category",
        items: [],
        productCount: 0
      };

      if (categories !== null) {
        categories.map(mapCategories(this.root, this.map_id_category, 1));
      }

      this.emit("changed-categories", this.root);
    });

    window.addEventListener("popstate", event => {
      const visible =
        (history.state && history.state.categoryMenuVisible) || false;
      const topId = (history.state && history.state.categoryMenuTopId) || 0;

      if (visible !== this.visible) {
        this.visible = visible;
        this.emit("changed-visible", visible);
      }

      if (topId !== this.topId) {
        this.topId = topId;
        this.openWith(topId);
      }
    });
  }

  //#endregion
  //#region visible
  isVisible() {
    return (history.state && history.state.categoryMenuVisible) || false;
  }
  protected setVisible(value: boolean) {
    this.visible = this.isVisible();

    if (this.visible !== value) {
      const state = history.state || {};
      let flag = false;

      if (state.menuVisible) {
        state.menuVisible = false;
        flag = true;
      }

      history.pushState(
        {
          ...state,
          categoryMenuVisible: value
        },
        "",
        location.hash
      );
      this.emit("changed-visible", value);
      if (flag) {
        serviceLayout.emit("changed-menu-main-visible", false);
      }
    }
  }
  show() {
    this.setVisible(true);
  }
  hide() {
    this.setVisible(false);
  }
  //#endregion

  getRoot() {
    return this.root;
  }
  getStack() {
    return this.stack;
  }
  push(categoryId: number) {
    const menuItem = this.map_id_category.get(categoryId);

    if (menuItem !== undefined) {
      const item = {
        visible: false,
        menuItem
      };

      this.stack.push(item);
      this.emit("changed-stack", this.stack);

      setTimeout(() => {
        item.visible = true;
        this.emit("changed-stack", this.stack);
      }, 30);
    }

    if (this.topId !== categoryId) {
      this.topId = categoryId;
      history.pushState(
        {
          ...history.state,
          categoryMenuTopId: this.topId
        },
        "",
        location.hash
      );
    }
  }
  pop(): void {
    if (this.stack.length) {
      this.stack[this.stack.length - 1].visible = false;
      this.emit("changed-stack", this.stack);

      setTimeout(() => {
        this.stack.pop();
        this.emit("changed-stack", this.stack);

        const categoryId = this.stack[this.stack.length - 1].menuItem.id;

        if (this.topId !== categoryId) {
          this.topId = categoryId;
          history.pushState(
            {
              ...history.state,
              categoryMenuTopId: this.topId
            },
            "",
            location.hash
          );
        }
      }, 300);
    }
  }
  clean() {
    this.stack = [];
    this.emit("changed-stack", this.stack);
  }
  openWith(categoryId: number) {
    const menuItem = this.map_id_category.get(categoryId);

    if (menuItem !== undefined) {
      const newStack = [] as CategoryMenuService["stack"];
      let currentItem = menuItem;

      while (currentItem) {
        if (currentItem.parent) {
          newStack.unshift({
            visible: true,
            menuItem: currentItem
          });
        } else {
          break;
        }
        currentItem = currentItem.parent;
      }

      this.stack = newStack;
      this.emit("changed-stack", this.stack);
      this.show();
    } else {
      this.stack = [
        {
          visible: true,
          menuItem: this.root
        }
      ];
      this.emit("changed-stack", this.stack);
      this.show();
    }

    if (this.topId !== categoryId) {
      this.topId = categoryId;
      history.pushState(
        {
          ...history.state,
          categoryMenuTopId: this.topId
        },
        "",
        location.hash
      );
    }
  }
}

/******************************/

const service = new CategoryMenuService();

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceCategoryMenu"] = service;
}

export default service;
