



























































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FormName from "@/components/form/Name.vue";
import FormContact from "@/components/form/Contact.vue";
import FormAddress from "@/components/form/Address.vue";

const EMPTY_ADDRESS = {
  id_cla: "",
  cla_nip: "",
  cla_address: "",
  cla_city: "",
  cla_company: "",
  cla_country: "",
  cla_flag_delivery: "",
  cla_flag_payment: "",
  cla_name: "",
  cla_number: "",
  cla_number2: "",
  cla_phone: "",
  cla_post_office: "",
  cla_postcode: "",
  cla_surname: "",
  id_cli: ""
};

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    FormName,
    FormContact,
    FormAddress
  },
  watch: {
    $route(this: Addresses) {
      this.edit();
    }
  }
})
export default class Addresses extends Vue {
  protected addressList = this.$service.customer.getAddresses();
  protected formPlaceholders = {
    ...this.$service.order.getPlaceholders(),
    cla_flag_delivery: window.t("Dla płatności"),
    cla_flag_payment: window.t("Dla dostawy")
  };
  protected formData: Exclude<Addresses["addressList"], null>[0] = {
    ...EMPTY_ADDRESS
  };
  protected status = "wait";

  edit() {
    if (this.$route.params.id === "add") {
      this.formData = { ...EMPTY_ADDRESS };
    } else {
      const id = this.$route.params.id;
      const address = this.$service.customer.getAddress(id);

      if (address) {
        this.$set(this, "formData", { ...this.formData, ...address });
      }
    }
  }

  getUrl() {
    return this.$route.path.slice(
      0,
      -(((this.$route.params.id && this.$route.params.id.length) || -1) + 1)
    );
  }

  async save() {
    this.status = "saving";

    const result = await this.$service.customer.saveAddresses(this.formData);

    this.status = "none";
    this.addressList = this.$service.customer.getAddresses();
    if (result.valid()) {
      this.$router.push(this.getUrl());
      this.$bvToast.toast(window.t("Zapisano"), {
        title: window.t("Moje adresy"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    } else {
      type Errors = { [key: string]: string[] };
      const errors = {} as Errors;
      result.getErrors().map((row: any) => {
        errors[row.key] = errors[row.key] || [];
        errors[row.key].push(row.message);
      });
      (this.$refs.form as {
        setErrors(errors: Errors): void;
      } & Vue).setErrors(errors);
      this.$bvToast.toast(window.t("Nie udało się zapisać zmian"), {
        title: window.t("Moje adresy"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "danger",
        headerClass: "d-none"
      });
      this.$nextTick(() => {
        const feedbackElement = document.querySelector(
          ".invalid-feedback.d-block"
        );

        if (feedbackElement) {
          const bBox = feedbackElement.getBoundingClientRect();
          //console.log(bBox);
          window.scrollTo(0, window.scrollY + bBox.top - 200);
        }
      });
    }
  }

  async del(id: string) {
    this.status = "wait";

    const result = await this.$service.customer.deleteAddresses(id);

    this.addressList = this.$service.customer.getAddresses();
    this.status = "none";

    if (result.valid()) {
      this.$bvToast.toast(window.t("Usunięto"), {
        title: window.t("Moje adresy"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    } else {
      this.$bvToast.toast(window.t("Usówanie nieudane"), {
        title: window.t("Moje adresy"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    }
  }

  async mounted() {
    await this.$service.customer.loadAddresses();
    this.addressList = this.$service.customer.getAddresses();

    if (!this.$route.params.id) {
      this.edit();
    }
  }

  getItemHeader(item: Exclude<Addresses["addressList"], null>[0]) {
    const t = window.t;

    switch (
      (parseInt(item.cla_flag_delivery) ? 1 : 0) |
      (parseInt(item.cla_flag_payment) ? 2 : 0)
    ) {
      case 0:
        return t("Adres");
      case 1:
        return t("Adres dostawy");
      case 2:
        return t("Adres płatności");
      case 3:
        return t("Adres dostawy i płatności");
    }
  }
  protected getCompanyDataStatus = "ok" as "ok" | "wait" | "error";

  async getCompanyData() {
    if (!this.formData.cla_nip) {
      (this.$refs.form as any).setErrors({
        cla_nip: window.t("Pole jest puste")
      });
      return;
    }

    this.getCompanyDataStatus = "wait";

    const nip = await this.$service.connection.nip(
      this.formData.cla_nip.replace(/[^\d]/g, "")
    );

    if (nip.valid()) {
      this.$nextTick(() => {
        //eslint-disable-next-line
        //debugger;
        for (const key in nip) {
          const fixKey = key.replace("cli_", "cla_");
          if (fixKey in this.formData) {
            this.$set(this.formData, fixKey, nip[key as keyof typeof nip]);
          }
        }
      });
      this.getCompanyDataStatus = "ok";
    } else {
      this.getCompanyDataStatus = "error";
    }
  }
}
