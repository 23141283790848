import Events from "@/libs/events";
import {
  facets,
  searchAndFacets,
  SearchAndFacetsResponse,
  category,
  CategoryResponse
} from "@/services/connection";
import serviceProductList from "@/services/productList";
import serviceCustomer from "@/services/customer";

/******************************/

function readTree(
  input: any[],
  output: any[] | { [key: string]: any },
  mode = false
) {
  for (const item of input) {
    if (Array.isArray(output)) {
      output.push(item);
      if (Array.isArray(item.childs)) {
        readTree(item.childs, output);
      }
    } else {
      if (mode) {
        output[item.id] = output[item.id] || [];
        if (Array.isArray(item.childs)) {
          for (const child of item.childs) {
            output[item.id].push(child);
          }
          readTree(item.childs, output, true);
        }
      } else {
        output[item.id] = item;
        if (Array.isArray(item.childs)) {
          readTree(item.childs, output);
        }
      }
    }
  }
}

/******************************/

type Facet = {
  id: string;
  attribute: string;
  label: string;
  value?: FacetItem[];
  range?: {
    from: number;
    to: number;
  };
};
type FacetItem = {
  id: number;
  name: string;
  children?: FacetItem[];
};

export class FacetBox {
  mapFilterIdIdItem = {} as { [key: string]: { [key: string]: FacetItem } };
  mapSearchKeyIdItem = {} as { [key: string]: { [key: string]: FacetItem } };
  mapIdFilter = {} as { [key: string]: Facet };
  mapCatParentIdList = {} as { [key: string]: FacetItem[] };

  /******************************/

  constructor(facets: SearchAndFacetsResponse["facets"]) {
    for (const facet of facets) {
      this.mapIdFilter[facet.id] = facet;
      this.mapFilterIdIdItem[facet.id] = this.mapSearchKeyIdItem[
        `${facet.id}[]`
      ] = {};

      if (Array.isArray(facet.value)) {
        readTree(facet.value, this.mapFilterIdIdItem[facet.id]);

        if (facet.id === "cat") {
          this.mapCatParentIdList[0] = [];
          for (const item of facet.value) {
            this.mapCatParentIdList[0].push(item);
          }
          readTree(facet.value, this.mapCatParentIdList, true);
        }
      }
    }
  }

  getCategoryListByParentId(parentId = 0) {
    if (this.mapCatParentIdList[parentId]) {
      return this.mapCatParentIdList[parentId];
    }
    return this.mapCatParentIdList[0];
  }
  getLabelByAttribute(attribute: string) {
    if (this.mapIdFilter[attribute]) {
      return this.mapIdFilter[attribute].label;
    }
    return null;
  }
  getFilterNameByAttributeAndId(attribute: string, id: string) {
    if (
      this.mapFilterIdIdItem[attribute] &&
      this.mapFilterIdIdItem[attribute][id]
    ) {
      return this.mapFilterIdIdItem[attribute][id].name;
    }
    return null;
  }
}

/******************************/

interface EventList {
  "changed-all": void;
  "changed-current": void;
  "changed-selectedFilters": void;
  "changed-visible": boolean;
}

/******************************/

class FacetsService {
  protected events = new Events<EventList>();
  public on!: Events<EventList>["on"];
  public off!: Events<EventList>["off"];
  public emit!: Events<EventList>["emit"];

  protected rawAll: SearchAndFacetsResponse["facets"] = [];
  protected rawCurrent: SearchAndFacetsResponse["facets"] = [];
  protected all: FacetBox = new FacetBox([]);
  protected current: FacetBox = new FacetBox([]);
  protected currentSelected = {} as { [key: string]: string[] };
  protected searchParams = new URLSearchParams();

  /******************************/

  constructor() {
    this.on = this.events.on.bind(this.events);
    this.off = this.events.off.bind(this.events);

    if (process.env.NODE_ENV !== "production") {
      this.emit = (type: keyof EventList, ...value: any[]) => {
        console.log("%cFacetsService", "color:orange;", type, value);
        return this.events.emit(type, value[0]);
      };
    } else {
      this.emit = this.events.emit.bind(this.events);
    }

    this.getAllFacets();
  }

  /******************************/

  isEmpty() {
    return !this.rawAll.length;
  }

  protected updateSearchParams() {
    const currentSearchParams = [];

    for (const key in this.currentSelected) {
      const value = this.currentSelected[key];
      currentSearchParams.push(`${key}=${value}`);
    }

    this.searchParams = new URLSearchParams(currentSearchParams.join("&"));
  }

  clean() {
    this.setSearchParams("");
  }

  setSearchParams(searchParams: string | URLSearchParams) {
    // eslint-disable-next-line
    // debugger;
    if (this.searchParams.toString() !== searchParams.toString()) {
      const newSearchParams = new URLSearchParams();
      const newCurrentSelected = {} as FacetsService["currentSelected"];
      const temp =
        searchParams instanceof URLSearchParams
          ? searchParams
          : new URLSearchParams(searchParams);

      temp.forEach((value, key) => {
        const attribute = key.replace("[]", "");

        if (
          this.current.mapIdFilter[attribute] &&
          this.current.mapIdFilter[attribute].range !== null
        ) {
          newSearchParams.append(key, value);
          newCurrentSelected[key] = newCurrentSelected[key] || [];
          newCurrentSelected[key].push(value);
        } else if (
          this.current.mapSearchKeyIdItem[key] &&
          this.current.mapSearchKeyIdItem[key][value]
        ) {
          newSearchParams.append(key, value);
          newCurrentSelected[key] = newCurrentSelected[key] || [];
          newCurrentSelected[key].push(value);
        }
      });

      this.searchParams = newSearchParams;
      this.currentSelected = newCurrentSelected;
      this.emit("changed-selectedFilters");
    }
  }

  /******************************/

  getCategoryLayer(inputParentId: number) {
    const parentId = inputParentId || 0;
  }

  getSearchParams(): URLSearchParams;
  getSearchParams(format: "string"): string;
  getSearchParams(format?: "string") {
    if (format) {
      return this.searchParams.toString();
    }
    return this.searchParams;
  }

  getValues = (attribute: string) => {
    const searchKey = `${attribute}[]`;

    return this.searchParams
      .getAll(searchKey)
      .map(v => (isNaN(parseInt(v)) ? v : parseInt(v)));
  };

  getFilterName(attribute: string, id: string) {
    return (
      this.all.getFilterNameByAttributeAndId(attribute, id) ||
      this.current.getFilterNameByAttributeAndId(attribute, id) ||
      id
    );
  }

  getFilterLabel(attribute: string) {
    return (
      this.all.getLabelByAttribute(attribute) ||
      this.current.getLabelByAttribute(attribute)
    );
  }

  setFilter = (attribute: string, id: string) => {
    const searchKey = `${attribute}[]`;

    this.currentSelected[searchKey] = this.currentSelected[searchKey] || [];

    const index = this.searchParams.getAll(searchKey).indexOf(id);

    if (index === -1) {
      this.searchParams.append(searchKey, id);
      this.currentSelected[searchKey].push(id);

      this.emit("changed-selectedFilters");
    }
  };

  setFilterValues = (attribute: string, idList: string[]) => {
    const searchKey = `${attribute}[]`;

    this.currentSelected[searchKey] = [];
    this.searchParams.delete(searchKey);

    for (const id of idList) {
      this.searchParams.append(searchKey, id);
      this.currentSelected[searchKey].push(id);
    }

    this.emit("changed-selectedFilters");
  };

  delFilter = (attribute: string, id: string) => {
    const searchKey = `${attribute}[]`;
    let flageChanged = false;

    if (id === "all" && this.currentSelected[searchKey]) {
      delete this.currentSelected[searchKey];
      flageChanged = true;
    }

    if (this.currentSelected[searchKey]) {
      const index = this.currentSelected[searchKey].indexOf(id);

      if (index !== -1) {
        this.currentSelected[searchKey].splice(index, 1);
        flageChanged = true;
      }
    }

    if (flageChanged) {
      const newSearchParams = new URLSearchParams();
      const interator = this.searchParams.entries();

      for (const [key, value] of interator) {
        if (searchKey !== key || (id !== value && id !== "all")) {
          newSearchParams.append(key, value);
        }
      }

      this.searchParams = newSearchParams;
      this.emit("changed-selectedFilters");
    }
  };

  /******************************/

  async getAllFacets() {
    try {
      let parameters = "";

      if (serviceCustomer.isLogged()) {
        parameters += ("customer_token=" +
          serviceCustomer.getCustomerToken()) as string;
      } else {
        parameters += ("guest_token=" +
          serviceCustomer.getGuestToken()) as string;
      }

      const result = await facets(new URLSearchParams(parameters));

      if (result.valid()) {
        this.rawAll = result.facets;
        this.all = new FacetBox(result.facets);
        this.emit("changed-all");
        this.setCurrentFacets(result.facets);
      }
    } catch (error) {
      null;
    }
  }

  getRawAll() {
    return this.rawAll;
  }
  getRawCurrent() {
    return this.rawCurrent;
  }

  setCurrentFacets(facets: SearchAndFacetsResponse["facets"]) {
    this.rawCurrent = facets;
    this.current = new FacetBox(facets);
    this.emit("changed-current");
  }

  getSelected() {
    const result = {} as { [key: string]: string[] };
    for (const key in this.currentSelected) {
      const attribute = key.replace("[]", "");
      result[attribute] = [...this.currentSelected[key]];
    }
    return result;
  }
}

/******************************/

const serviceFacets = new FacetsService();

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceFacets"] = serviceFacets;
}

export default serviceFacets;
