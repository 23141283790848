import { Component, Prop, Vue } from "vue-property-decorator";
import { CategoryResponse } from "@/services/connection";

type DataItem = CategoryResponse["categories"][0];

@Component
class DDItem extends Vue {
  @Prop() private item!: DataItem & { children: DataItem[] };

  get text() {
    return this.item.name;
  }
  get route() {
    return this.item.route.replace(/^product\//, "products/");
  }

  open = false;
  direction = "left" as "left" | "right";
  clickToggle() {
    this.open = !this.open;
  }
  setOpen() {
    this.open = true;
  }
  setHide() {
    this.open = false;
  }

  render() {
    const children = this.item.children;

    this.$nextTick(() => {
      const element = this.$refs.root as HTMLElement;
      const bodyBBox = document.body.getBoundingClientRect();
      const bBox = element.getBoundingClientRect();

      if (bodyBBox.width / 2 > bBox.x + bBox.width) {
        this.direction = "left";
      } else {
        this.direction = "right";
      }
    });

    return children.length ? (
      <li
        role="presentation"
        class={{ show: this.open, dropdown: true }}
        ref="root"
        onMouseover={this.setOpen}
        onMouseleave={this.setHide}
      >
        <a href={`#/${this.route}`}>
          <span>{this.text}</span>
        </a>
        <button
          class="btn btn-link dropdown-toggle"
          onClick={this.clickToggle}
        ></button>
        <ul class={["dropdown-menu", this.direction]}>
          {children.map(child => (
            <c-nav-dd-menu item={child} key={child.id} />
          ))}
        </ul>
      </li>
    ) : (
      <li role="presentation" ref="root">
        <a href={`#/${this.route}`}>
          <span>{this.text}</span>
        </a>
      </li>
    );
  }
}

Vue.component("c-nav-dd-menu", DDItem);
