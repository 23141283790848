import * as uuid from "uuid";

/******************************/

let deviceUuid = (localStorage.getItem("deviceUuid") || null) as string | null;

if (deviceUuid === null) {
  deviceUuid = uuid.v4();
  localStorage.setItem("deviceUuid", deviceUuid);
}

/******************************/

const serviceDevice = {
  getDeviceUuid() {
    return deviceUuid as string;
  }
};

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceDevice"] = serviceDevice;
}

export default serviceDevice;
