import { jsonApiFetch } from "./endpoint";

type Response = {
  addresses: {
    cla_address: string;
    cla_city: string;
    cla_company: string;
    cla_country: string;
    cla_flag_delivery: string; //number
    cla_flag_payment: string; //number
    cla_name: string;
    cla_nip: string;
    cla_number: string;
    cla_number2: string;
    cla_phone: string;
    cla_post_office: string;
    cla_postcode: string;
    cla_surname: string;
    id_cla: string; //number
    id_cli: string; //number
  }[];
};

type A = { guest_token: string };
type B = { customer_token: string };
type Request = (A | B) & {
  address?: { [key: string]: string | number };
  delete?: string;
};

function action(inputData: Request) {
  return jsonApiFetch<Response>(
    "/address",
    JSON.stringify({
      ...inputData
    })
  );
}

export { action as address, Response as AddressResponse };
