import { jsonApiFetch } from "./endpoint";
import { OrderItem } from "./order";

type Response = {
  order: OrderItem;
  delivery: {
    id: number;
    action: string;
    name: string;
    payment: string[];
    price: number;
    price_formatted: string;
    show_address: number;
  }[];
  payment: {
    id: number;
    name: string;
    price: number;
    price_formatted: string;
  }[];
};

type A = { guest_token: string };
type B = { customer_token: string };
type Request = (A | B) & { delivery_id?: number | null };

function action(inputData: Request) {
  return jsonApiFetch<Response>(
    "/checkout",
    JSON.stringify({
      ...inputData
    })
  );
}

export { action as checkout, Response as CheckoutResponse };
