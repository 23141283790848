











































































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/views/layout/header";
import Modal from "@/views/layout/Modal";
import serviceLayout, { CategoryItem, MenuItem } from "@/services/layout";
import serviceProductList from "@/services/productList";
import serviceCategoryMenu from "@/services/categoryMenu";
import { ContentResponse } from "@/services/connection";

@Component
export default class DrawerCategories extends Vue {
  visible = serviceCategoryMenu.isVisible();
  stack = serviceCategoryMenu.getStack();
  root = serviceCategoryMenu.getRoot();
  searchPhase = "";

  beforeMount() {
    if (process.env.NODE_ENV !== "production") {
      Object.assign(window, { $pageDrawerCategories: this });
    }

    serviceCategoryMenu.on("changed-visible", this.onVisibleChange);
    serviceCategoryMenu.on("changed-stack", this.onStackChange);
    serviceCategoryMenu.on("changed-categories", this.onCategoriesChange);
  }
  beforeDestroy() {
    serviceCategoryMenu.off("changed-visible", this.onVisibleChange);
    serviceCategoryMenu.off("changed-stack", this.onStackChange);
    serviceCategoryMenu.off("changed-categories", this.onCategoriesChange);
    document.body.removeAttribute("style");
  }

  onVisibleChange(value: boolean) {
    this.visible = serviceCategoryMenu.isVisible();

    if (this.visible) {
      Object.assign(document.body.style, { overflow: "hidden" });
    } else {
      document.body.removeAttribute("style");
    }
  }
  onStackChange(stack: DrawerCategories["stack"]) {
    this.stack = serviceCategoryMenu.getStack();
  }
  onCategoriesChange(root: DrawerCategories["root"]) {
    this.root = serviceCategoryMenu.getRoot();
  }

  onMenuCategoriesSearch(phase: string) {
    this.searchPhase = phase;
  }

  // po zamknięciu category menu
  handleCategoryMenuClose() {
    serviceCategoryMenu.pop();
  }

  // wybranie elementu na liście kategorii
  handleCategoryMenuSelect(item: MenuItem, forceSelect = false) {
    // jesli element ma dzieci
    if (
      item.items &&
      (item.level || 0) < 2 &&
      item.items.length &&
      forceSelect === false
    ) {
      serviceCategoryMenu.push(item.id);
    } else if (item.value !== null) {
      // serviceProductList.setCid(parseInt(item.value));
      // const gotTo = `/products${serviceProductList.getUrlParams()}`;
      // if (this.$route.path === gotTo) {
      //   serviceCategoryMenu.emit("changed-visible", false);
      // } else {
      //   this.$router.push({ path: gotTo });
      // }
    }
  }
}
