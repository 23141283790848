import { Component, Vue } from "vue-property-decorator";
import serviceLayout, {
  ModalSlotSope,
  ModalRenderSlot
} from "@/services/layout";

@Component
export default class Modal extends Vue {
  private modalList = serviceLayout.getModalList();

  modalHide(uuid: string) {
    serviceLayout.remove(uuid);
  }

  render() {
    return (
      <div>
        {this.modalList.map(modal => (
          <b-modal
            key={modal.uuid}
            id={modal.uuid}
            visible={true}
            title={modal.header}
            header-bg-variant={modal.headerStyle}
            body-bg-variant={modal.bodyStyle}
            footer-bg-variant={modal.footerStyle}
            ok-only={modal.cancel === false}
            cancel-title={modal.cancel ? modal.cancel : undefined}
            {...{
              scopedSlots: {
                default: modal.bodySlot
                  ? (scope: ModalSlotSope) =>
                      (modal.bodySlot as ModalRenderSlot)(
                        this.$createElement,
                        scope
                      )
                  : undefined,
                "modal-header": modal.headerSlot
                  ? (scope: ModalSlotSope) =>
                      (modal.headerSlot as ModalRenderSlot)(
                        this.$createElement,
                        scope
                      )
                  : undefined,
                "modal-footer": modal.footerSlot
                  ? (scope: ModalSlotSope) =>
                      (modal.footerSlot as ModalRenderSlot)(
                        this.$createElement,
                        scope
                      )
                  : undefined
              },
              ...(() => {
                // console.log({
                //   "cancel-disabled": modal.cancel === false,
                //   "cancel-title": modal.cancel ? modal.cancel : undefined
                // });
                return {};
              })()
            }}
          >
            <p class="my-1">{modal.body}</p>
          </b-modal>
        ))}
      </div>
    );
  }
}
