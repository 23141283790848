import { jsonApiFetch } from "./endpoint";
import { LoginResponse } from ".";

type Request =
  | {
      customer_token: string;
    }
  | {
      long_life_token: string;
    };

function action(inputData: Request) {
  return jsonApiFetch<LoginResponse>(
    "/customerReclaim",
    JSON.stringify(inputData)
  );
}

export { action as customerReclaim };
