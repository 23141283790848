





































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import serviceCart from "@/services/cart";
import serviceCustomer from "@/services/customer";
import serviceLayout from "@/services/layout";
import serviceOrder from "@/services/order";
import * as serviceConnection from "@/services/connection";
import { CartResponse } from "@/services/connection";
import ListItem from "@/views/cart/ListItem.vue";
import * as uuid from "uuid";

@Component({
  components: {
    ListItem
  }
})
export default class Current extends Vue {
  protected uuid = uuid.v4();
  protected cart = serviceCart.getCartData();
  protected lock = serviceCart.isLock();
  protected coupon = "";

  /******************************/

  mounted() {
    serviceLayout.setBottomBar(this.uuid, this.renderBottomBar());
    serviceCart.on("changed-cart", this.getCart);
    serviceCart.on("changed-lock", this.updateLock);
    serviceCart.on("code-message", this.couponMessage);
    this.getCart();
  }
  beforeDestroy() {
    serviceCart.off("changed-cart", this.getCart);
    serviceCart.off("changed-lock", this.updateLock);
    serviceCart.off("code-message", this.couponMessage);
    serviceLayout.removeBottomBar(this.uuid);
  }

  /******************************/

  getCart() {
    this.cart = serviceCart.getCartData();
    serviceLayout.setBottomBar(this.uuid, this.renderBottomBar());
  }

  updateLock() {
    this.lock = serviceCart.isLock();
  }

  renderBottomBar(): JSX.Element {
    return this.cart.items && this.cart.items.length ? (
      <div class="btn-cart-bottom-bar">
        <div class="container d-flex justify-content-between">
          <button
            class="btn btn-primary btn-block btn-lg"
            onClick={this.createOrder}
            disabled={this.$service.cart.isLock()}
          >
            {window.t("Wybierz dostawę i płatność")}
          </button>
        </div>
      </div>
    ) : (
      <span></span>
    );
  }

  async createOrder() {
    if (this.cart !== null && this.cart.items_count) {
      await serviceOrder.createOrder();

      if (serviceCustomer.isLogged()) {
        this.$router.push("/order");
      } else {
        serviceCustomer.redirectAfterLogin = "/order";
        this.$router.push("/account");
      }
    }
  }

  couponMessage(message: string) {
    this.$bvToast.toast(message, {
      title: window.t("Kupon"),
      toaster: "toast-bottom-bar-slot",
      solid: true,
      appendToast: true,
      variant: "primary",
      headerClass: "d-none"
    });
  }

  async applyDiscountCode() {
    const result = await this.$service.cart.applyDiscountCode(this.coupon);

    if (!result) {
      this.coupon = "";
    }
  }
}
