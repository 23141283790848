
























































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import serviceLayout from "@/services/layout";
import serviceCategoryMenu from "@/services/categoryMenu";
import serviceCustomer from "@/services/customer";
import serviceCart from "@/services/cart";
import BottomBarAdditional from "./BottomBarAdditional";

@Component({
  components: {
    BottomBarAdditional
  }
})
export default class BottomBar extends Vue {
  @Prop({ default: false }) withToast!: boolean;
  protected visible = true;
  protected additionalVisible = true;
  protected cart = serviceCart.getCartData();

  mounted() {
    serviceLayout.on("changed-menu-main-visible", this.visibleChange);
    serviceCategoryMenu.on("changed-visible", this.visibleChange);
    serviceLayout.on("changed-bottom-bar-visible", this.visibleChange);
    serviceCart.on("changed-cart", this.updateCart);
    this.updateCart();
  }
  beforeDestroy() {
    serviceLayout.off("changed-menu-main-visible", this.visibleChange);
    serviceCategoryMenu.off("changed-visible", this.visibleChange);
    serviceLayout.off("changed-bottom-bar-visible", this.visibleChange);
    serviceCart.off("changed-cart", this.updateCart);
  }
  visibleChange(value: boolean) {
    this.visible = serviceLayout.isBottomMenuVisible();
    this.additionalVisible = !(
      serviceLayout.isMenuVisible() || serviceCategoryMenu.isVisible()
    );
  }
  updateCart() {
    this.cart = serviceCart.getCartData();
  }

  handlerOpenMenu() {
    serviceLayout.setMenuVisible(!serviceLayout.isMenuVisible());
  }
  handlerOpenCategoryMenu() {
    if (serviceCategoryMenu.isVisible()) {
      serviceCategoryMenu.hide();
    } else {
      serviceCategoryMenu.openWith(0);
    }
  }
}
