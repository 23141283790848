







































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import * as serviceConnection from "@/services/connection";
import serviceCustomer from "@/services/customer";
import serviceCart from "@/services/cart";
import serviceLayout from "@/services/layout";
import Back from "@/components/RouteTopBack.vue";
import ProductsSwiper from "@/components/SwiperProducts.vue";
import Tile from "@/components/Tile.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import * as uuid from "uuid";
import serviceProductList from "../services/productList";

function HTML2DocumentFragment(markup: string) {
  if (
    markup
      .toLowerCase()
      .trim()
      .indexOf("<!doctype") === 0
  ) {
    const doc = document.implementation.createHTMLDocument("");
    doc.documentElement.innerHTML = markup;
    return doc;
  } else if ("content" in document.createElement("template")) {
    // Template tag exists!
    const el = document.createElement("template");
    el.innerHTML = markup;
    return el.content;
  } else {
    // Template tag doesn't exist!
    const docfrag = document.createDocumentFragment();
    const el = document.createElement("body");
    el.innerHTML = markup;
    for (let i = 0; 0 < el.childNodes.length; ) {
      docfrag.appendChild(el.childNodes[i]);
    }
    return docfrag;
  }
}

@Component({
  components: {
    Back,
    Swiper,
    SwiperSlide,
    ProductsSwiper,
    Tile
  }
})
export default class Product extends Vue {
  protected uuid = uuid.v4();
  protected product = null as serviceConnection.Product | null;
  protected category = null as serviceConnection.Category | null;
  protected inWishList = false;
  protected toCartQty = 1;

  /******************************/

  beforeMount() {
    this.$watch("$route", this.loadPage);
    this.loadPage();
    this.updateInWishList();
    serviceCart.on("changed-wishlist", this.updateInWishList);
    this.$service.customer.on("after-login", this.loadPage);
    this.$service.productList.on("loaded-categories", this.updateCategory);
  }

  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.onScroll();
  }

  beforeDestroy() {
    serviceCart.off("changed-wishlist", this.updateInWishList);
    this.$service.customer.off("after-login", this.loadPage);
    this.$service.productList.off("loaded-categories", this.updateCategory);
    window.removeEventListener("scroll", this.onScroll);
  }

  updateCategory() {
    if (this.product && this.product.category) {
      this.category = this.$service.productList.getCategoryById(
        this.product.category.id
      );
    }
  }

  onScroll() {
    if (!this.$refs.price) {
      serviceLayout.removeBottomBar(this.uuid);
      return;
    }

    const priceElement = this.$refs.price as HTMLElement;
    const bBox = priceElement.getBoundingClientRect();

    if (bBox.top < 0) {
      this.product &&
        serviceLayout.setBottomBar(this.uuid, this.renderBottomBar());
    } else {
      serviceLayout.removeBottomBar(this.uuid);
    }
  }

  updateInWishList() {
    if (this.product) {
      this.inWishList = serviceCart.isProductInWishList(this.product.id);
    }
  }

  renderBottomBar(): JSX.Element {
    const p = this.product as serviceConnection.Product;

    return [8].indexOf(p.availability_raw) === -1 ? (
      <div class="bg-light product-additional-bar">
        <div class="container d-flex justify-content-between">
          <div class="price d-flex align-items-center">
            <span class="price-netto">{p.price_netto_formatted}</span>
            <span class="separator">/</span>
            <span class="price-brutto">{p.price_brutto_formatted}</span>
          </div>
          <div class="d-flex align-items-stretch">
            <b-form-input
              v-model={this.toCartQty}
              type="number"
              step={p.pack_items_count || 1}
            ></b-form-input>
            <button class="btn btn-primary" onClick={this.addToCart}>
              <figure
                domPropsInnerHTML={require("!html-loader!@/assets/iconCart.svg")}
              ></figure>
            </button>
          </div>
        </div>
      </div>
    ) : (
      <span></span>
    );
  }

  goto(refName: string) {
    const element = this.$refs[refName] as Element & { offsetTop: number };
    const top = element.offsetTop;

    window.scrollTo(0, top);
  }

  /******************************/

  async loadPage() {
    this.product = null;
    const productId = parseInt(this.$route.params.productId);

    if (productId) {
      const result = await serviceConnection.product({
        productId,
        ...(this.$service.customer.isLogged()
          ? {
              customer_token: this.$service.customer.getCustomerToken() as string
            }
          : { guest_token: this.$service.customer.getGuestToken() as string })
      });

      if (result) {
        this.product = result.product;
        if (this.product.category) {
          this.category = serviceProductList.getCategoryById(
            result.product.category.id
          );
        }
        if (this.product) {
          this.onScroll();
          this.updateInWishList();
        }

        const result2 = await serviceConnection.product({
          productId,
          related: 1,
          ...(this.$service.customer.isLogged()
            ? {
                customer_token: this.$service.customer.getCustomerToken() as string
              }
            : { guest_token: this.$service.customer.getGuestToken() as string })
        });

        if (result2) {
          this.product = result2.product;
          this.toCartQty = this.product.pack_items_count || 1;
        }
      }
    } else {
      this.$router.push("/404");
    }
  }

  async addToCart() {
    if (this.product) {
      const result = await serviceCart.addToCart({
        product_id: this.product.id,
        quantity: this.toCartQty
      });

      if (result !== null) {
        this.$bvToast.toast(window.t("Dodano do koszyka"), {
          title: window.t("Koszyk"),
          toaster: "toast-bottom-bar-slot",
          solid: true,
          appendToast: true,
          variant: "primary",
          headerClass: "d-none"
        });
      }
    }
  }

  getDescription() {
    if (this.product) {
      const content = HTML2DocumentFragment(
        this.product.description
      ).querySelector("#content-container p");

      if (content) {
        return content.innerHTML;
      }
    }
    return "";
  }

  async handlerAddToWishList(productId: number) {
    const result = await serviceCart.addToWishList({
      product_id: productId
    });

    if (result !== null) {
      this.$bvToast.toast(window.t("Dodano do przechowalni"), {
        title: window.t("Przechowalnia"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    }
  }

  async handlerRemoveFromWishList(productId: number) {
    const result = await serviceCart.delFromWishList(productId);

    if (result !== null) {
      this.$bvToast.toast(window.t("Usunięto z przechowalni"), {
        title: window.t("Przechowalnia"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    }
  }

  handlerBack() {
    if (this.category && this.product) {
      this.$router.push(`/products/cid:${this.product.category.id}`);
    } else {
      this.$router.back();
    }
  }
}
