




















































































import { Component, Vue } from "vue-property-decorator";
import { CategoryResponse } from "@/services/connection";
import serviceProductList from "@/services/productList";
import serviceLayout from "@/services/layout";
import UserNav from "./UserNav.vue";

@Component({
  components: { UserNav }
})
export default class App extends Vue {
  private categories = serviceProductList.getCategories();

  showMenu() {
    serviceLayout.emit("changed-menu-main-visible", true);
  }

  beforeMount() {
    serviceProductList.on("loaded-categories", this.onCategoriesChange);
  }
  beforeDestroy() {
    serviceProductList.off("loaded-categories", this.onCategoriesChange);
  }

  onCategoriesChange(categories: CategoryResponse["categories"] | null) {
    this.categories = categories;
  }
}
