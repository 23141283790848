



















































import { Component, Prop, Vue } from "vue-property-decorator";
import RouteTopNav from "@/components/RouteTopNav.vue";
import serviceCustomer from "@/services/customer";

@Component({
  components: {
    RouteTopNav
  }
})
export default class Layout extends Vue {
  routeMapGuest = {
    "/account/login": window.t("Logowanie"),
    "/account/registration": window.t("Rejestracja")
  };
  routeMapUser = {
    "/account/data": window.t("Konto"),
    "/account/logout": window.t("Wyloguj")
  };

  isLogged() {
    return serviceCustomer.isLogged();
  }
}
