import Events from "@/libs/events";
import Categories from "@/models/categories";
import serviceFacets from "@/services/facets";
import serviceLayout from "@/services/layout";
import serviceCustomer from "@/services/customer";
import {
  search,
  facets,
  facetsFilter,
  searchAndFacets,
  SearchAndFacetsResponse,
  category,
  CategoryResponse,
  Brand
} from "@/services/connection";

/******************************/

const LIST_SIZES = [20, 60, 100, 200];
const INIT_PAGE = 1;
const INIT_ITEMS_PER_PAGE = LIST_SIZES[0];

interface EventList {
  "changed-page": number;
  "changed-sort": number;
  "changed-itemsPerPage": number;
  "changed-total": number;
  "changed-pagination": void;
  "changed-cid": number | null;
  "changed-bid": boolean | null;
  "changed-query": string | null;
  "changed-urlParams": string;
  "changed-searchParams": URLSearchParams;
  "loaded-categories": CategoryResponse["categories"] | null;
  "changed-category": CategoryResponse["categories"][0] | null;
  "changed-brand": Brand | null;
  "changed-productList": SearchAndFacetsResponse["products"];
  "loading-products": void;
  "loaded-products": void;
}

/******************************/

class ProductListService {
  public state = {
    cid: null as number | null,
    bid: null as number | null,
    query: null as string | null,
    page: INIT_PAGE as number,
    sort: 0 as number,
    totalItems: 0 as number,
    itemsPerPage: 0 as number
  };
  protected events = new Events<EventList>();
  protected page = INIT_PAGE;
  protected sort = 0;
  protected totalItems = 0;
  protected itemsPerPage = INIT_ITEMS_PER_PAGE;
  protected cid = null as number | null;
  protected bid = null as number | null;
  protected query = null as string | null;
  protected urlParams = "";
  protected categoryBox = null as Categories | null;
  protected categories = [] as CategoryResponse["categories"] | null;
  protected category = null as CategoryResponse["categories"][0] | null;
  protected brand = null as Brand | null;
  protected searchParamsString = "";
  protected searchParams!: URLSearchParams;
  protected productList: SearchAndFacetsResponse["products"] = [];
  public on!: Events<EventList>["on"];
  public off!: Events<EventList>["off"];
  public emit!: Events<EventList>["emit"];

  /******************************/

  constructor() {
    this.on = this.events.on.bind(this.events);
    this.off = this.events.off.bind(this.events);

    if (process.env.NODE_ENV !== "production") {
      this.emit = (type: keyof EventList, ...value: any[]) => {
        console.log("%cProductListService", "color:orange;", type, value);
        return this.events.emit(type, value[0]);
      };
    } else {
      this.emit = this.events.emit.bind(this.events);
    }

    this.events.on("changed-page", this.updateUrlParams);
    this.events.on("changed-itemsPerPage", this.updateUrlParams);
    this.events.on("changed-cid", this.updateUrlParams);
    this.events.on("changed-cid", this.updateCategory);
    this.events.on("changed-cid", this.updateSearchParams);
    this.events.on("changed-bid", this.updateUrlParams);
    this.events.on("changed-bid", this.updateSearchParams);
    this.events.on("changed-bid", this.updateBrand);
    this.events.on("changed-query", this.updateUrlParams);
    this.events.on("changed-sort", this.updateUrlParams);
    this.events.on("changed-urlParams", this.updateSearchParams);
    this.events.on("changed-searchParams", this.requestUpdateList);
    this.events.on("loaded-categories", this.updateCategory);

    setTimeout(() => {
      (async () => {
        const result = await category();

        this.categoryBox = new Categories(result.categories);
        this.categories = this.categoryBox.getMain();
        this.events.emit("loaded-categories", this.categories);
      })();

      serviceFacets.on("changed-selectedFilters", () => {
        this.updateSearchParams();
        this.updateUrlParams();
        this.updateList();
      });

      serviceLayout.on("changed-brands", () => {
        this.updateBrand(true);
      });
    });
  }

  /******************************/

  getBrand() {
    return this.brand;
  }

  getSizes() {
    return LIST_SIZES.slice();
  }
  getUrlParams() {
    return this.urlParams;
  }

  getPage() {
    return this.page;
  }
  setPage = (value: number) => {
    if (this.page !== value) {
      this.page = value;
      this.state.page = value;
      this.events.emit("changed-page", this.page);
      this.events.emit("changed-pagination");
    }
  };

  getTotal() {
    return this.totalItems;
  }
  setTotal = (value: number) => {
    if (this.totalItems !== value) {
      this.totalItems = value;
      this.state.totalItems = value;
      this.events.emit("changed-total", this.totalItems);
      this.events.emit("changed-pagination");
    }
  };

  getItemsPerPage() {
    return this.itemsPerPage;
  }
  setItemsPerPage = (value: number) => {
    const newValue = LIST_SIZES.indexOf(value) !== -1 ? value : LIST_SIZES[0];

    if (this.itemsPerPage !== newValue) {
      this.itemsPerPage = newValue;
      this.state.itemsPerPage = newValue;

      this.events.emit("changed-itemsPerPage", this.itemsPerPage);
      this.events.emit("changed-pagination");
    }
  };

  getCid() {
    return this.cid;
  }
  setCid = (value: number | null) => {
    const safe = parseInt(value as any);

    if (value === null) {
      this.cid = null;
      this.state.cid = null;
      this.events.emit("changed-cid", null);
    } else if (this.cid !== safe && !isNaN(safe)) {
      if (this.getBid()) {
        this.bid = null;
        this.state.bid = null;
        this.events.emit("changed-bid", false);
      }

      this.cid = safe;
      this.state.cid = safe;
      this.events.emit("changed-cid", this.cid);
    }
  };

  getBid() {
    return this.bid;
  }
  setBid = (value: number | null) => {
    const safe = parseInt(value as any);

    if (value === null) {
      this.bid = null;
      this.state.bid = null;
      this.events.emit("changed-bid", false);
    } else if (this.bid !== safe && !isNaN(safe)) {
      if (this.getCid()) {
        this.cid = null;
        this.state.cid = null;
        this.events.emit("changed-cid", null);
      }

      this.bid = safe;
      this.state.bid = safe;
      this.events.emit("changed-bid", false);
    }
  };

  getCategories() {
    return this.categories;
  }
  getCategoryList(id = 0) {
    return this.categoryBox !== null
      ? this.categoryBox.getChildListById(id)
      : [];
  }
  getCategoryById(id: number) {
    return this.categoryBox !== null ? this.categoryBox.getById(id) : null;
  }
  getCategory() {
    const cid = this.getCid();
    if (!this.category && cid) {
      this.category = this.getCategoryById(cid);
    }
    return this.category;
  }
  getCategoryRootList() {
    return this.categoryBox?.getMain() || null;
  }

  getProductList() {
    return this.productList.slice();
  }
  setProductList = (newList: SearchAndFacetsResponse["products"]) => {
    this.productList = newList;
    this.events.emit("changed-productList", this.productList);
  };

  readUrlParams(url: string) {
    const paramsMap = {} as { [key: string]: string };

    if (url) {
      const urlParams = url.split(",");

      for (const param of urlParams) {
        const [key, value] = param.split(":");

        if (key && value) {
          paramsMap[key] = value;
        }
      }
    }

    if ("page" in paramsMap) {
      this.setPage(parseInt(paramsMap["page"]));
    }
    if ("limit" in paramsMap) {
      this.setItemsPerPage(parseInt(paramsMap["limit"]));
    }
    if ("sort" in paramsMap) {
      this.setSort(parseInt(paramsMap["sort"]));
    }
    if ("cid" in paramsMap) {
      this.setCid(parseInt(paramsMap["cid"]));
    } else {
      this.setCid(null);
      if ("bid" in paramsMap) {
        this.setBid(parseInt(paramsMap["bid"]));
      } else {
        this.setBid(null);
      }
    }
    if ("q" in paramsMap) {
      this.setQuery(atob(paramsMap["q"].replace(/-/g, "+").replace(/_/g, "/")));
    } else {
      this.setQuery("");
    }
    if ("filters" in paramsMap) {
      if (serviceFacets.isEmpty()) {
        serviceFacets.on("changed-all", () => {
          serviceFacets.setSearchParams(paramsMap["filters"]);
        });
      } else {
        serviceFacets.setSearchParams(paramsMap["filters"]);
      }
    } else {
      serviceFacets.setSearchParams("");
    }
  }

  setQuery(query: string) {
    if (query.length > 2) {
      if (query !== this.query) {
        this.query = query;
        this.state.query = query;
        this.emit("changed-query", this.query);
      }
    } else {
      if (this.query !== null) {
        this.query = null;
        this.state.query = null;
        this.emit("changed-query", this.query);
      }
    }
  }
  getQuery() {
    return this.query;
  }
  setSort(value: number) {
    if (this.sort !== value) {
      this.sort = value;
      this.state.sort = value;
      this.emit("changed-sort", value);
    }
  }
  getSort() {
    return this.sort;
  }
  getSortLabel(key = this.getSort()) {
    switch (key) {
      case 0:
        return window.t("sortowanie_domyslne");
      case 1:
        return window.t("sortowanie_nazwa_rosnaco");
      case 2:
        return window.t("sortowanie_nazwa_malejaco");
      case 3:
        return window.t("sortowanie_cena_rosnaco");
      case 4:
        return window.t("sortowanie_cena_malejaco");
      case 5:
        return window.t("sortowanie_symbol_rosnaco");
      case 6:
        return window.t("sortowanie_symbol_malejaco");
      default:
        return window.t("sortowanie_domyslne");
    }
  }

  /******************************/

  protected updateUrlParams = () => {
    const params = [];

    if (this.cid !== null) {
      params.push(`cid:${this.cid}`);
    }
    if (this.bid !== null) {
      params.push(`bid:${this.bid}`);
    }
    if (this.query !== null) {
      params.push(
        `q:${btoa(this.query.replace(/\+/g, "-").replace(/\//g, "_"))}`
      );
    }
    if (this.sort !== 0) {
      params.push(`sort:${this.sort}`);
    }
    if (this.page !== 1) {
      params.push(`page:${this.page}`);
    }
    if (this.itemsPerPage !== 20) {
      params.push(`limit:${this.itemsPerPage}`);
    }
    const filters = serviceFacets.getSearchParams("string");
    if (filters) {
      params.push(`filters:${decodeURI(filters)}`);
    }

    let newUrlParams: string;
    if (params.length) {
      newUrlParams = `/${params.join()}`;
    } else {
      newUrlParams = "";
    }

    if (newUrlParams !== this.urlParams) {
      this.urlParams = newUrlParams;
      this.events.emit("changed-urlParams", this.urlParams);
    }
  };
  protected updateSearchParams = () => {
    const newParams = new URLSearchParams();

    newParams.set("p", this.page.toString());
    newParams.set("sort", this.sort.toString());
    newParams.set("limit", this.itemsPerPage.toString());

    if (this.cid !== null) {
      newParams.set("cid", this.cid.toString());
    }
    if (this.bid !== null) {
      newParams.set("bid", this.bid.toString());
    }
    if (this.query !== null) {
      newParams.set("q", this.query);
    }

    const stringValue = newParams.toString();

    if (this.searchParamsString !== stringValue) {
      this.searchParams = newParams;
      this.searchParamsString = stringValue;
      this.events.emit("changed-searchParams", this.searchParams);
    }
  };
  protected updateCategory = () => {
    if (this.categoryBox !== null) {
      let newCategory = null;
      if (this.cid !== null) {
        newCategory = this.categoryBox.getById(this.cid);
      }

      if (newCategory !== this.category) {
        this.category = newCategory;
        this.events.emit("changed-category", this.category);
      }
    }
  };

  protected updateBrand = (force: boolean | null = false) => {
    const bid = this.getBid();
    let newBrand = null;

    if (bid !== null) {
      newBrand = serviceLayout.getBrand(bid);
    }

    if (newBrand !== this.brand || force) {
      this.brand = newBrand;
      this.events.emit("changed-brand", this.brand);
    }
  };

  protected updateListHasBeenRequested = false;
  requestUpdateList = () => {
    if (this.updateListHasBeenRequested === false) {
      this.updateListHasBeenRequested = true;
      setTimeout(this.updateList);
    }
  };
  protected updateList = () => {
    this.updateListHasBeenRequested = false;
    // eslint-disable-next-line
    // debugger;
    this.events.emit("loading-products");

    let parameters = serviceFacets.getSearchParams("string");

    if (this.searchParamsString) {
      parameters += "&" + this.searchParamsString;
    }

    if (serviceCustomer.isLogged()) {
      parameters += ("&customer_token=" +
        serviceCustomer.getCustomerToken()) as string;
    } else {
      parameters += ("&guest_token=" +
        serviceCustomer.getGuestToken()) as string;
    }

    (async () => {
      const result = await search({
        filters: parameters
      });

      if (result.valid()) {
        this.setTotal(result.total);
        this.setProductList(result.products);
        this.events.emit("loaded-products");
      }
    })();
    (async () => {
      const result = await facetsFilter(new URLSearchParams(parameters));

      if (result.valid()) {
        if (Array.isArray(result.facets)) {
          serviceFacets.setCurrentFacets(result.facets);
        }
      }
    })();
  };
}

const serviceProductList = new ProductListService();

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceProductList"] = serviceProductList;
}

export default serviceProductList;
