import { jsonApiFetch } from "./endpoint";
import { CartResponse } from ".";

type A = { guest_token: string };
type B = { customer_token: string };
type R = {
  product_id: number;
  type: "cart" | "wishlist";
};
type Request = (A | B) & R;

function action(inputData: Request) {
  return jsonApiFetch<CartResponse>(
    "/cartDel",
    JSON.stringify({ ...inputData })
  );
}

export { action as cartDel };
