import * as serviceConnection from "@/services/connection";
import serviceCustomer from "@/services/customer";
import serviceCart from "@/services/cart";
import Events from "@/libs/events";
import cartService from "@/services/cart";

/******************************/

interface EventTypes {
  "changed-delivery": void;
  "changed-order": void;
}
type EventKey = keyof EventTypes;

/******************************/

class OrderService {
  protected events = new Events<EventTypes>();
  protected order: serviceConnection.CheckoutResponse["order"] | null = null;
  protected orderList: serviceConnection.OrderResponse["orders"] = [];
  protected delivery: serviceConnection.CheckoutResponse["delivery"] = [];
  protected deliveries: serviceConnection.CheckoutResponse["delivery"] = [];
  protected payment:
    | serviceConnection.CheckoutResponse["payment"]
    | null = null;
  protected payments: serviceConnection.CheckoutResponse["payment"] = [];
  protected paymentLink: string | null = null;
  protected errorList = [] as {
    key: string;
    message: string;
  }[];
  protected formData = {
    id_cur: 1,
    id_lng: 1,
    id_user: "",

    order_desc: "",

    id_delivery: (0 as number) || null,
    id_payment: (0 as number) || null,
    payment_method: "" as string | null,
    delivery_method: "" as string | null,

    user_type: 0,
    user_company: "",
    user_nip: "",
    user_name: "",
    user_surname: "",
    user_email: "",
    user_phone: "",
    user_address: "",
    user_number: "",
    user_number2: "",
    user_city: "",
    user_postcode: "",
    user_post_office: "",
    user_country: "",
    delivery_other: "",
    delivery_name: "",
    delivery_surname: "",
    delivery_company: "",
    delivery_email: "",
    delivery_phone: "",
    delivery_address: "",
    delivery_number: "",
    delivery_number2: "",
    delivery_city: "",
    delivery_postcode: "",
    delivery_post_office: "",
    delivery_country: "",
    delivery_value: "",

    order_rules: 0,
    order_privacy_policy: 0,
    order_adult: 0,
    order_newsletter: 0
  };
  protected placeholders = {
    company: window.t("Firma"),
    nip: window.t("NIP"),
    name: window.t("Imię"),
    surname: window.t("Nazwisko"),
    email: window.t("Adres e-mail"),
    phone: window.t("Telefon"),
    address: window.t("Ulica"),
    number: window.t("Numer budynku"),
    number2: window.t("Numer lokalu"),
    postcode: window.t("Kod pocztowy"),
    post_office: window.t("Poczta - miasto"),
    country: window.t("Kraj"),
    other: window.t("Adres dostawy inny niż adres płatnika"),
    city: window.t("Miasto")
  };

  public on!: Events<EventTypes>["on"];
  public off!: Events<EventTypes>["off"];
  public emit!: Events<EventTypes>["emit"];

  constructor() {
    this.on = this.events.on.bind(this.events);
    this.off = this.events.off.bind(this.events);

    if (process.env.NODE_ENV !== "production") {
      this.emit = (type: EventKey, ...value: any[]) => {
        console.log("%OrderService", "color:purple;", type, value);
        return this.events.emit(type, value[0]);
      };
    } else {
      this.emit = this.events.emit.bind(this.events);
    }
  }

  public getDeliver() {
    return this.delivery;
  }
  public getDelivery(id: string | number) {
    for (const delivery of this.deliveries) {
      if (
        parseInt((delivery.id as unknown) as string) === parseInt(id as string)
      ) {
        return delivery;
      }
    }
    return null;
  }
  public getPayment(id: string | number) {
    for (const payment of this.payments) {
      if (
        parseInt((payment.id as unknown) as string) === parseInt(id as string)
      ) {
        return payment;
      }
    }
    return null;
  }
  public getPaymentLink() {
    return this.paymentLink;
  }
  public getForm() {
    return this.formData;
  }
  public getErrors() {
    return this.errorList;
  }
  public getPlaceholders() {
    return this.placeholders;
  }
  public getPh(key: keyof OrderService["placeholders"]) {
    return this.placeholders[key] || "Unknown";
  }

  async createOrder(description: string | null = null) {
    this.formData["delivery_method"] = this.formData["delivery_method"] || null;
    this.formData["payment_method"] = this.formData["payment_method"] || null;
    this.formData["id_delivery"] =
      parseInt(this.formData["delivery_method"] || "0") || null;
    this.formData["id_payment"] =
      parseInt(this.formData["payment_method"] || "0") || null;

    const result = await serviceConnection.checkout({
      delivery_id: this.formData.id_delivery,
      ...(serviceCustomer.isLogged()
        ? { customer_token: serviceCustomer.getCustomerToken() as string }
        : { guest_token: serviceCustomer.getGuestToken() as string })
    });

    if (result.valid()) {
      this.delivery = result.delivery;
      this.order = result.order;
      this.payment = result.payment;
      this.errorList = result.errors_list || [];

      for (const delivery of result.delivery) {
        if (!this.getDelivery(delivery.id)) {
          this.deliveries.push(delivery);
        }
      }
      for (const payment of result.payment) {
        if (!this.getPayment(payment.id)) {
          this.payments.push(payment);
        }
      }

      this.order.order_desc = description;

      // uncheck invalid payment
      const avaiablePaymentsId = [];
      for (const payment of this.payment) {
        avaiablePaymentsId.push(payment.id);
      }
      if (
        avaiablePaymentsId.indexOf(this.formData["id_payment"] as number) === -1
      ) {
        this.formData["payment_method"] = null;
        this.formData["id_payment"] = null;
      }

      // eslint-disable-next-line
      //debugger;

      for (const key in this.formData) {
        type KeyF = keyof OrderService["formData"];
        type KeyO = keyof serviceConnection.CheckoutResponse["order"];

        if (!this.formData[key as KeyF] && this.order[key as KeyO]) {
          // eslint-disable-next-line
          //@ts-ignore
          this.formData[key] = this.order[key as KeyO];
        }
      }

      this.emit("changed-delivery");
      this.emit("changed-order");
    }
  }

  async finishOrder(save = true) {
    type KeyF = keyof OrderService["formData"];
    // kopiowanie user_data do delivery_data
    for (const key in this.formData) {
      if (key.search("user_") !== -1) {
        const keyD = key.replace("user_", "delivery_") as KeyF;

        if (!this.formData[keyD] && this.formData[key as KeyF]) {
          // eslint-disable-next-line
          //@ts-ignore
          this.formData[keyD] = this.formData[key];
        }
      }
    }
    if (
      !this.formData["delivery_city" as KeyF] &&
      this.formData["user_post_office" as KeyF]
    ) {
      // eslint-disable-next-line
      //@ts-ignore
      this.formData["delivery_city" as KeyF] = this.formData[
        "user_post_office"
      ];
    }

    // id productów
    const ids = [];
    if (save) {
      const cart = serviceCart.getCartData().items;

      for (const item of cart) {
        ids.push(item.product.id);
      }
    }

    this.formData["delivery_method"] = this.formData["delivery_method"] || null;
    this.formData["payment_method"] = this.formData["payment_method"] || null;
    this.formData["id_delivery"] =
      parseInt(this.formData["delivery_method"] || "0") || null;
    this.formData["id_payment"] =
      parseInt(this.formData["payment_method"] || "0") || null;

    const result = await serviceConnection.checkoutFinalize({
      ...this.formData,
      ...(serviceCustomer.isLogged()
        ? { customer_token: serviceCustomer.getCustomerToken() as string }
        : { guest_token: serviceCustomer.getGuestToken() as string }),
      save,
      ...(ids.length ? { related_products_by: ids } : {})
    });

    this.errorList = result.errors_list || [];

    if (result.valid()) {
      // serviceCart.setCartDataFromCartResonse(result as any, "cart");
      this.order = result.order as OrderService["order"];

      if (save) {
        serviceCart.clear("cart");
      } else {
        await serviceCart.getCart();
      }

      this.paymentLink = result.payment_link || null;
      if (result.payment_link) {
        setTimeout(() => {
          const ref = open(result.payment_link as string, "_blank");

          ref?.addEventListener("close", () => {
            history.pushState({}, "", location.href.replace(location.hash, ""));
          });
        }, 3e3);
      }

      // const chResult = await serviceConnection.checkout({
      //   ...(serviceCustomer.isLogged()
      //     ? { customer_token: serviceCustomer.getCustomerToken() as string }
      //     : { guest_token: serviceCustomer.getGuestToken() as string }),
      // });
      // if (chResult.valid()) {
      //   console.log(chResult);
      // }
    }

    return result;
  }

  public async loadOrders() {
    const result = await serviceConnection.order({
      ...(serviceCustomer.isLogged()
        ? { customer_token: serviceCustomer.getCustomerToken() as string }
        : { guest_token: serviceCustomer.getGuestToken() as string })
    });

    if (result.valid()) {
      this.orderList = result.orders;
    }

    return result;
  }

  public getOrders() {
    return this.orderList;
  }
  public async loadOrder(id: string) {
    const result = await serviceConnection.order({
      ...(serviceCustomer.isLogged()
        ? { customer_token: serviceCustomer.getCustomerToken() as string }
        : { guest_token: serviceCustomer.getGuestToken() as string }),
      order_id: id
    });

    if (result.valid()) {
      //console.log(result);
    }

    return result;
  }
  public getOrder(id: string) {
    if (this.orderList) {
      for (const item of this.orderList) {
        //console.log(item.id_ord, id);
        if (parseInt((item.id_ord as unknown) as string) === parseInt(id)) {
          return item;
        }
      }
    }
    return null;
  }
}

/******************************/

const serviceOrder = new OrderService();

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceOrder"] = serviceOrder;
}

export default serviceOrder;
