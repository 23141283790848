import LazyPromise from "@/libs/LazyPromise";

const promiseReady = new LazyPromise();
let SpeechRecognitionConstructor: typeof SpeechRecognition | null = null;

//DOM load event
window.addEventListener("DOMContentLoaded", () => {
  //Set speech recognition fallback
  // eslint-disable-next-line
  //@ts-ignore
  SpeechRecognitionConstructor =
    window.SpeechRecognition ||
    (window as typeof window & { webkitSpeechRecognition: SpeechRecognition })
      .webkitSpeechRecognition;

  if (SpeechRecognitionConstructor === null) {
    promiseReady.reject(new Error("Not available!"));
  } else {
    if (process.env.NODE_ENV !== "production") {
      console.log("%c🎙 Init", "color:red;font-size:28px;");
    }
    promiseReady.resolve();
  }
});

function listen(): Promise<string> {
  return new Promise((resolve, reject) => {
    if (SpeechRecognitionConstructor === null) {
      reject(new Error("Not available!"));
      return;
    }

    const instance: SpeechRecognition = new SpeechRecognitionConstructor();

    instance.interimResults = true;

    let currentResult = "";
    let endTimeoutId = 0;
    const end = () => {
      instance.stop();
      resolve(currentResult);
      if (process.env.NODE_ENV !== "production") {
        console.log("%c🎙 End", "color:red;font-size:28px;", currentResult);
      }
    };
    const requestEnd = () => {
      if (endTimeoutId) {
        clearTimeout(endTimeoutId);
      }
      endTimeoutId = setTimeout(end, 600);
    };

    instance.addEventListener("result", (event: SpeechRecognitionEvent) => {
      currentResult = event.results[0][0].transcript;
      if (currentResult) {
        requestEnd();
      }
    });

    instance.addEventListener("error", error => {
      instance.abort();
      if (process.env.NODE_ENV !== "production") {
        console.log("%c🎙 Error", "color:red;font-size:28px;", error);
      }
      reject(error);
    });

    if (process.env.NODE_ENV !== "production") {
      console.log("%c🎙 Start", "color:red;font-size:28px;");
    }

    instance.start();
  });
}

const api = {
  isAvailable() {
    return !!SpeechRecognitionConstructor;
  },
  waitToReady() {
    return promiseReady.get();
  },
  start() {
    if (api.isAvailable()) {
      return listen();
    }
    return Promise.reject(new Error("Not available!"));
  }
};

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceSpeechRecognition"] = api;
}

export default api;

/*


  const recognition = new SpeechRecognition(),
        notes = document.querySelector('.notes-list'),
        saveBtn = document.querySelector('.btn-save'),
        createNote = (transcript, newNote = true) => {
            //Create list item element
            const note = document.createElement('li');

            //Add attributes to note
            note.textContent = transcript;
            note.classList.add('note');
            note.setAttribute('contenteditable', '');

            //Add note to notes list
            notes.appendChild(note);

            //Check save button class
            if (newNote && !saveBtn.classList.contains('unsaved')) saveBtn.classList.add('unsaved');
        },
        saveNotes = () => {
            //Get notes
            const noteList = document.querySelectorAll('.note');

            if (noteList.length > 0) {
                //Store notes in local storage
                localStorage.setItem('notes', Array.from(noteList).map(note => note.textContent).join(', '));
            } else {
                //Delete notes entry from local storage
                if (localStorage.getItem('notes') !== null) localStorage.removeItem('notes');
            }

            //Check save button class
            if (saveBtn.classList.contains('unsaved')) saveBtn.classList.remove('unsaved');
        },
        loadNotes = () => {
            //Check if notes exist in local storage
            if (localStorage.getItem('notes') !== null) {
                //Get notes from local storage
                const noteList = localStorage.getItem('notes').split(', ');

                //Display notes
                noteList.forEach(note => createNote(note, false));
            }
        },
        checkNoteLength = e => {
            //Check if note should be deleted
            if (e.target.textContent.length === 0) e.target.remove();

            //Check save button class
            if (!saveBtn.classList.contains('unsaved')) saveBtn.classList.add('unsaved');
        }


  recognition.addEventListener('result', e => createNote(e.results[0][0].transcript));

  recognition.addEventListener('end', recognition.start);

  recognition.start();

  loadNotes();
});


*/
