//@ts-ignore
import Quagga from "quagga";

const div = document.createElement("div");

div.id = "quagga";

type Item = { code: string; times: number };
const state = {
  best: null as string | null,
  countOfCodes: 0,
  bestDiff: 0,
  codes: {} as { [key: string]: Item }
};

let resetCodesHasBeenRequested = false;
let resetCodesTimeoutId = null as number | null;
function resetCodes() {
  resetCodesHasBeenRequested = false;
  state.codes = {};
  state.countOfCodes = 0;
  state.bestDiff = 0;
  state.best = null;
  if (resetCodesTimeoutId !== null) {
    clearTimeout(resetCodesTimeoutId);
    resetCodesTimeoutId = null;
  }
}
function requestResetCodes() {
  if (resetCodesHasBeenRequested === false) {
    if (resetCodesTimeoutId !== null) {
      clearTimeout(resetCodesTimeoutId);
    }
    resetCodesTimeoutId = setTimeout(resetCodes, 1e3);
  }
}

function findBestCode() {
  let first: Item | null = null;
  let second: Item | null = null;

  for (const key in state.codes) {
    const item = state.codes[key];

    if (!first) {
      first = item;
    } else if (first.times < item.times) {
      second = first;
      first = item;
    } else if (!second) {
      second = item;
    }
  }
  if (first && second) {
    state.bestDiff = first.times - second.times;
  }

  if (state.countOfCodes > 10) {
    if (first && second) {
      if (state.bestDiff > 5) {
        state.best = first.code;
      } else {
        state.best = null;
      }
    } else if (first && !second) {
      state.best = first.code;
      state.bestDiff = first.times;
    }
  } else {
    state.best = null;
  }
}

let initRatio = 1;
let currentRatio = 1;

Quagga.onDetected((result: any) => {
  const { code, format } = result.codeResult;
  const key = `${code}:${format}`;

  if (state.codes[key]) {
    state.codes[key].times++;
  } else {
    state.codes[key] = { code, times: 1 };
  }
  state.codes = { ...state.codes }; //watcher
  state.countOfCodes++;

  requestResetCodes();
  findBestCode();

  const drawingCtx = Quagga.canvas.ctx.overlay,
    drawingCanvas = Quagga.canvas.dom.overlay;

  if (result) {
    console.log(result, initRatio, currentRatio);
    if (result.boxes) {
      drawingCtx.clearRect(
        0,
        0,
        parseInt(drawingCanvas.getAttribute("width")),
        parseInt(drawingCanvas.getAttribute("height"))
      );
      // result.boxes
      //   .filter(function(box: any) {
      //     return box !== result.box;
      //   })
      //   .forEach(function(box: any) {
      //     Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
      //       color: "green",
      //       lineWidth: 2
      //     });
      //   });
      //eslint-disable-next-line

      // show boxes
      if (!drawingCanvas.classList.contains("change")) {
        drawingCanvas.classList.add("change");
        setTimeout(() => {
          drawingCanvas.classList.remove("change");
        }, 100);
      }
    }

    if (result.box) {
      let box = result.box;
      if (initRatio !== currentRatio) {
        box = result.box.map((item: number[]) => {
          return [item[0] * currentRatio, item[1] * initRatio];
        });
      }
      Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
        color: "#00F",
        lineWidth: 2
      });
    }

    if (result.codeResult && result.codeResult.code) {
      let line = result.line;
      if (initRatio !== currentRatio) {
        line = [
          { x: line[0].x / initRatio, y: line[0].y / currentRatio },
          { x: line[1].x / initRatio, y: line[1].y / currentRatio }
        ];
      }
      Quagga.ImageDebug.drawPath(line, { x: "x", y: "y" }, drawingCtx, {
        color: "red",
        lineWidth: 3
      });
    }
  }
});

function updateCanvas() {
  const canvas = div.querySelector("canvas");
  const video = div.querySelector("video");
  const ref = Quagga.canvas.dom;

  if (canvas && video) {
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ref.overlay.width = video.videoWidth;
    ref.overlay.height = video.videoHeight;
    ref.image.width = video.videoWidth;
    ref.image.height = video.videoHeight;
    currentRatio = video.videoWidth / video.videoHeight;
  }
}

function initSuccess(err: Error) {
  if (err) {
    console.log(err);

    return;
  }
  updateCanvas();
  Quagga.start();
  const video = div.querySelector("video");
  if (video) {
    initRatio = video.videoWidth / video.videoHeight;
  }
}

const config = {
  inputStream: {
    name: "Live",
    type: "LiveStream",
    target: div // Or '#yourElement' (optional)
  },
  decoder: {
    readers: ["ean_reader" /*, "ean_8_reader"*/]
  },
  locator: {
    patchSize: "medium",
    halfSample: true
  },
  locate: true
};

const api = {
  getState() {
    return state;
  },
  getDom() {
    return div;
  },
  init: () => {
    Quagga.init(config, initSuccess);
  },
  reset: resetCodes,
  start: () => Quagga.start(),
  stop: () => Quagga.stop(),
  test: async () =>
    JSON.stringify(
      (await Quagga.CameraAccess.enumerateVideoDevices()).map((v: any) => ({
        label: v.label,
        info: v.getCapabilities()
      })),
      null,
      " "
    ),
  Quagga
};

window.addEventListener("resize", updateCanvas);
updateCanvas();

/******************************/

export default api;

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"] = window["dev"] || {};
  // eslint-disable-next-line
  //@ts-ignore
  window["dev"]["serviceBarcode"] = api;
}
