import Vue from "vue";

interface Languages {
  pl: void;
  en: void;
}

const data = {
  site_name: {
    pl: "Sparta",
    en: "Sparta"
  },
  Logowanie: {
    pl: "Logowanie",
    en: "Login"
  },
  Rejestracja: {
    pl: "Rejestracja",
    en: "Register"
  },
  "Brak wyników": {
    pl: "Brak wyników",
    en: "No results"
  },
  "Wszystkie kategorie": {
    pl: "Wszystkie kategorie",
    en: "All categories"
  },
  "Nasze marki": {
    pl: "Nasze marki",
    en: "Our brands"
  },
  Sparta: {
    pl: "Sparta",
    en: "Sparta"
  },
  Kategorie: {
    pl: "Kategorie",
    en: "Categories"
  },
  Menu: {
    pl: "Menu",
    en: "Menu"
  },
  Konto: {
    pl: "Konto",
    en: "Account"
  },
  Koszyk: {
    pl: "Koszyk",
    en: "Cart"
  },
  Produkty: {
    pl: "Produkty",
    en: "Products"
  },
  Szukaj: {
    pl: "Szukaj",
    en: "Search"
  },
  "Zobacz wszystkie": {
    pl: "Zobacz wszystkie",
    en: "See all"
  },
  "Pokaż wszystkie": {
    pl: "Pokaż wszystkie",
    en: "See all"
  },

  sortowanie_domyslne: {
    pl: "Domyślne",
    en: "Default"
  },
  sortowanie_nazwa_rosnaco: {
    pl: "Nazwa - rosnąco",
    en: "By name - ascending"
  },
  sortowanie_nazwa_malejaco: {
    pl: "Nazwa - malejąco",
    en: "By name - descending"
  },
  sortowanie_cena_rosnaco: {
    pl: "Cena - rosnąco",
    en: "By price - ascending"
  },
  sortowanie_cena_malejaco: {
    pl: "Cena - malejąco",
    en: "By price - descending"
  },
  sortowanie_symbol_rosnaco: {
    pl: "Symbol - rosnąco",
    en: "By symbol - ascending"
  },
  sortowanie_symbol_malejaco: {
    pl: "Symbol - malejąco",
    en: "By symbol - descending"
  },
  loading: {
    pl: "Wczytywanie",
    en: "Loading"
  },

  cena: {
    pl: "Cena",
    en: "Price"
  },

  productList_produkty: {
    pl: "Produkty",
    en: "Products"
  },
  productList_kategoria: {
    pl: "Kategoria",
    en: "Category"
  },

  cart_dodaj: {
    pl: "Do koszyka",
    en: "Add to cart"
  },

  product_wysylka: {
    pl: "Wysyłka",
    en: "Sending"
  },
  product_dostepnosc: {
    pl: "Dostępność",
    en: "Availability"
  },
  product_promocja: {
    pl: "Promocja",
    en: "Sale"
  },
  product_nowosc: {
    pl: "Nowość",
    en: "New"
  },
  product_sztuki_short: {
    pl: "szt",
    en: "kpl"
  },
  product_related_products: {
    pl: "Powiązane produkty",
    en: "Related products"
  },
  product_customer_choices: {
    pl: "Klienci wybrali",
    en: "Customer choices"
  },

  cart_nazwa_produktu: {
    pl: "Nazwa produktu",
    en: "Product name"
  },
  cart_szczegolt_produktu: {
    pl: "Szczegóły",
    en: "Details"
  },
  cart_ilosc_produktu: {
    pl: "Ilość",
    en: "Count"
  },
  cart_cena_produktu: {
    pl: "Cena",
    en: "Price"
  },
  cart_cena_calkowita_produktu: {
    pl: "Cena całkowita",
    en: "Total price"
  },
  cart_suma: {
    pl: "Suma",
    en: "Summary"
  },
  cart_do_zaplaty: {
    pl: "Do zapłaty",
    en: "Value to pay"
  },

  cartModal_koszyk: {
    pl: "Koszyk",
    en: "Cart"
  },
  cartModal_dodano: {
    pl: "Dodano do koszyka",
    en: "Added to the cart"
  },
  cartModal_kontynuuj_zakupy: {
    pl: "Kontynuuj zakupy",
    en: "Continue shopping"
  },
  cartModal_do_koszyka: {
    pl: "Przejdz do koszyka",
    en: "Go to the cart"
  },

  menu_categorie_wszystkie: {
    pl: "Wszystkie",
    en: "All of"
  },
  "Wybierz dostawę i płatność": {
    pl: "Wybierz dostawę i płatność",
    en: "Choose payment and delivery method"
  },
  Schowek: {
    pl: "Schowek",
    en: "Wishlist"
  },
  Przechowalnia: {
    pl: "Przechowalnia",
    en: "Wishlist"
  },
  "Dodano do przechowalni": {
    pl: "Dodano do przechowalni",
    en: "Product has been added to the wishlist"
  },
  "Usunięto z przechowalni": {
    pl: "Usunięto z przechowalni",
    en: "Product has been removet ftom the wishlist"
  },
  "Dodano do koszyka": {
    pl: "Dodano do koszyka",
    en: "Product has been added to the cart"
  },
  "Dostawa i płatność": {
    pl: "Dostawa i płatność",
    en: "Delivery and payment"
  },
  Potwierdzenie: {
    pl: "Potwierdzenie",
    en: "Confirm"
  },
  Filtruj: {
    pl: "Filtruj",
    en: "Filter"
  },
  Filtry: {
    pl: "Filtry",
    en: "Filters"
  },
  Sortuj: {
    pl: "Sortuj",
    en: "Sort"
  },
  Promocja: {
    pl: "Promocja",
    en: "Promotion"
  },
  "Zobacz więcej": {
    pl: "Zobacz więcej",
    en: "See more"
  },
  "Pokaż więcej": {
    pl: "Pokaż więcej",
    en: "See more"
  },
  Pokaż: {
    pl: "Pokaż",
    en: "Show"
  },
  Wróć: {
    pl: "Wróć",
    en: "Back"
  },
  Wstecz: {
    pl: "Wstecz",
    en: "Back"
  },
  Wyloguj: {
    pl: "Wyloguj",
    en: "Logout"
  },
  google_login_success: {
    pl: "Zalogowano",
    en: "Logged"
  },
  google_login_error: {
    pl: "Coś poszło nie tak",
    en: "Something went wrong"
  },
  facebook_login_success: {
    pl: "Zalogowano",
    en: "Logged"
  },
  facebook_login_error: {
    pl: "Coś poszło nie tak",
    en: "Something went wrong"
  },
  "Znajdź produkt": {
    pl: "Znajdź produkt",
    en: "Find a product"
  },
  login_lub: {
    pl: "lub",
    en: "or"
  },
  login_kontynuujJakoGosc: {
    pl: "Kontynuuj jako gość",
    en: "Continue as a gest"
  },
  login_zalogujSie: {
    pl: "Zaloguj się",
    en: "Login"
  },
  login_zajerestrujSie: {
    pl: "Zajerestruj się",
    en: "Sing up"
  },
  login_niePamietamHasla: {
    pl: "Nie pamiętam hasła/adresu e-mail",
    en: "I don'n remember the password/e-mail address"
  },
  login_label_email: {
    pl: "Login / E-mail",
    en: "Login / E-mail"
  },
  login_label_haslo: {
    pl: "Hasło",
    en: "Password"
  },
  "Dostawa kurierem GLS": {
    pl: "Dostawa kurierem GLS",
    en: "Delivery by GLS courier"
  },
  "Odbiór własny": {
    pl: "Odbiór własny",
    en: "Own pickup"
  },
  "Paczkomat Inpost": {
    pl: "Paczkomat Inpost",
    en: "Inpost point"
  },
  "Za pobraniem": {
    pl: "Za pobraniem",
    en: "Cash on delivery"
  },
  Firma: {
    pl: "Firma",
    en: "Company"
  },
  "Osoba prywatna": {
    pl: "Osoba prywatna",
    en: "Person"
  },
  Dostawa: {
    pl: "Dostawa",
    en: "Delivery"
  },
  Płatność: {
    pl: "Płatność",
    en: "Payment"
  },
  "Dane adresowe": {
    pl: "Dane adresowe",
    en: "Address data"
  },
  "Dane płatnika": {
    pl: "Dane płatnika",
    en: "Payer data"
  },
  "Dane dostawy": {
    pl: "Dane dostawy",
    en: "Delivery data"
  },
  "Karta płatncza": {
    pl: "Karta płatncza",
    en: "Credit card"
  },
  Przelew: {
    pl: "Przelew",
    en: "Money transfer"
  },
  Gotówka: {
    pl: "Gotówka",
    en: "Cash"
  },
  Imię: {
    pl: "Imię",
    en: "Name"
  },
  Nazwisko: {
    pl: "Nazwisko",
    en: "Lastname"
  },
  "Adres e-mail": {
    pl: "Adres e-mail",
    en: "E-mail address"
  },
  "E-mail": {
    pl: "E-mail",
    en: "E-mail"
  },
  Telefon: {
    pl: "Telefon",
    en: "Phone"
  },
  Ulica: {
    pl: "Ulica",
    en: "Street"
  },
  "Numer budynku": {
    pl: "Numer budynku",
    en: "Building number"
  },
  "Numer lokalu": {
    pl: "Numer lokalu",
    en: "Permit number"
  },
  "Kod pocztowy": {
    pl: "Kod pocztowy",
    en: "Postcode"
  },
  Miasto: {
    pl: "Miasto",
    en: "City"
  },
  Kraj: {
    pl: "Kraj",
    en: "Country"
  },
  "Poczta - miasto": {
    pl: "Miasto",
    en: "Postoffice"
  },
  "Wybierz metodę dostawy": {
    pl: "Wybierz metodę dostawy",
    en: "Select method of delivery"
  },
  "Wybierz metodę płatności": {
    pl: "Wybierz metodę płatności",
    en: "Select method of payment"
  },
  "To pole jest wymagane": {
    pl: "To pole jest wymagane",
    en: "This field is required"
  },
  "Ta zgoda jest wymagana": {
    pl: "Ta zgoda jest wymagana",
    en: "That agreement is required"
  },
  "Adres email nie jest poprawny": {
    pl: "Adres email nie jest poprawny",
    en: "The email address is not valid"
  },
  "Do zapłaty": {
    pl: "Do zapłaty",
    en: "To pay"
  },
  "Wartość produktów": {
    pl: "Wartość produktów",
    en: "Value of products"
  },
  Razem: {
    pl: "Razem",
    en: "Summary"
  },
  dostawa: {
    pl: "dostawa",
    en: "delivery"
  },
  Dalej: {
    pl: "Dalej",
    en: "Continue"
  },
  "Sztuk w opakowaniu": {
    pl: "Sztuk w opakowaniu",
    en: "In pack"
  },
  Jednostka: {
    pl: "Jednostka",
    en: "Unit"
  },
  "Szczegółowy opis": {
    pl: "Szczegółowy opis",
    en: "Detailed description"
  },
  "Opis szczegółowy": {
    pl: "Opis szczegółowy",
    en: "Detailed description"
  },
  "Kod promocyjny": {
    pl: "Kod promocyjny",
    en: "Discount coupon"
  },
  menu_dzien_dobry: {
    pl: "Dzień dobry",
    en: "Welcome"
  },
  menu_zalogoj: {
    pl: "Zaloguj się lub załóż konto",
    en: "Login or sigin"
  },
  "Zamawiam z obowiązkiem zapłaty": {
    pl: "Zamawiam z obowiązkiem zapłaty",
    en: "I order with a payment obligation"
  },

  Realizacja: {
    pl: "Realizacja",
    en: "Realization"
  },
  "Brak produktów": {
    pl: "Brak produktów",
    en: "No products"
  },
  "Dziękujemy za skorzystanie z naszej oferty": {
    pl: "Dziękujemy za&nbsp;skorzystanie z&nbsp;naszej oferty",
    en: "Thank you for using our offer"
  },
  "Przejdź do strony głównej": {
    pl: "Przejdź do&nbsp;strony głównej",
    en: "Go to the home"
  },

  "Wpisz kod rabatowy": {
    pl: "Wpisz kod rabatowy",
    en: "Enter a discount code"
  },
  "Komentarz do zamówienia": {
    pl: "Komentarz do zamówienia",
    en: "Comment on the order"
  },
  "Katalog<br />produktu": {
    pl: "Katalog<br />produktu",
    en: "Product<br />catalog"
  },
  "Karta<br />produktu": {
    pl: "Karta<br />produktu",
    en: "Product<br />card"
  },
  "Opis<br />rysunek": {
    pl: "Opis<br />rysunek",
    en: "Description<br />drawing"
  },
  Certyfikaty: {
    pl: "Certyfikaty",
    en: "Certificates"
  },
  "Wyszukaj...": {
    pl: "Wyszukaj...",
    en: "Search..."
  },
  "Zarejestruj się": {
    pl: "Zarejestruj się",
    en: "Sing up"
  },
  "Konto indiwidualne": {
    pl: "Konto indywidualne",
    en: "Individual account"
  },
  "Konto indywidualne": {
    pl: "Konto indywidualne",
    en: "Individual account"
  },
  "Konto firmowe": {
    pl: "Konto firmowe",
    en: "Company account"
  },
  "Dane personalne": {
    pl: "Dane personalne",
    en: "Personal data"
  },
  "Dane firmy": {
    pl: "Dane firmy",
    en: "Company data"
  },
  "Dane logowania": {
    pl: "Dane logowania",
    en: "Login data"
  },
  "Informacje dodatkowe": {
    pl: "Informacje dodatkowe",
    en: "Additional information"
  },
  Adres: {
    pl: "Adres",
    en: "Address"
  },
  NIP: {
    pl: "NIP",
    en: "NIP"
  },
  Hasło: {
    pl: "Hasło",
    en: "Password"
  },
  "Powtórz hasło": {
    pl: "Powtórz hasło",
    en: "Confirm password"
  },
  "Strona www": {
    pl: "Strona www",
    en: "WWW Site"
  },
  "Pobierz dane firmy z GUS": {
    pl: "Pobierz dane firmy z GUS",
    en: "Download company data from the GUS"
  },
  "Jakie konto chcesz założyć": {
    pl: "Jakie konto chcesz założyć",
    en: "Select an account type"
  },
  "lub zajerestruj się za pomocą": {
    pl: "lub zajerestruj się za pomocą",
    en: "or sing up using an social account"
  },
  "Pole jest puste": {
    pl: "Pole jest puste",
    en: "Field is empty"
  },
  "Dane adresowe dostawy": {
    pl: "Dane adresowe dostawy",
    en: "Delivery address data"
  },
  "Dane adresowe odbiorcy": {
    pl: "Dane adresowe odbiorcy",
    en: "Recipient address data"
  },
  "Dane odbiorcy": {
    pl: "Dane odbiorcy",
    en: "Recipient data"
  },
  "Adres dostawy inny niż adres płatnika": {
    pl: "Adres dostawy inny niż adres płatnika",
    en: "Delivery address other than payer address"
  },
  Zalogowano: {
    pl: "Zalogowano",
    en: "Logged in"
  },
  Wylogowano: {
    pl: "Wylogowano",
    en: "Logouted"
  },
  Zajerestrowano: {
    pl: "Zajerestrowano",
    en: "Signed up"
  },
  "Kod przyjęty": {
    pl: "Kod przyjęty",
    en: "The code has been accepted"
  },
  Kupon: {
    pl: "Kupon",
    en: "Coupon"
  },
  "Brak danych dla klucza": {
    pl: "Brak danych dla klucza",
    en: "No data for the slug"
  },
  "Coś poszło nie tak": {
    pl: "Coś poszło nie tak",
    en: "Something went wrong"
  },
  "Dodawanie adresu": {
    pl: "Dodawanie adresu",
    en: "Address adding"
  },
  "Edycja adresu": {
    pl: "Edycja adresu",
    en: "Address editing"
  },
  "Dla płatności": {
    pl: "Dla płatności",
    en: "For payments"
  },
  "Dla dostawy": {
    pl: "Dla dostawy",
    en: "For delivery"
  },
  Poczta: {
    pl: "Poczta",
    en: "Post office"
  },
  Zapisz: {
    pl: "Zapisz",
    en: "Save"
  },
  "Dodaj adres": {
    pl: "Dodaj adres",
    en: "Add an adrress"
  },
  "Dodaj nowy adres": {
    pl: "Dodaj nowy adres",
    en: "Add a new adrress"
  },
  "Moje konto": {
    pl: "Moje konto",
    en: "My account"
  },
  "Moje adresy": {
    pl: "Moje adresy",
    en: "My adrress list"
  },
  Adresy: {
    pl: "Adresy",
    en: "Addresses"
  },
  "Moje zamówienia": {
    pl: "Moje zamówienia",
    en: "My order list"
  },
  Zamówienia: {
    pl: "Zamówienia",
    en: "Orders"
  },
  "Moje rabaty": {
    pl: "Moje rabaty",
    en: "My discount list"
  },
  Zapisano: {
    pl: "Zapisano",
    en: "Saved"
  },
  "Nie udało się zapisać zmian": {
    pl: "Nie udało się zapisać zmian",
    en: "Saving failed"
  },
  Usunięto: {
    pl: "Usunięto",
    en: "Deleted"
  },
  "Usówanie nieudane": {
    pl: "Usówanie nieudane",
    en: "Deleting failed"
  },
  Tak: {
    pl: "Tak",
    en: "Yes"
  },
  Nie: {
    pl: "Nie",
    en: "No"
  },
  Edytuj: {
    pl: "Edytuj",
    en: "Edit"
  },
  Usuń: {
    pl: "Usuń",
    en: "Delete"
  },
  Zobacz: {
    pl: "Zobacz",
    en: "See"
  },
  "Brak adresów": {
    pl: "Brak adresów",
    en: "No addresses"
  },
  "Brak zamówień": {
    pl: "Brak zamówień",
    en: "No orders"
  },
  "Wartość zamówienia": {
    pl: "Wartość zamówienia",
    en: "Order value"
  },
  Szczegóły: {
    pl: "Szczegóły",
    en: "Details"
  },
  "Z listy": {
    pl: "Z listy",
    en: "From the list"
  },
  "Wybierz paczkomat": {
    pl: "Wybierz paczkomat",
    en: "Select a inpost point"
  },
  "Resetowanie hasła": {
    pl: "Resetowanie hasła",
    en: "Reset yours password"
  },
  "Twój adres email": {
    pl: "Twój adres email",
    en: "Your email"
  },
  "Resetuj hasło": {
    pl: "Resetuj hasło",
    en: "Reset password"
  },
  "Status zamówienia": {
    pl: "Status zamówienia",
    en: "Order status"
  },
  "Status płatności": {
    pl: "Status płatności",
    en: "Payment status"
  },
  "Metoda płatności": {
    pl: "Metoda płatności",
    en: "Payment method"
  },
  "Metoda dostawy": {
    pl: "Metoda dostawy",
    en: "Delivery method"
  },
  "Koszt płatności": {
    pl: "Koszt płatności",
    en: "Payment cost"
  },
  "Koszt dostawy": {
    pl: "Koszt dostawy",
    en: "Delivery cost"
  },
  "Adres dostawy": {
    pl: "Adres dostawy",
    en: "Delivery address"
  },
  "Adres płatności": {
    pl: "Adres dostawy",
    en: "Payment address"
  },
  "Adres dostawy i płatności": {
    pl: "Adres dostawy i płatności",
    en: "Delivery & payment address"
  },
  "Zamówienie nr": {
    pl: "Zamówienie nr",
    en: "Order no."
  },
  "Nie udało się pobrać danych": {
    pl: "Nie udało się pobrać danych",
    en: "Downloading data failed"
  },
  "Dodatkowe informacje": {
    pl: "Dodatkowe informacje",
    en: "Additional information"
  },
  Wartość: {
    pl: "Wartość",
    en: "Value"
  },
  Rabat: {
    pl: "Rabat",
    en: "Discount"
  },
  "Razem netto": {
    pl: "Razem netto",
    en: "Total"
  },
  "Razem brutto": {
    pl: "Razem brutto",
    en: "Total gross"
  },
  "Błąd serwera": {
    pl: "Błąd serwera",
    en: "Server error"
  },
  Komentarz: {
    pl: "Komentarz",
    en: "Description"
  },
  Pewność: {
    pl: "Pewność",
    en: "Certainty"
  },
  "Skaner kodów kreskowych": {
    pl: "Skaner kodów kreskowych",
    en: "Barcode scanner"
  },
  zł: {
    pl: "zł",
    en: "PLN"
  },
  "Koszyk jest pusty": {
    pl: "Koszyk jest pusty",
    en: "Your cart is empty"
  },
  "Przechowalnia jest pusta": {
    pl: "Przechowalnia jest pusta",
    en: "The wishlist is empty"
  },
  "Za chwilę nastąpi przekierowanie do strony z płatnościami.": {
    pl: "Za chwilę nastąpi przekierowanie do strony z płatnościami.",
    en: "Za chwilę nastąpi przekierowanie do strony z płatnościami." // TODO
  }
};

let currentLanguage: keyof Languages = "pl";
const api = {
  setLanguage(key: keyof Languages) {
    currentLanguage = key;
  }
};
export default api;

export function t(key: keyof typeof data): string {
  try {
    return data[key][currentLanguage];
  } catch (error) {
    console.error(`No translation [ ${key} ][ ${currentLanguage} ]`);
  }
  return `[ ${key} ][ ${currentLanguage} ]`;
}
window.t = t;

Vue.use({
  install(Vue) {
    Vue.prototype.t = window.t;
  }
});
