import { jsonApiFetch } from "./endpoint";

type Request = {
  email: string;
  guestToken: string;
  deviceUuid: string;
  pushToken: string;
};
type Response = {
  message: string;
  checksum: string;
  errors_count: number;
};

function action(inputData: Request) {
  return jsonApiFetch<Response>(
    "/customerPassword",
    JSON.stringify({
      email: inputData.email,
      push_token: inputData.pushToken,
      device_uuid: inputData.deviceUuid,
      guest_token: inputData.guestToken
    })
  );
}

export { action as customerPassword, Response as PasswordResponse };
