var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)},"reset":_vm.onReset}},[_c('nav',{staticClass:"row m-0 p-Gp2 nav-registration"},[_c('div',{staticClass:"col m-Gp2 p-0"},[_c('div',{on:{"click":_vm.setCompany}},[_c('span',{staticClass:"nav-btn px-2 py-4",class:_vm.formData.accountType === 'company' ? 'active' : ''},[_c('figure',{domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/iconCompany.svg'))}}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.t("Konto firmowe")))])])])]),_c('div',{staticClass:"col m-Gp2 p-0"},[_c('div',{on:{"click":_vm.setIndividual}},[_c('span',{staticClass:"nav-btn px-2 py-4",class:_vm.formData.accountType === 'individual' ? 'active' : ''},[_c('figure',{domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/iconUser.svg'))}}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.t("Konto indiwidualne")))])])])])]),_c('section',{staticClass:"bg-white"},[(_vm.formData.accountType === 'individual')?_c('h2',{staticClass:"h1"},[_vm._v(" "+_vm._s(_vm.t("Dane personalne"))+" ")]):_vm._e(),(_vm.formData.accountType === 'company')?[_c('h2',{staticClass:"h1"},[_vm._v(" "+_vm._s(_vm.t("Dane firmy"))+" ")]),_c('ValidationProvider',{attrs:{"name":"cli_nip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0"},[_c('b-form-input',{attrs:{"id":"input-cli_nip","name":"cli_nip","type":"text","placeholder":_vm.formPlaceholders.nip},model:{value:(_vm.formData.cli_nip),callback:function ($$v) {_vm.$set(_vm.formData, "cli_nip", $$v)},expression:"formData.cli_nip"}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"btn-get-data-by-nip"},[_c('button',{staticClass:"btn btn-link",attrs:{"type":"button"},on:{"click":_vm.getCompanyData}},[_vm._v(" "+_vm._s(_vm.t("Pobierz dane firmy z GUS"))+" ")]),(_vm.getCompanyDataStatus === 'wait')?_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])]):_vm._e(),(_vm.getCompanyDataStatus === 'error')?_c('b-alert',{staticClass:"mb-2 p-2",attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.t("Nie udało się pobrać danych"))+" ")]):_vm._e()],1),_c('ValidationProvider',{attrs:{"name":"cli_company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"input-cli_company","name":"cli_company","type":"text","placeholder":_vm.formPlaceholders.company},model:{value:(_vm.formData.cli_company),callback:function ($$v) {_vm.$set(_vm.formData, "cli_company", $$v)},expression:"formData.cli_company"}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})]:_vm._e(),_c('ValidationProvider',{attrs:{"name":"cli_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"input-cli_name","name":"cli_name","type":"text","placeholder":_vm.formPlaceholders.name},model:{value:(_vm.formData.cli_name),callback:function ($$v) {_vm.$set(_vm.formData, "cli_name", $$v)},expression:"formData.cli_name"}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"cli_surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"input-cli_surname","type":"text","placeholder":_vm.formPlaceholders.surname},model:{value:(_vm.formData.cli_surname),callback:function ($$v) {_vm.$set(_vm.formData, "cli_surname", $$v)},expression:"formData.cli_surname"}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('h3',[_vm._v(_vm._s(_vm.t("Adres")))]),_c('FormAddress',{attrs:{"suffix":"cli_","formData":_vm.formData,"formPlaceholders":_vm.formPlaceholders}})],2),_c('section',{staticClass:"bg-white"},[_c('h2',{staticClass:"h1"},[_vm._v(_vm._s(_vm.t("Dane logowania")))]),_c('ValidationProvider',{attrs:{"name":"cli_email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"input-cli_email","name":"cli_email","type":"email","placeholder":_vm.formPlaceholders.email},model:{value:(_vm.formData.cli_email),callback:function ($$v) {_vm.$set(_vm.formData, "cli_email", $$v)},expression:"formData.cli_email"}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"cli_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"input-cli_password","name":"cli_password","type":"password","placeholder":_vm.formPlaceholders.password},model:{value:(_vm.formData.cli_password),callback:function ($$v) {_vm.$set(_vm.formData, "cli_password", $$v)},expression:"formData.cli_password"}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"cli_confirm_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0"},[_c('b-form-input',{attrs:{"id":"input-cli_confirm_password","name":"cli_confirm_password","type":"password","placeholder":_vm.formPlaceholders.confirmation},model:{value:(_vm.formData.cli_confirm_password),callback:function ($$v) {_vm.$set(_vm.formData, "cli_confirm_password", $$v)},expression:"formData.cli_confirm_password"}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('section',{staticClass:"bg-white"},[_c('h2',{staticClass:"h1"},[_vm._v(_vm._s(_vm.t("Informacje dodatkowe")))]),_c('ValidationProvider',{attrs:{"name":"cli_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"input-cli_phone","name":"cli_phone","type":"text","placeholder":_vm.formPlaceholders.phone},model:{value:(_vm.formData.cli_phone),callback:function ($$v) {_vm.$set(_vm.formData, "cli_phone", $$v)},expression:"formData.cli_phone"}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"cli_www"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0"},[_c('b-form-input',{attrs:{"id":"input-cli_www","name":"cli_www","type":"text","placeholder":_vm.formPlaceholders.www},model:{value:(_vm.formData.cli_www),callback:function ($$v) {_vm.$set(_vm.formData, "cli_www", $$v)},expression:"formData.cli_www"}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('section',[_vm._l((_vm.checkboxes),function(item,index){return [_c('ValidationProvider',{key:index,attrs:{"name":("cli_" + (item.slug))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.checkboxes[("cli_" + (item.slug))]),expression:"formData.checkboxes[`cli_${item.slug}`]"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":("checkbox-" + (item.slug)),"name":("cli_" + (item.slug))},domProps:{"checked":Array.isArray(_vm.formData.checkboxes[("cli_" + (item.slug))])?_vm._i(_vm.formData.checkboxes[("cli_" + (item.slug))],null)>-1:(_vm.formData.checkboxes[("cli_" + (item.slug))])},on:{"change":function($event){var $$a=_vm.formData.checkboxes[("cli_" + (item.slug))],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData.checkboxes, ("cli_" + (item.slug)), $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData.checkboxes, ("cli_" + (item.slug)), $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData.checkboxes, ("cli_" + (item.slug)), $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":("checkbox-" + (item.slug))}},[_c('div',{domProps:{"innerHTML":_vm._s(item.text_raw)}})]),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0] ? _vm.t("Ta zgoda jest wymagana") : "")+" ")])],1)]}}],null,true)})]})],2),_c('div',{staticClass:"p-0 m-0"},[_c('div',{staticClass:"btn-order-bottom-bar as-padding"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/order/delivery-and-payments"}},[_vm._v(" "+_vm._s(_vm.t("Zarejestruj się"))+" ")])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }