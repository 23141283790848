











import { Component, Vue } from "vue-property-decorator";
import Header from "@/views/layout/header";
import Modal from "@/views/layout/Modal";
import Drawers from "@/views/layout/drawer/Main.vue";
import BottomBar from "@/views/layout/BottomBar.vue";
import serviceCustomer from "@/services/customer";

@Component({
  components: {
    Header,
    Modal,
    Drawers,
    BottomBar
  }
})
export default class App extends Vue {
  mounted() {
    serviceCustomer.on("after-login", () => {
      this.$bvToast.toast(window.t("Zalogowano"), {
        title: window.t("Logowanie"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    });
    serviceCustomer.on("after-logout", () => {
      this.$bvToast.toast(window.t("Wylogowano"), {
        title: window.t("Logowanie"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    });
  }
}
