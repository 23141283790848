import { jsonApiFetch } from "./endpoint";

export type Content = {
  picture: string;
  title: string;
  class: string;
  icon: string;
  text: string | null;
  text_raw: string | null;
  url: string;
  filters: string;
  style: string;
  slug: string;
  user_type: string;
  show_title: boolean;
  show_as_banner: false;
  show_as_products: false;
  priority: number;
  content_type: string;
  banner_size: string;
  active: boolean;
  active_translation: boolean;
  date_use: boolean;
  date_from: string;
  date_to: string;
};
type Response = { [key: string]: Content | Content[] };

function action() {
  return jsonApiFetch<Response>("/content", undefined, "get");
}

export { action as content, Response as ContentResponse };
