var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"top-bar px-G2"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('button',{staticClass:"btn btn-link btn-menu",on:{"click":_vm.handlerOpenMenu}},[_c('figure',{staticClass:"m-0 d-flex align-items-center",domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/iconMenu.svg'))}})]),_vm._m(0),_c('div',[_c('button',{staticClass:"btn btn-secondary btn-search",on:{"click":_vm.handlerOpenCategories}},[_c('span',{staticClass:"px-1 text"},[_vm._v(_vm._s(_vm.t("Znajdź produkt")))]),_c('span',{staticClass:"px-1 el-icon-search"})])])])]),_c('SwiperCategories'),_c('SwiperMain'),_c('SwiperBrands'),_c('SwiperProducts'),(_vm.getContent('home_articles').length)?_vm._l((_vm.getContent('home_articles')),function(item,index){return _c('div',{key:index,staticClass:"article-list"},[_c('h2',{staticClass:"h1 px-G2"},[_vm._v(_vm._s(item.title))]),_c('ul',_vm._l((_vm.getContent(item.slug, 5)),function(article,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":article.url}},[_vm._v(" "+_vm._s(article.title)+" ")])],1)}),0),_c('div',{staticClass:"d-flex justify-content-center"},[_c('router-link',{staticClass:"btn btn-light btn-see-all my-3",attrs:{"to":("/articles/" + (item.slug))}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.t("Pokaż więcej"))+" ("+_vm._s(_vm.contents[item.slug].length)+") ")])])],1)])}):_vm._e(),(
      _vm.getContent('home_footer_text').length ||
        _vm.getContent('home_footer_text').length
    )?_c('footer',{staticClass:"mt-3 px-G2"},[(_vm.getContent('home_footer_btn').length)?[_c('ul',_vm._l((_vm.getContent('home_footer_btn')),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":item.url}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)}),0)]:_vm._e(),(_vm.getContent('home_footer_text').length)?[_c('div',{staticClass:"rights",domProps:{"innerHTML":_vm._s(
          _vm.getContent('home_footer_text')[0].text.replace(
            '#YEAR',
            new Date().getFullYear()
          )
        )}}),_c('div',{staticClass:"rights d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.t("Realizacja"))+": "),_c('span',{staticClass:"text-primary"},[_vm._v("www.ecreo.eu")])]),_c('span',[_vm._v("build: "+_vm._s(_vm.build))])])]:_vm._e()],2):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.svg"),"width":"200"}})])}]

export { render, staticRenderFns }