

















































import { Component, Prop, Vue } from "vue-property-decorator";
import * as serviceConnection from "@/services/connection";

@Component
export default class OrderAddress extends Vue {
  @Prop({ required: true }) order!: serviceConnection.OrderItem;

  get suffix() {
    return this.order.delivery_other === "1" ? "delivery_" : "delivery_";
  }
}
