



























import { Component, Prop, Vue } from "vue-property-decorator";
import { AddressResponse } from "@/services/connection";

@Component
export default class AddressesDropdown extends Vue {
  @Prop({ required: true, default: () => [] })
  addresses!: AddressResponse["addresses"];
  @Prop({ required: true }) onSelect!: (
    item: AddressResponse["addresses"][0]
  ) => void;
}
