



























































































































































































































































// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import HelloWorld from "@/components/HelloWorld.vue";
import serviceLayout, { CategoryItem } from "@/services/layout";
import SwiperCategories from "./SwiperCategories.vue";
import SwiperMain from "./SwiperMain.vue";
import SwiperBrands from "./SwiperBrands.vue";
import SwiperProducts from "./SwiperProducts.vue";
import { Content } from "../../services/connection";
import serviceCategoryMenu from "@/services/categoryMenu";

@Component({
  components: {
    HelloWorld,
    SwiperCategories,
    SwiperMain,
    SwiperBrands,
    SwiperProducts,
    Swiper,
    SwiperSlide
  }
})
export default class Home extends Vue {
  private contents = serviceLayout.getContents();
  protected build = window.document.documentElement.dataset.build;

  getContent(name: string, limit?: number) {
    const list = (this.contents[name] as Content[]) || [];

    if (limit) {
      return list.slice(0, limit);
    }

    return list;
  }

  beforeMount() {
    window.scrollTo(0, 0);
    serviceLayout.on("changed-contents", this.onContentsChanged);
  }
  beforeDestroy() {
    serviceLayout.off("changed-contents", this.onContentsChanged);
  }

  onContentsChanged() {
    this.contents = serviceLayout.getContents();
  }

  handlerOpenMenu() {
    serviceLayout.setMenuVisible(true);
  }

  handlerOpenCategories() {
    serviceCategoryMenu.show();
  }
}
