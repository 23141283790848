import { jsonApiFetch } from "./endpoint";

type Facet = {
  id: string;
  attribute: string;
  label: string;
  value: {
    id: number;
    name: string;
    hex: string | null;
    related: number[];
    children?: any[];
  }[];
  range?: {
    from: number;
    to: number;
  };
};

type Response = {
  total: number;
  facets: Facet[];
  checksum: string;
  errors_count: number;
};

function action(searchParams: URLSearchParams) {
  return jsonApiFetch<Response>("/facetsFilter", searchParams);
}

export { action as facetsFilter, Response as FacetsFilterResponse };
