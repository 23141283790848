
































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SocialButtons from "@/views/account/login/SocialButtons.vue";
import serviceCustomer from "@/services/customer";

@Component({
  components: {
    SocialButtons,
    ValidationProvider,
    ValidationObserver
  }
})
export default class Login extends Vue {
  private formData = {
    username: "",
    password: ""
  };
  private lock = false;

  onSubmit() {
    this.lock = true;

    (async () => {
      const result = await serviceCustomer.login(
        this.formData.username,
        this.formData.password
      );

      if (result !== null) {
        if (!result.valid()) {
          type Errors = { [key: string]: string[] };
          const errors = {} as Errors;
          result.getErrors().map(row => {
            errors[row.key] = errors[row.key] || [];
            errors[row.key].push(row.message);
          });
          (this.$refs.form as {
            setErrors(errors: Errors): void;
          } & Vue).setErrors(errors);
        } else {
          this.$bvToast.toast(window.t("Zalogowano"), {
            title: window.t("Logowanie"),
            toaster: "toast-bottom-bar-slot",
            solid: true,
            appendToast: true,
            variant: "primary",
            headerClass: "d-none"
          });
          try {
            if (serviceCustomer.redirectAfterLogin) {
              this.$router.push(serviceCustomer.redirectAfterLogin);
              serviceCustomer.redirectAfterLogin = "";
            } else {
              this.$router.push("/");
            }
          } catch (error) {
            null;
          }
        }
        this.lock = false;
      }
    })();
  }
}
