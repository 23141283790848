







































import { Component, Vue } from "vue-property-decorator";
import serviceFacets from "@/services/facets";
import serviceProductList from "../../services/productList";

@Component
export default class ProductFilterLabels extends Vue {
  private labelList = [] as {
    attribute: string;
    id: string;
    value: string;
    label: string;
  }[];

  /******************************/

  beforeMount() {
    this.updateFilterList();
    serviceFacets.on("changed-current", this.updateFilterList);
  }
  beforeDestroy() {
    serviceFacets.off("changed-current", this.updateFilterList);
  }

  /******************************/

  updateFilterList() {
    const newLabelList = [] as ProductFilterLabels["labelList"];
    const list = serviceFacets.getSearchParams().entries();
    const priceValues = [] as number[];

    for (const [key, id] of list) {
      const attribute = key.replace("[]", "");

      if (attribute === "price") {
        priceValues.push(parseInt(id));
        continue;
      }

      const value =
        serviceFacets.getFilterName(attribute, id) || `UNNAMED<${id}>`;
      const label =
        serviceFacets.getFilterLabel(attribute) || `UNNAMED<${attribute}>`;

      newLabelList.push({ attribute, id, label, value });
    }

    if (priceValues.length) {
      newLabelList.push({
        attribute: "price",
        id: "all",
        value: `${priceValues.join(" - ")} zł`,
        label: serviceFacets.getFilterLabel("price") || "Price"
      });
    }

    const query = serviceProductList.getQuery();
    if (query) {
      newLabelList.push({
        attribute: "q",
        id: "q",
        value: query,
        label: window.t("Szukaj")
      });
    }

    this.labelList = newLabelList;
  }

  delFilter(attribute: string, filterId: string) {
    if (attribute === "q") {
      serviceProductList.setQuery("");
      setTimeout(() => this.updateFilterList());
    } else {
      serviceFacets.delFilter(attribute, filterId);
    }
  }
}
