














































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Tile extends Vue {
  @Prop({ required: true }) protected text!: string;
  @Prop({ required: true }) protected image!: string;
}
