















































































import { Component, Vue } from "vue-property-decorator";
import serviceLayout, { CategoryItem } from "@/services/layout";
import { Brand } from "@/services/connection";
import Back from "@/components/RouteTopBack.vue";

@Component({
  components: {
    Back
  }
})
export default class PageBrands extends Vue {
  private loading = true;
  private layoutBrands = serviceLayout.getBrands();

  beforeMount() {
    window.scrollTo(0, 0);
    serviceLayout.on("changed-brands", this.onLayoutBrandsChange);

    if (this.layoutBrands.length) {
      this.loading = false;
    }
  }
  beforeDestroy() {
    serviceLayout.off("changed-brands", this.onLayoutBrandsChange);
  }

  showAllBrands() {
    alert("TODO");
  }

  onLayoutBrandsChange(list: Brand[]) {
    this.loading = false;
    this.layoutBrands = list;
  }
}
