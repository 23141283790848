






































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  Swiper,
  SwiperSlide,
  directive as swiperDirective
} from "vue-awesome-swiper";
import serviceLayout from "@/services/layout";
import { Content, ProductOnList } from "@/services/connection";

@Component({
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: swiperDirective
  }
})
export default class SwiperProducts extends Vue {
  @Prop({ required: true, default: [] }) protected products!: ProductOnList[];
  @Prop({ default: "" }) protected filters!: string;
}
