




















































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { SearchResponse } from "@/services/connection";
import { getPicture } from "@/helper";
import serviceCustomer from "@/services/customer";
import serviceCart from "@/services/cart";
import serviceLayout from "@/services/layout";

@Component
export default class Product extends Vue {
  @Prop() private product!: SearchResponse["products"][0];
  protected toCartQty = 1;
  protected inWishList = false;

  beforeMount() {
    this.updateInWishList();
    serviceCart.on("changed-wishlist", this.updateInWishList);
  }
  beforeDestroy() {
    serviceCart.off("changed-wishlist", this.updateInWishList);
  }

  updateInWishList() {
    this.inWishList = serviceCart.isProductInWishList(this.product.id);
  }

  getPicture(width: number, height: number): string {
    return getPicture(this.product.picture, width, height);
  }

  async addToCart(productId: number) {
    const result = await serviceCart.addToCart({
      product_id: productId,
      quantity: this.toCartQty
    });

    if (result !== null) {
      this.$bvToast.toast(window.t("Dodano do koszyka"), {
        title: window.t("Koszyk"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    }
  }

  async handlerAddToWishList(productId: number) {
    const result = await serviceCart.addToWishList({
      product_id: productId
    });

    if (result !== null) {
      this.$bvToast.toast(window.t("Dodano do przechowalni"), {
        title: window.t("Przechowalnia"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    }
  }

  async handlerRemoveFromWishList(productId: number) {
    const result = await serviceCart.delFromWishList(productId);

    if (result !== null) {
      this.$bvToast.toast(window.t("Usunięto z przechowalni"), {
        title: window.t("Przechowalnia"),
        toaster: "toast-bottom-bar-slot",
        solid: true,
        appendToast: true,
        variant: "primary",
        headerClass: "d-none"
      });
    }
  }
}
