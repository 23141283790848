/* eslint-disable no-console */

import { register, unregister } from "register-service-worker";

let broadcastChannel: BroadcastChannel | null = null;

if ("undefined" !== typeof BroadcastChannel) {
  broadcastChannel = new BroadcastChannel("sw-messages");

  broadcastChannel.addEventListener("message", event => {
    if (event.data && event.data.type === "RELOAD") {
      window.location.reload();
    }
  });
}

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registion) {
      console.log("New content is available; please refresh.");

      if (broadcastChannel !== null) {
        broadcastChannel.postMessage({ type: "SKIP_WAITING" });
      } else {
        if (
          confirm(
            `Dostępna jest nowa wersja. Kliknij OK aby odświeżyć.\nJeżeli komunikat się powtarza zamknij wszystkie karty z m.sparta.com`
          )
        ) {
          unregister();
          setTimeout(function() {
            window.location.reload();
          }, 200);
        }
      }
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
