



































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import serviceProductList from "@/services/productList";
import serviceFacets, { FacetBox } from "@/services/facets";
import serviceLayout from "@/services/layout";
import Back from "@/components/RouteTopBack.vue";
import * as serviceConnection from "@/services/connection";
import { Category, Brand } from "@/services/connection";
import * as uuid from "uuid";

@Component({
  components: {
    Back
  }
})
export default class Filters extends Vue {
  protected uuid = uuid.v4();
  protected filterList = serviceFacets.getRawCurrent();
  protected selfFilterList = serviceFacets.getRawCurrent();
  protected models = {} as { [key: string]: (number | string)[] };
  protected query = serviceProductList.getQuery() || "";
  protected specials = ["price", "cat"];
  protected defaultProps = {
    multiple: true,
    emitPath: false,
    checkStrictly: false,
    value: "id",
    label: "name",
    children: "childrens"
  };
  protected category = serviceProductList.getCategory() as Category | null;
  protected categoryList:
    | Category[]
    | null = serviceProductList.getCategoryList(
    (this.category && this.category.id) || 0
  );
  protected rootCategoryList:
    | Category[]
    | null = serviceProductList.getCategoryRootList();
  protected brand = serviceProductList.getBrand() as Brand | null;
  protected changes = serviceFacets.getSelected();
  protected facetBox!: FacetBox;

  /******************************/

  canShow(key: string) {
    if (key === "manufacturer") {
      if (serviceProductList.getBid()) {
        return false;
      }
    }
    return true;
  }

  /******************************/

  beforeMount() {
    this.facetBox = new FacetBox(serviceFacets.getRawCurrent());

    serviceLayout.setBottomBar(this.uuid, this.renderBottomBar());
    serviceFacets.on("changed-current", this.updateModel);
    serviceFacets.on("changed-selectedFilters", this.updateModel);
    serviceFacets.on("changed-all", this.updateModel);
    serviceFacets.on("changed-all", this.updateCategoryBrand);
    serviceProductList.on("changed-category", this.updateCategoryBrand);
    serviceProductList.on("changed-brand", this.updateCategoryBrand);
  }
  mounted() {
    this.updateModel();
    this.updateCategoryBrand();

    if (process.env.NODE_ENV !== "production") {
      Object.assign(window, { $pageFilters: this });
    }
  }
  beforeDestroy() {
    serviceLayout.removeBottomBar(this.uuid);
    serviceFacets.off("changed-current", this.updateModel);
    serviceFacets.off("changed-selectedFilters", this.updateModel);
    serviceFacets.off("changed-all", this.updateModel);
    serviceFacets.off("changed-all", this.updateCategoryBrand);
    serviceProductList.off("changed-category", this.updateCategoryBrand);
    serviceProductList.off("changed-brand", this.updateCategoryBrand);
  }

  protected updateCategoryBrand() {
    this.category = serviceProductList.getCategory();
    this.categoryList = serviceProductList.getCategoryList(
      (this.category && this.category.id) || 0
    );
    this.rootCategoryList = serviceProductList.getCategoryRootList();
    this.brand = serviceProductList.getBrand();
  }

  updateModel() {
    const newValue = {} as Filters["models"];

    this.filterList = serviceFacets.getRawCurrent();

    for (const filter of this.filterList) {
      const attribute = (filter.attribute || filter.id).replace("[]", "");
      if (filter.value) {
        newValue[attribute] = serviceFacets.getValues(attribute);
      } else if (filter.range) {
        const values = serviceFacets.getValues(attribute);
        newValue[attribute] = [values[0] || 0, values[1] || Infinity];
      }
    }

    this.models = newValue;
  }

  /******************************/

  renderBottomBar(): JSX.Element {
    return (
      <div class="btn-order-bottom-bar">
        <div class="container d-flex justify-content-between">
          <button class="btn btn-primary" onClick={this.apply}>
            {window.t("Szukaj")}
          </button>
        </div>
      </div>
    );
  }

  /******************************/

  updateFilter(item: Filters["filterList"][0], values: number[]) {
    const key = item.attribute || item.id;
    const value = values.map((value: number) => {
      return value.toString();
    });

    //serviceFacets.setFilterValues(key, value);
    this.changes[key] = value;

    (async () => {
      const searchParams = new URLSearchParams(`nocache=1`);
      const cid = serviceProductList.getCid();

      if (cid) {
        searchParams.append("cid", cid.toString());
      } else {
        const bid = serviceProductList.getBid();

        if (bid) {
          searchParams.append("bid", bid.toString());
        }
      }

      for (const key in this.changes) {
        this.changes[key].map((value: string) => {
          const safe = isNaN(parseInt(value))
            ? value
            : Math.abs(parseInt(value)).toString();
          searchParams.append(`${key}[]`, safe);
        });
      }

      const result = await serviceConnection.searchAndFacets(searchParams);

      if (result.valid()) {
        this.facetBox = new FacetBox(result.facets);
        this.selfFilterList = result.facets;
      }
    })();
  }

  apply() {
    for (const key in this.changes) {
      const value = this.changes[key];

      serviceFacets.setFilterValues(key, value);
    }

    serviceProductList.setQuery(this.query);

    const url = `/products${serviceProductList.getUrlParams()}`;
    // eslint-disable-next-line
    //debugger;
    this.$router.push(url);
  }

  getFilterName(attribute: string, filterId: string) {
    return serviceFacets.getFilterName(attribute, filterId);
  }

  removeCategory() {
    serviceProductList.setCid(null);
  }

  removeBran() {
    serviceProductList.setBid(null);
  }
}
