

















import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
@Component({
  components: {
    ValidationProvider
  }
})
export default class Input extends Vue {
  @Prop() name!: string;
  @Prop() model!: string;
  @Prop({ default: "text" }) type!: string;
  @Prop() placeholder!: string;
}
