var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-fill"},[(!_vm.$route.params.id)?_c('div',{staticClass:"account-data-container bg-light p-2 flex-fill"},[(!_vm.addressList || !_vm.addressList.length)?_c('div',{staticClass:"card-section"},[_vm._v(" "+_vm._s(_vm.t("Brak adresów"))+" ")]):_vm._e(),_c('div',{staticClass:"address-list-item list-item"},[(_vm.addressList && _vm.addressList.length)?[_vm._l((_vm.addressList),function(item,index){return [_c('div',{key:("" + index),staticClass:"row card-section mb-2"},[_c('div',{staticClass:"col-12 col-header"},[_c('h4',[_vm._v(_vm._s(_vm.getItemHeader(item)))])]),_c('div',{key:("address-" + index),staticClass:"col-8 col-md-4 col-address order-1"},[_c('div',[_c('b',[_vm._v(_vm._s(item.cla_address))]),_vm._v(_vm._s(" ")),(item.cla_number)?_c('span',[_vm._v(_vm._s(item.cla_number))]):_vm._e(),(item.cla_number2)?[_c('b',[_vm._v("/")]),_c('span',[_vm._v(_vm._s(item.cla_number2))])]:_vm._e()],2),_c('div',[_c('b',[_vm._v(_vm._s(item.cla_postcode))]),_vm._v(_vm._s(" ")+" "),_c('span',[_vm._v(_vm._s(item.cla_post_office))])]),_c('div',[_c('b',[_vm._v(_vm._s(item.cla_city))]),_vm._v(_vm._s(" ")+" "),_c('span',[_vm._v(_vm._s(item.cla_country))])])]),_c('div',{key:("buttons-" + index),staticClass:"col-4 col-btns d-flex flex-column align-items-stretch order-2 order-md-3"},[_c('router-link',{attrs:{"to":((_vm.$route.path) + "/" + (item.id_cla))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('a',{staticClass:"btn btn-outline-primary my-2",attrs:{"href":href}},[_c('span',{staticClass:"el-icon-edit-outline"}),_vm._v(_vm._s(" ")),_c('span',[_vm._v(_vm._s(_vm.t("Edytuj")))])])]}}],null,true)}),_c('span',{staticClass:"btn btn-outline-light-gray text-black my-2",on:{"click":function($event){return _vm.del(item.id_cla)}}},[_c('span',{staticClass:"el-icon-delete"}),_vm._v(_vm._s(" ")),_c('span',[_vm._v(_vm._s(_vm.t("Usuń")))])])],1),_c('div',{key:("person-" + index),staticClass:"col-8 col-md-4 col-person order-3 order-md-2"},[(item.cla_company || item.cla_nip)?_c('div',{staticClass:"d-flex"},[_c('div',[(item.cla_company)?_c('div',[_vm._v(_vm._s(item.cla_company))]):_vm._e(),(item.cla_nip)?_c('div',[_c('b',[_vm._v(_vm._s(item.cla_nip))])]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',[_c('div',[_vm._v(_vm._s(item.cla_name))]),_c('div',[_vm._v(_vm._s(item.cla_surname))])])]),_c('div',[_vm._v(" "+_vm._s(item.cla_phone)+" ")])])])]})]:_vm._e(),_c('div',{staticClass:"card-section"},[_c('router-link',{attrs:{"to":((_vm.$route.path) + "/add")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('a',{attrs:{"href":href}},[_c('span',{staticClass:"btn btn-primary btn-block py-2"},[_vm._v(" "+_vm._s(_vm.t("Dodaj nowy adres"))+" ")])])]}}],null,false,631080155)})],1)],2)]):_vm._e(),(_vm.$route.params.id)?_c('div',{staticClass:"account-data-container bg-light p-2"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{staticClass:"form-edit-address card-section",on:{"submit":function($event){$event.preventDefault();return passes()}}},[_c('header',[(_vm.formData.id_cla)?_c('h3',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.t("Edycja adresu"))+" ")]):_vm._e(),(!_vm.formData.id_cla)?_c('h3',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.t("Dodawanie adresu"))+" ")]):_vm._e()]),_c('FormName',{attrs:{"suffix":"cla_","formData":_vm.formData,"formPlaceholders":_vm.formPlaceholders}}),_c('FormContact',{attrs:{"suffix":"cla_","formData":_vm.formData,"formPlaceholders":_vm.formPlaceholders}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"cla_company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.cla_company),expression:"formData.cla_company"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.formPlaceholders.company},domProps:{"value":(_vm.formData.cla_company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "cla_company", $event.target.value)}}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"cla_nip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.cla_nip),expression:"formData.cla_nip"}],staticClass:"form-control mb-0",attrs:{"type":"text","placeholder":"NIP"},domProps:{"value":(_vm.formData.cla_nip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "cla_nip", $event.target.value)}}}),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"btn-get-data-by-nip mb-2"},[_c('button',{staticClass:"btn btn-link p-0",attrs:{"type":"button"},on:{"click":_vm.getCompanyData}},[_vm._v(" "+_vm._s(_vm.t("Pobierz dane firmy z GUS"))+" ")]),(_vm.getCompanyDataStatus === 'wait')?_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])]):_vm._e(),(_vm.getCompanyDataStatus === 'error')?_c('b-alert',{staticClass:"mb-2 p-2",attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.t("Nie udało się pobrać danych"))+" ")]):_vm._e()],1)],1)]),_c('FormAddress',{attrs:{"suffix":"cla_","formData":_vm.formData,"formPlaceholders":_vm.formPlaceholders}}),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":"cla_flag_delivery"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData["cla_flag_delivery"]),expression:"formData[`cla_flag_delivery`]"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"checkbox-cla_flag_delivery","name":"cla_flag_delivery","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(_vm.formData["cla_flag_delivery"])?_vm._i(_vm.formData["cla_flag_delivery"],null)>-1:_vm._q(_vm.formData["cla_flag_delivery"],"1")},on:{"change":function($event){var $$a=_vm.formData["cla_flag_delivery"],$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "cla_flag_delivery", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "cla_flag_delivery", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "cla_flag_delivery", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"checkbox-cla_flag_delivery"}},[_vm._v(" "+_vm._s(_vm.formPlaceholders["cla_flag_delivery"])+" ")]),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":"cla_flag_payment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData["cla_flag_payment"]),expression:"formData[`cla_flag_payment`]"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"checkbox-cla_flag_payment","name":"cla_flag_payment","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(_vm.formData["cla_flag_payment"])?_vm._i(_vm.formData["cla_flag_payment"],null)>-1:_vm._q(_vm.formData["cla_flag_payment"],"1")},on:{"change":function($event){var $$a=_vm.formData["cla_flag_payment"],$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "cla_flag_payment", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "cla_flag_payment", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "cla_flag_payment", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"checkbox-cla_flag_payment"}},[_vm._v(" "+_vm._s(_vm.formPlaceholders["cla_flag_payment"])+" ")]),_c('b-form-invalid-feedback',{attrs:{"state":!errors.length}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 d-flex align-items-stretch"},[_c('a',{staticClass:"btn btn-outline-primary btn-block py-2 px-1",attrs:{"href":("#" + (_vm.getUrl()))}},[_c('span',{staticClass:"el-icon-back"}),_vm._v(" "+_vm._s(_vm.t("Wstecz"))+" ")])]),_c('div',{staticClass:"col-8 d-flex align-items-stretch"},[_c('button',{staticClass:"btn btn-primary btn-block py-2 px-1",on:{"click":_vm.save}},[(_vm.formData.id_cla)?_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.t("Zapisz"))+" ")]):_vm._e(),(!_vm.formData.id_cla)?_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.t("Dodaj nowy adres"))+" ")]):_vm._e()])])])],1)]}}],null,false,851021689)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }