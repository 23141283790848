



































































































































































































































































import * as uuid from "uuid";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import serviceCustomer from "@/services/customer";
import { CartResponse, Content } from "@/services/connection";
import serviceLayout from "@/services/layout";
import * as serviceConnection from "@/services/connection";
import Input from "./Input.vue";
import FormAddress from "@/components/form/Address.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    Input,
    FormAddress
  }
})
export default class Individual extends Vue {
  protected uuid = uuid.v4();
  protected checkboxes: Content[] = [];
  protected getCompanyDataStatus = "ok" as "ok" | "wait" | "error";
  protected formData = {
    cli_nip: "",
    cli_company: "",
    cli_name: "",
    cli_surname: "",

    cli_email: "",
    cli_password: "",
    cli_confirm_password: "",

    cli_address: "",
    cli_number: "",
    cli_number2: "",
    cli_postcode: "",
    cli_post_office: "",
    cli_country: "",

    cli_www: "",
    cli_phone: "",

    accountType: "individual" as "individual" | "company",
    checkboxes: {} as { [key: string]: boolean }
  };
  protected formPlaceholders = this.$service.customer.getPlaceholders();
  protected lock = false;

  /******************************/

  beforeMount() {
    serviceLayout.on("changed-contents", this.contentChange);
    serviceLayout.setBottomBar(this.uuid, this.renderBottomBar());
    this.contentChange();

    this.formData.accountType = (this.$route.path ===
    "/account/registration/company"
      ? "company"
      : "individual") as "individual" | "company";
  }
  beforeDestroy() {
    serviceLayout.off("changed-contents", this.contentChange);
    serviceLayout.removeBottomBar(this.uuid);
  }
  renderBottomBar(): JSX.Element {
    return (
      <div class="btn-order-bottom-bar">
        <div class="container d-flex justify-content-between">
          <button class="btn btn-primary" onClick={this.onSubmit}>
            {window.t("Zarejestruj się")}
          </button>
        </div>
      </div>
    );
  }

  contentChange() {
    const contents = serviceLayout.getContents();

    if (contents["form-checkboxes"]) {
      this.checkboxes = contents["form-checkboxes"] as Content[];
    }
  }

  onSubmit() {
    this.lock = true;

    (async () => {
      const result = await serviceCustomer.register(this.formData);

      if (result !== null) {
        if (!result.valid()) {
          // console.log(
          //   "%cref Form",
          //   "color:red;font-size:16px;",
          //   this.$refs.form
          // );

          type Errors = { [key: string]: string[] };
          const errors = {} as Errors;
          result.getErrors().map(row => {
            errors[row.key] = errors[row.key] || [];
            errors[row.key].push(row.message);
          });
          (this.$refs.form as {
            setErrors(errors: Errors): void;
          } & Vue).setErrors(errors);
          this.lock = false;
        } else {
          this.$bvToast.toast(window.t("Zajerestrowano"), {
            title: window.t("Rejestracja"),
            toaster: "toast-bottom-bar-slot",
            solid: true,
            appendToast: true,
            variant: "primary",
            headerClass: "d-none"
          });
          try {
            if (serviceCustomer.redirectAfterLogin) {
              this.$router.push(serviceCustomer.redirectAfterLogin);
              serviceCustomer.redirectAfterLogin = "";
            } else {
              this.$router.push("/");
            }
          } catch (error) {
            null;
          }
        }
      } else {
        this.$bvToast.toast(window.t("Coś poszło nie tak"), {
          title: window.t("Rejestracja"),
          toaster: "toast-bottom-bar-slot",
          solid: true,
          appendToast: true,
          variant: "primary",
          headerClass: "d-none"
        });
      }
    })();
  }
  onReset(e: Event) {
    null;
  }

  async getCompanyData() {
    if (!this.formData.cli_nip) {
      (this.$refs.form as any).setErrors({
        cli_nip: window.t("Pole jest puste")
      });
      return;
    }

    this.getCompanyDataStatus = "wait";

    const nip = await serviceConnection.nip(
      this.formData.cli_nip.replace(/[^\d]/g, "")
    );

    if (nip.valid()) {
      this.$nextTick(() => {
        for (const key in nip) {
          if (key in this.formData) {
            this.$set(this.formData, key, nip[key as keyof typeof nip]);
          }
        }
      });
      this.getCompanyDataStatus = "ok";
    } else {
      this.getCompanyDataStatus = "error";
    }
  }

  setIndividual() {
    this.formData.accountType = "individual";
  }
  setCompany() {
    this.formData.accountType = "company";
  }
}
