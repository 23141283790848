

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProductPriceFilter extends Vue {
  @Prop({ required: true }) range!: [number, number];
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) onChange!: (value: [number, number]) => void;

  value: [number, number] = [0, Infinity];
  onRangeChange(value: [number, number]) {
    this.onChange(value);
  }
}
