




































import { Component, Vue, Prop } from "vue-property-decorator";
import Back from "@/components/RouteTopBack.vue";

@Component({
  components: {
    Back
  }
})
export default class Header extends Vue {
  protected state = this.$service.productList.state;
  protected category = this.$service.productList.getCategory();

  @Prop() loader!: boolean;

  /******************************/
  beforeMount() {
    this.$service.productList.on("loaded-categories", this.updateCategories);
    this.$service.productList.on("changed-category", this.updateCategories);
  }
  beforeDestroy() {
    this.$service.productList.off("loaded-categories", this.updateCategories);
    this.$service.productList.off("changed-category", this.updateCategories);
  }

  updateCategories() {
    this.category = this.$service.productList.getCategory();
  }

  handlerBackCategory() {
    if (this.category !== null) {
      this.$service.categoryMenu.openWith(this.category.parent_id);
    } else {
      this.$service.categoryMenu.openWith(0);
    }
  }

  handlerBackBrand() {
    this.$router.push("/brands");
  }
}
