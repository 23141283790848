































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OrderMain extends Vue {
  @Prop({ required: true }) order!: {};
}
