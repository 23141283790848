





















import { Component, Prop, Vue } from "vue-property-decorator";
import serviceCustomer from "@/services/customer";
import { ResponseErrorRow } from "@/services/connection/endpoint";

@Component
export default class UserNav extends Vue {
  hasBeenLogin = false;

  formData = {
    email: "",
    password: "",
    keepLogged: false
  };

  mounted() {
    serviceCustomer.onLogin(() => {
      this.hasBeenLogin = true;
    });
    serviceCustomer.onLogout(() => {
      this.hasBeenLogin = false;
    });
  }

  get isValidEmail() {
    return !this.validationFlag ? null : !!this.formData.email;
  }
  get isValidPassword() {
    return !this.validationFlag ? null : !!this.formData.password;
  }
  get isValidCredentials() {
    return !this.message;
  }
  private validationFlag = false;
  private lock = false;
  private message = null as ResponseErrorRow[] | null;

  getUserName() {
    const profile = serviceCustomer.getProfile();
    return (profile as Exclude<typeof profile, null>).cli_name;
  }
}
Vue.component("c-nav-user", UserNav);
