import { jsonApiFetch } from "./endpoint";

type Request = (
  | {
      email: string;
      password: string;
    }
  | {
      socialToken: string;
      socialService: string;
    }
) & {
  guestToken: string;
  deviceUuid: string;
  pushToken: string;
};
type Response = {
  data: {
    firstname: string;
    lastname: string;
    gender: "man" | "woman";
    cartItemsCount: number;
    wishlistItemsCount: number;
    gomezClub: 0 | 1;
    raw: any;
  };
  customer_token: string;
  long_life_token: string;
  checksum: string;
  errors_count: number;
};

function action(inputData: Request) {
  return jsonApiFetch<Response>(
    "/customerLogin",
    JSON.stringify({
      //@ts-ignore
      email: inputData.email,
      //@ts-ignore
      password: inputData.password,
      push_token: inputData.pushToken,
      device_uuid: inputData.deviceUuid,
      guest_token: inputData.guestToken,
      //@ts-ignore
      social_token: inputData.socialToken,
      //@ts-ignore
      social_service: inputData.socialService
    })
  );
}

export { action as customerLogin, Response as LoginResponse };
