























































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import OrderPayer from "@/views/order/OrderPayer.vue";
import OrderDelivery from "@/views/order/OrderDelivery.vue";
import ListItem from "@/views/cart/ListItem.vue";

@Component({
  components: {
    OrderPayer,
    OrderDelivery,
    ListItem
  },
  watch: {
    $route(this: Orders) {
      this.show();
    }
  }
})
export default class Orders extends Vue {
  protected orderList = this.$service.order.getOrders();
  protected order = null as null | Orders["orderList"][0];
  protected status = "wait";

  getUrl() {
    return this.$route.path.slice(
      0,
      -(((this.$route.params.id && this.$route.params.id.length) || -1) + 1)
    );
  }

  show() {
    const id = this.$route.params.id;

    if (id) {
      const order = this.$service.order.getOrder(id);
      if (order) {
        this.order = order;
      }
    }
  }

  async mounted() {
    await this.$service.order.loadOrders();
    this.orderList = this.$service.order.getOrders();

    if (!this.$route.params.id) {
      this.show();
    }
  }
}
