


























































import { Component, Prop, Vue } from "vue-property-decorator";
import RouteTopNav from "@/components/RouteTopNav.vue";

@Component({
  components: {
    RouteTopNav
  }
})
export default class Data extends Vue {
  protected navMap = {
    "/account/data/profile": window.t("Konto"),
    "/account/data/addresses": window.t("Adresy"),
    "/account/data/orders": window.t("Zamówienia")
    //"/account/data/discount": window.t("Moje rabaty")
  };
}
