import { EventEmitter, Listener } from "events";

/******************************/

// eslint-disable-next-line
type EventListType = { [key: string]: any };
export default class Events<
  EventList extends EventListType
> extends EventEmitter {
  emit<Key extends keyof EventList, Value extends EventList[Key]>(
    command: Value extends void ? Key : "Event requires a second parameter"
  ): boolean;
  emit<Key extends keyof EventList, Value extends EventList[Key]>(
    command: Key,
    data: Value extends void
      ? "Event does not accept a second parameter"
      : EventList[Key]
  ): boolean;
  // eslint-disable-next-line
  emit(command: any, data?: any) {
    return super.emit(command, data);
  }

  on<Key extends keyof EventList, Value extends EventList[Key]>(
    command: Key,
    listener: Value extends void ? () => void : (data: EventList[Key]) => void
  ): this {
    return super.on(command as string, listener);
  }
  addListener<Key extends keyof EventList, Value extends EventList[Key]>(
    command: Key,
    listener: Value extends void ? () => void : (data: EventList[Key]) => void
  ): this {
    return super.addListener(command as string, listener);
  }
  once<Key extends keyof EventList, Value extends EventList[Key]>(
    command: Key,
    listener: Value extends void ? () => void : (data: EventList[Key]) => void
  ): this {
    return super.once(command as string, listener);
  }
  prependListener<Key extends keyof EventList, Value extends EventList[Key]>(
    command: Key,
    listener: Value extends void ? () => void : (data: EventList[Key]) => void
  ): this {
    return super.prependListener(command as string, listener);
  }
  prependOnceListener<
    Key extends keyof EventList,
    Value extends EventList[Key]
  >(
    command: Key,
    listener: Value extends void ? () => void : (data: EventList[Key]) => void
  ): this {
    return super.prependOnceListener(command as string, listener);
  }

  removeListener<Key extends keyof EventList, Value extends EventList[Key]>(
    command: Key,
    listener: Value extends void ? () => void : (data: EventList[Key]) => void
  ): this {
    return super.removeListener(command as string, listener);
  }
  off<Key extends keyof EventList, Value extends EventList[Key]>(
    command: Key,
    listener: Value extends void ? () => void : (data: EventList[Key]) => void
  ): this {
    return super.off(command as string, listener);
  }

  removeAllListeners(): this;
  removeAllListeners<Key extends keyof EventList>(command: Key): this;
  // eslint-disable-next-line
  removeAllListeners(command?: any) {
    super.removeAllListeners(command) as Events<EventList>;
    return this;
  }

  listeners<Key extends keyof EventList>(command: Key): Listener[] {
    return super.listeners(command as string);
  }
  rawListeners<Key extends keyof EventList>(command: Key): Listener[] {
    return super.rawListeners(command as string);
  }
  eventNames() {
    return super.eventNames();
  }
}
