


























































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RouteTopNav extends Vue {
  @Prop({ required: true }) routeMap!: { [key: string]: string };
  @Prop({ default: false }) sm!: boolean;
}
