












































































































































import { Component, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import serviceLayout from "@/services/layout";
import Tile from "@/components/Tile.vue";

@Component({
  components: {
    Swiper,
    SwiperSlide,
    Tile
  }
})
export default class SwiperMain extends Vue {
  private contents = serviceLayout.getContents();

  getContent(name: string) {
    return this.contents[name] || [];
  }

  beforeMount() {
    serviceLayout.on("changed-contents", this.onContentsChanged);
  }
  beforeDestroy() {
    serviceLayout.off("changed-contents", this.onContentsChanged);
  }

  onContentsChanged() {
    this.contents = serviceLayout.getContents();
  }
}
