import { jsonApiFetch } from "./endpoint";

type Product = {
  id: number;
  title: string;
  subtitle: string;
  model: string;
  sku: string;
  promotion: boolean;
  availability: string;
  availability_raw: number;
  description: string;
  description_short: string;
  price_brutto: number;
  price_brutto_formatted: string;
  price_netto: number;
  price_netto_formatted: string;
  price_old: number;
  price_old_formatted: string;

  pack_items_count: number;
  pack_items_entire: string;
  pack_unit: string;

  url: string;
  badges: string[];
  attributes: string;
  measurement: null;
  manufacturer: {
    label: string;
    attribute: string;
    value: {
      name: string;
      id: number;
      picture: string;
    };
  };
  size: string;
  color: {
    label: string;
    attribute: string;
    value: {
      name: string;
      id: number;
      hex: string;
    };
  };
  picture: string;
  pictures: string[];
  sizes: {
    name: string;
    id: number;
    warning: null;
    salable: boolean;
  }[];
  category: { name: string; id: number };
};
type Response = {
  product: Product;
};

type Request = {
  productId: number;
  related?: 1;
  customer_token?: string;
  guest_token?: string;
};

function action(data: Request) {
  return jsonApiFetch<Response>(
    "/product",
    new URLSearchParams(
      `id=${data.productId}` +
        (data.guest_token ? "&guest_token=" + data.guest_token : "") +
        (data.customer_token ? "&customer_token=" + data.customer_token : "") +
        (data.related ? "&related=1" : "")
    )
  );
}

export { action as product, Response as ProductResponse, Product };
