import { jsonApiFetch } from "./endpoint";
import { LoginResponse } from ".";

type Request = {
  store?: 0 | 1;
  title?: string;

  cli_nip?: string;
  cli_company?: string;
  cli_name: string;
  cli_surname: string;

  cli_email: string;
  cli_password: string;
  cli_confirm_password: string;

  cli_address: string;
  cli_number: string;
  cli_number2: string;
  cli_postcode: string;
  cli_country: string;
  cli_post_office: string;

  cli_www: string;
  cli_phone: string;

  checkboxes: { [key: string]: boolean };
  cli_type: 0 | 1;

  push_token: string;
  device_name?: string;
  device_uuid: string;
  guest_token: string;
};

function action(inputData: Request) {
  return jsonApiFetch<LoginResponse>(
    "/customerRegistration",
    JSON.stringify(inputData)
  );
}

export { action as customerRegistration, LoginResponse as RegstrationResponse };

/*
  push_token: "teogv4-3fdsf4-ge5ve-sref4fa-erfef",
  store: 1,
  title: "Mr",
  firstname: "Jan",
  lastname: "Kowalski",
  email: "test7@example.com",
  password: "password123",
  confirmation: "password123",
  regulations: 1,
  account: 1,
  device_name: "GoClever Insignia 5X",
  device_uuid: "8a1015c5-da4e-3326-895d-bdaadc680d95",
  guest_token: "77342c9fb21f04b723ef89fb"
*/
