


































import { Component, Vue } from "vue-property-decorator";
import serviceProductList from "@/services/productList";

@Component
export default class Pagination extends Vue {
  private page = serviceProductList.getPage();
  private totalItems = serviceProductList.getTotal();
  private itemsPerPage = serviceProductList.getItemsPerPage();
  private listSizes = serviceProductList.getSizes();

  beforeMount() {
    this.$watch("page", serviceProductList.setPage);
    this.$watch("itemsPerPage", serviceProductList.setItemsPerPage);

    serviceProductList.on("changed-pagination", this.onPaginationChange);
  }
  beforeDestroy() {
    serviceProductList.off("changed-pagination", this.onPaginationChange);
  }

  onPaginationChange() {
    this.$set(this, "itemsPerPage", serviceProductList.getItemsPerPage());
    this.$set(this, "totalItems", serviceProductList.getTotal());
    this.$set(this, "page", serviceProductList.getPage());
  }
}
