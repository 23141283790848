






















































































































import { Component, Vue } from "vue-property-decorator";
import DrawerMenu from "@/views/layout/drawer/Menu.vue";
import DrawerCategories from "@/views/layout/drawer/Categories.vue";
import DrawerSearch from "@/views/layout/drawer/Search.vue";

@Component({
  components: {
    DrawerMenu,
    DrawerCategories,
    DrawerSearch
  }
})
export default class Drawers extends Vue {}
