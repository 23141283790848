









































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { CartResponse, Content, AddressResponse } from "@/services/connection";
import serviceCart from "@/services/cart";
import serviceLayout from "@/services/layout";
import serviceCustomer from "@/services/customer";
import * as uuid from "uuid";
import serviceOrder from "../../services/order";
import DeliveryAndPayments from "./DeliveryAndPayments.vue";
import AddressesDropdown from "./AddressesDropdown.vue";
import FormName from "@/components/form/Name.vue";
import FormContact from "@/components/form/Contact.vue";
import FormAddress from "@/components/form/Address.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    DeliveryAndPayments,
    AddressesDropdown,
    FormName,
    FormContact,
    FormAddress
  }
})
export default class From extends Vue {
  protected uuid = uuid.v4();
  protected payerOptions = [
    { label: window.t("Firma"), value: 1 },
    { label: window.t("Osoba prywatna"), value: 0 }
  ];
  protected lock = false;
  protected sent = false;
  protected message = "";
  protected checkboxes: Content[] = [];
  protected addresses: AddressResponse["addresses"] = [];
  protected formData = serviceOrder.getForm();
  protected formPlaceholders = serviceOrder.getPlaceholders();

  beforeMount() {
    serviceLayout.on("changed-contents", this.contentChange);
    serviceLayout.setBottomBar(this.uuid, this.renderBottomBar());
    this.contentChange();
  }
  mounted() {
    if (!this.$service.order.getDeliver().length) {
      this.$router.push("/cart/current");
      return;
    }

    serviceLayout.emit("changed-bottom-bar-visible", false);

    const errorList = serviceOrder.getErrors();

    if (errorList.length) {
      type Errors = { [key: string]: string[] };
      const errors = {} as Errors;
      errorList.map(row => {
        errors[row.key] = errors[row.key] || [];
        errors[row.key].push(row.message);
      });
      (this.$refs.form as {
        setErrors(errors: Errors): void;
      } & Vue).setErrors(errors);
    }

    if (this.$service.customer.isLogged()) {
      (async () => {
        this.addresses = await this.$service.customer.getOrLoadAddress();
      })();
    } else {
      (async () => {
        await this.$service.customer.onLogin();
        this.addresses = await this.$service.customer.getOrLoadAddress();
      })();
    }
  }
  beforeDestroy() {
    serviceLayout.off("changed-contents", this.contentChange);
    serviceLayout.removeBottomBar(this.uuid);
  }
  destroyed() {
    serviceLayout.emit("changed-bottom-bar-visible", true);
  }

  contentChange() {
    const contents = serviceLayout.getContents();

    if (contents["form-checkboxes"]) {
      this.checkboxes = contents["form-checkboxes"] as Content[];
    }
  }

  renderBottomBar(): JSX.Element {
    return (
      <div class="btn-order-bottom-bar">
        <div class="container d-flex justify-content-between">
          <button class="btn btn-primary" onClick={this.handlerNext}>
            {window.t("Dalej")}
          </button>
        </div>
      </div>
    );
  }

  async handlerNext() {
    // const result = await (this.$refs.form as Vue & {
    //   validate(): Promise<boolean>;
    // }).validate();

    const result = await this.$service.order.finishOrder(false);

    if (result.valid()) {
      this.$router.push("/order/confirm");
    } else {
      type Errors = { [key: string]: string[] };
      const errors = {} as Errors;
      result.getErrors().map((row: any) => {
        errors[row.key] = errors[row.key] || [];
        errors[row.key].push(row.message);
      });
      (this.$refs.form as {
        setErrors(errors: Errors): void;
      } & Vue).setErrors(errors);

      this.$nextTick(() => {
        const feedbackElement = document.querySelector(
          ".invalid-feedback.d-block"
        );

        if (feedbackElement) {
          const bBox = feedbackElement.getBoundingClientRect();
          //console.log(bBox);
          window.scrollTo(0, window.scrollY + bBox.top - 200);
        }
      });
    }
  }

  fillAddress(address: From["addresses"][0], suffix: string) {
    for (const key in this.formData) {
      if (key.search(suffix) !== 0) {
        continue;
      }
      const ffName = key as keyof From["formData"];
      const afName = `cla_${key.substr(
        suffix.length
      )}` as keyof From["addresses"][0];

      if (ffName in this.formData && afName in address) {
        // eslint-disable-next-line
        //@ts-ignore
        this.formData[ffName] = address[afName];
      }
    }
  }
}
