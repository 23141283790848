



















































import { Component, Prop, Vue } from "vue-property-decorator";
import { CartResponse } from "@/services/connection";
import serviceCart from "@/services/cart";
import serviceLayout from "@/services/layout";
import ListItem from "@/views/cart/ListItem.vue";
import ProductsSwiper from "@/components/SwiperProducts.vue";

@Component({
  components: {
    ProductsSwiper
  }
})
export default class Success extends Vue {
  protected cart = null as CartResponse | null;
  protected paymentLink = this.$service.order.getPaymentLink();

  beforeMount() {
    this.getCart();
  }
  mounted() {
    serviceCart.on("changed-cart", this.getCart);
    setTimeout(() => {
      this.paymentLink = null;
    }, 5e3);
  }
  beforeDestroy() {
    serviceCart.off("changed-cart", this.getCart);
  }

  getCart() {
    const cart = serviceCart.getCartData();
    this.$set(this, "cart", cart);
  }
}
