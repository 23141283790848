
















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RouteTopBack extends Vue {
  @Prop({ default: window.t("Wróć") }) private label!: string;
  @Prop({ default: null }) protected handler!: () => void;

  protected onClick() {
    if (this.$props.handler) {
      this.$props.handler();
    } else {
      this.$router.back();
    }
  }
}
