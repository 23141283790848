import { jsonApiFetch } from "./endpoint";

export type Category = {
  id: number;
  parent_id: number;
  position: number;
  name: string;
  path: string;
  level: number;
  slug: string;
  route: string;
  children?: Category[];
  image?: string;
  countProduct: string;
};

type Response = {
  categories: Category[];
  checksum: string;
  errors_count: number;
};

function action() {
  return jsonApiFetch<Response>("/category", undefined, "get");
}

export { action as category, Response as CategoryResponse };
